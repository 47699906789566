import React, { useEffect, useState } from "react";
import myClass from "./loader.module.css";

export default function LoaderWithText() {
  const [loadingText, setloadingText] = useState();
  const arrayOfText = [
    "Don't worry - a few bits tried to escape, but we caught them",
    "We're testing your patience",
    "As if you had any other choice",
    "Counting backward from Infinity",
    "Don't panic...",
    "I swear it's almost done.",
    "Dividing by zero...",
    "BRB, working on my special project",
    "Winter is coming...",
    "Loading we will be right back soon...",
    "Downloading more RAM..",
    "Stay calm, Moiz is calculating the amount.",
    "We will be back soon...",
    "we will be right back soon...",
  ];
  let step = 0;

  useEffect(() => {
    const timerId = setInterval(textTimer, 25000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const textTimer = () => {
    if (step < arrayOfText.length) {
      setloadingText(arrayOfText[step]);
      step++;
    } else {
      step = 0;
      setloadingText(arrayOfText[step]);
    }
  };
  return (
    <div>
      <div
        className="fill-window"
        style={{ width: "100%", height: "100%" }}
      ></div>
      <div className={myClass.loaderAndText}>
        <div className={myClass.loadertxt}>Loading...</div>
        <div className={myClass.loaderText}>{loadingText}</div>
      </div>
    </div>
  );
}
