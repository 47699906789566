import React, { useState } from 'react';
import {
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';

import Header from '../../components/header/Header';
import SecurePayment from '../../assets/images/icon_securepayment.png';
import Billing from './billing';
import OrderConfirmation from './confirmation';
import SelectPlan from './selectPlan';
import {
  ORDER_STEP,
  ORDER_STEPS,
  ORDER_STEPS_ROUTE,
} from '../../constants/app';
import { RATE_PLANS } from '../../constants/ratePlans';
import { APP_ROUTES } from '../../constants/routes';

// eslint-disable-next-line react/function-component-definition
export default function CreateOrder() {
  const history = useHistory();
  const { step } = history.location.state || { step: ORDER_STEP.YOUR_ORDER };
  const existingCustomer = localStorage.getItem('existingCustomer');
  const ratePlans = RATE_PLANS;
  const [isMonthly] = useState(true);
  const [rate, setRate] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [shippingFee, setShippingFee] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);
  const [promocode, setPromocode] = useState('');
  const [blankError] = useState('');
  const [promoNotMatched] = useState(false);
  const [selectedPlanId, setselectedPlanId] = useState();
  const [productRateplanId, setproductRateplanId] = useState('');
  const [productRatePlanChargeId, setproductRatePlanChargeId] = useState('');
  const [editBillingData, setEditBillingData] = useState({});

  const moveToStep = (stepNo, state) => {
    const historyObj = { pathname: ORDER_STEPS_ROUTE[stepNo] };
    let pageState = { step: stepNo };
    if (state) {
      pageState = { ...state, ...pageState };
    }
    historyObj.state = pageState;
    history.push(historyObj);
  };

  const continueToBilling = () => {
    history.push({ state: { ...history.location.state, enableSubmit: true } });
    const billing = {
      quantity,
      shippingFee,
      subTotal,
      total,
      productRateplanId,
      productRatePlanChargeId,
      isMonthly,
    };
    const { resetForm } = history.location.state;
    moveToStep(ORDER_STEP.BILLING, { billing, resetForm });
  };

  const continueToConfirmation = (billingObj, billing) => {
    const confirmation = billingObj;
    if (confirmation.soldTo) {
      confirmation.shiffingAddress = confirmation.address;
      confirmation.shiffingCity = confirmation.city;
      confirmation.shiffingCountry = confirmation.country;
      confirmation.shiffingPhone = confirmation.phone;
      confirmation.shiffingPostalCode = confirmation.postalCode;
      confirmation.shiffingState = confirmation.state;
    } else {
      confirmation.shiffingAddress = confirmation.soldtoAddress;
      confirmation.shiffingCity = confirmation.soldtoCity;
      confirmation.shiffingState = confirmation.soldtoState;
      confirmation.shiffingCountry = confirmation.soldtoCountry;
      confirmation.shiffingPostalCode = confirmation.soldtoPostalCode;
      confirmation.shiffingPhone = confirmation.soldtoPhone;
    }
    confirmation.isMonthly = isMonthly;
    if (!confirmation.handyman) {
      confirmation.handyman = confirmation.soldTo
        ? `${confirmation.fname} ${confirmation.lname}`
        : `${confirmation.soldtoFname} ${confirmation.soldtoLname}`;
    }
    delete confirmation.newUser;
    delete confirmation.expiration;
    delete confirmation.existingCustomer;
    moveToStep(ORDER_STEP.CONFIRMATION, { billing, confirmation });
  };

  const backToRatePlans = () => {
    moveToStep(ORDER_STEP.YOUR_ORDER, { enableSubmit: true, resetForm: true });
  };

  const backToBilling = (billing) => {
    moveToStep(ORDER_STEP.BILLING, { billing, resetForm: true });
  };

  const getStepUI = () => (
    <Switch>
      <Route path={APP_ROUTES.BILLING} exact name="orderBilling">
        <Billing
          editBillingData={editBillingData}
          existingCustomer={existingCustomer}
          handleBillingSubmit={continueToConfirmation}
          setEditBillingData={setEditBillingData}
        />
      </Route>

      <Route
        path={APP_ROUTES.CONFIRMATION}
        exact
        name="orderConfirmation"
        render={() => (
          <OrderConfirmation
            existingCustomer={existingCustomer}
            backToBilling={backToBilling}
            backToRatePlans={backToRatePlans}
          />
        )}
      />
      <Route
        path="/"
        name="selectPlan"
        render={() => (
          <SelectPlan
            isMonthly={isMonthly}
            rate={rate}
            setRate={setRate}
            quantity={quantity}
            setQuantity={setQuantity}
            shippingFee={shippingFee}
            setShippingFee={setShippingFee}
            subTotal={subTotal}
            setSubTotal={setSubTotal}
            total={total}
            setTotal={setTotal}
            isPromoCodeApplied={isPromoCodeApplied}
            setIsPromoCodeApplied={setIsPromoCodeApplied}
            promocode={promocode}
            setPromocode={setPromocode}
            blankError={blankError}
            promoNotMatched={promoNotMatched}
            selectedPlanId={selectedPlanId}
            setselectedPlanId={setselectedPlanId}
            ratePlans={ratePlans}
            handleContinue={continueToBilling}
            setproductRateplanId={setproductRateplanId}
            setproductRatePlanChargeId={setproductRatePlanChargeId}
          />
        )}
      />
    </Switch>
  );

  return (
    <div className="fill-window fill-white">
      <Header image={SecurePayment} text="secure payment" />
      <div className="d-flex mt-3 w-100 ">
        <div className=" w-100">
          <div className="order-steps">
            {ORDER_STEPS.map((stepName, index) => {
              const stepNo = index;
              return (
                <span
                  key={stepNo + 1}
                  className={stepNo <= step ? 'selected' : ''}
                >
                  <em>{stepNo + 1}</em>
                  <strong>{stepName}</strong>
                </span>
              );
            })}
          </div>
          <div>{getStepUI()}</div>
        </div>
      </div>
    </div>
  );
}
