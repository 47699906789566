import React, { useState, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { CountryDropdown } from 'react-country-region-selector';
import { useHistory } from 'react-router-dom';

import myClass from './Billing.module.css';
import OrderSummary from '../../../components/orderSummary';
import billingSchema from '../../../validations/billing';
import HMAC from '../../../services/billing';
import MESSAGES from '../../../constants/messages';
import SavedPaymentMethods from '../../../components/savedPaymentMethods';
import PaymentCardsRow from '../../../components/paymentCardsRow';
import CreditCardField from '../../../components/creditCardField';
import { PAYMENT_CARD_TYPES } from '../../../constants/app';
import FormError from '../../../components/formError';
import { AppContext } from '../../../context/app';
import CvvField from '../../../components/cvvField';

// eslint-disable-next-line react/function-component-definition
export default function Billing({
  editBillingData,
  existingCustomer,
  handleBillingSubmit,
  setEditBillingData,
}) {
  const {
    register, handleSubmit, setValue, control, formState: { errors }, getValues,
  } = useForm({ resolver: yupResolver(billingSchema) });
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);
  const [selectedCardType, setSelectedCardType] = useState('');
  const history = useHistory();
  const { billing, resetForm } = history.location.state || {};
  const { loading, showMessage, messageText } = useContext(AppContext);
  const [, setIsLoading] = loading;
  const [, setShowMsg] = showMessage;
  const [, setMessage] = messageText;

  useEffect(() => {
    history.listen((loc, action) => {
      if (action === 'POP' && loc.pathname === '/nec/order/billing') {
        // TODO: form reset not working so reloading page for now
        window.location.reload();
      }
    });
  }, [history]);

  useEffect(() => {
    if (Object.keys(editBillingData).length) {
      Object.keys(editBillingData).forEach((item) => {
        setValue(item, editBillingData[item]);
        if (item === MESSAGES.SOLDTO) {
          setBillingSameAsShipping(editBillingData[item]);
        }
      });
    }
    setValue('existingCustomer', existingCustomer || false);
    if (resetForm) {
      history.push({ state: { ...history.location.state, resetForm: false } });
      // TODO: form reset working but validations not getting reset for few fields
      // so reloading the page for now
      window.location.reload();
    }
  }, [editBillingData, existingCustomer, setValue]);

  const setFormValues = (card) => {
    setValue('creditCardNumber', card.cardNumber);
    setValue('creditCardType', card.cardType);
    setValue('expirationMonth', card.expirationMonth);
    setValue('expirationYear', card.expirationYear);
    setValue('address', card.cardHolderInfo.addressLine1);
    setValue('city', card.cardHolderInfo.city);
    setValue('state', card.cardHolderInfo.state);
    setValue('phone', card.cardHolderInfo.phone);
    setValue('country', card.cardHolderInfo.country);
    setValue('postalCode', card.cardHolderInfo.zipCode);
    setValue('handyman', card.cardHolderInfo.cardHolderName);
    setValue('cardHolderName', card.cardHolderInfo.cardHolderName);
    setValue('shiffingAddress', card.cardHolderInfo.addressLine1);
    setValue('shiffingCity', card.cardHolderInfo.city);
    setValue('shiffingState', card.cardHolderInfo.state);
    setValue('shiffingPhone', card.cardHolderInfo.phone);
    setValue('shiffingCountry', card.cardHolderInfo.country);
    setValue('shiffingPostalCode', card.cardHolderInfo.zipCode);
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    setEditBillingData(formData);

    if (existingCustomer) {
      const payment = {
        productRateplanId: billing.productRateplanId,
        productRatePlanChargeId: billing.productRatePlanChargeId,
        quantity: billing.quantity,
        shiffingFee: billing.shippingFee,
        subTotal: billing.subTotal,
        total: billing.total,
        accountId: localStorage.getItem('accountId'),
        ...getValues(),
      };
      handleBillingSubmit(payment, billing);
      setIsLoading(false);
    } else {
      const expiration = getValues('expiration');
      const splits = expiration.split('/', 2);
      const paymentDto = {
        creditCardNumber: getValues('creditCardNumber'),
        cardHolderName: `${getValues('fname')} ${getValues('lname')}`,
        expirationMonth: splits[0],
        expirationYear: splits[1],
        fname: getValues('fname'),
        lname: getValues('lname'),
        address: getValues('address'),
        city: getValues('city'),
        state: getValues('state'),
        country: getValues('country'),
        postalCode: getValues('postalCode'),
        userEmail: getValues('email'),
        userPassword: getValues('userPassword'),
        cvv: getValues('cvv'),
        newUser: true,
        phone: getValues('phone'),
        creditCardType: PAYMENT_CARD_TYPES[selectedCardType.toUpperCase()],
      };

      const response = await HMAC(paymentDto);
      if (!response.data.success) {
        const errorMsg = response.data.reasons.map((i) => (<p key={i.value}>{i.message.replace(/[<]br[^>]*[>]/gi, ' ')}</p>));
        setShowMsg(true);
        setMessage(errorMsg);
        setIsLoading(false);
      } else {
        const trailingCharsIntactCount = 4;
        let cardNo = paymentDto.creditCardNumber;
        cardNo = new Array(cardNo.length - trailingCharsIntactCount + 1).join('x')
        + cardNo.slice(-trailingCharsIntactCount);
        const payment = {
          ...paymentDto,
          cardNo,
          paymentMethodId: response.data.paymentMethodId,
          productRateplanId: billing.productRateplanId,
          productRatePlanChargeId: billing.productRatePlanChargeId,
          quantity: billing.quantity,
          shiffingFee: billing.shippingFee,
          subTotal: billing.subTotal,
          total: billing.total,
          loggedInEmail: localStorage.getItem('email'),
          companyName: localStorage.getItem('companyName'),
          noOfProperty: localStorage.getItem('noOfProperty'),
          pms: localStorage.getItem('pms'),
          ...getValues(),
        };

        if (response?.data?.paymentMethodId) {
          handleBillingSubmit(payment, billing);
          setIsLoading(false);
        }
      }
    }
  };

  const handleContinue = () => {
    document.getElementById('formSubmitButton').click();
  };

  return (
    <div>
      <div className="w-100 has-top-border">
        <div className="container-fluid w-1120">
          <div className="row w-100">
            <div className="col-md-8 mt-4">
              <div className="card ml-3 order-left-col mb-4">
                <div className="card-body billing-form">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {existingCustomer
                      ? (
                        <SavedPaymentMethods
                          setIsLoading={setIsLoading}
                          setFormValues={setFormValues}
                        />
                      )
                      : (
                        <>
                          <h5 className="card-title mt-n1">{MESSAGES.PAYMENT_DETAILS}</h5>

                          <div className="card-list">
                            <PaymentCardsRow
                              cardType={selectedCardType}
                              imgComponent={(
                                <img
                                  src=""
                                  alt=""
                                />
                                )}
                            />
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <label htmlFor="cardNumber" className={myClass.label}>
                                {MESSAGES.CARD_NUMBER}
                              </label>
                              <CreditCardField
                                register={register}
                                setSelectedCardType={setSelectedCardType}
                                error={errors.creditCardNumber?.message}
                              />
                            </div>
                            <div className="col-lg-3">
                              <label htmlFor="expiration" className={myClass.label}>
                                {MESSAGES.CARD_EXPIRY}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="expiration"
                                maxLength="7"
                                {...register('expiration')}
                              />
                              <small>{MESSAGES.MM_YYYY}</small>
                              {errors?.expiration && (
                              <small className="err-msg-bottom">
                                {errors?.expiration.message}
                              </small>
                              )}
                            </div>

                            <div className="col-lg-3">
                              <label htmlFor="cvv" className={myClass.label}>
                                {MESSAGES.CVV}
                              </label>
                              <CvvField
                                register={register}
                                selectedCardType={selectedCardType}
                                error={errors?.cvv?.message}
                              />
                            </div>
                          </div>

                          <hr />

                          <h5 className="card-title">{MESSAGES.BILLING_INFO}</h5>
                          <div className="row">
                            <div className="col-lg-6">
                              <label htmlFor="firstName" className={myClass.label}>
                                {MESSAGES.FIRST_NAME}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="fname"
                                {...register('fname')}
                                placeholder={errors?.fname?.message}
                              />
                            </div>
                            <div className="col-lg-6">
                              <label htmlFor="lastName" className={myClass.label}>
                                {MESSAGES.LAST_NAME}

                              </label>
                              <input
                                type="text"
                                name="lname"
                                className="form-control"
                                {...register('lname')}
                                placeholder={errors?.lname?.message}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <label htmlFor="phone" className={myClass.label}>
                                {MESSAGES.PHONE_NO}
                              </label>
                              <input
                                type="text"
                                name="phone"
                                id="phone"
                                maxLength="15"
                                className="form-control"
                                {...register('phone')}
                              />
                              {errors?.phone && (
                              <p style={{ color: 'red', fontSize: 'small' }}>
                                {errors?.phone?.message}
                              </p>
                              )}
                            </div>

                            <div className="col-lg-6">
                              <label htmlFor="email" className={myClass.label}>
                                {MESSAGES.EMAIL}
                              </label>
                              <input
                                type="text"
                                name="email"
                                id="email"
                                className="form-control"
                                {...register('email')}
                                placeholder={errors?.email?.message}
                              />
                              {errors.email?.message && <FormError error={errors.email?.message} />}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-12">
                              <label htmlFor="address" className={myClass.label}>
                                {MESSAGES.ADDRESS}
                              </label>
                              <input
                                type="text"
                                name="address"
                                className="form-control"
                                {...register('address')}
                                placeholder={errors?.address?.message}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <label htmlFor="city" className={myClass.label}>
                                {MESSAGES.CITY}
                              </label>
                              <input
                                type="text"
                                name="city"
                                className="form-control"
                                {...register('city')}
                                placeholder={errors?.city?.message}
                              />
                            </div>

                            <div className="col-lg-6">
                              <label htmlFor="state" className={myClass.label}>
                                {MESSAGES.STATE}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="state"
                                {...register('state')}
                                placeholder={errors?.state?.message}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <label htmlFor="country" className={myClass.label}>
                                {MESSAGES.COUNTRY}
                              </label>
                              <Controller
                                name="country"
                                control={control}
                                render={({
                                  field: {
                                    onChange, onBlur, value,
                                  },
                                }) => (
                                  <CountryDropdown
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    className={myClass.select}
                                  />
                                )}
                              />
                              {errors?.country && (
                              <p style={{ color: 'red', fontSize: 'small' }}>
                                {errors?.country?.message}
                              </p>
                              )}
                            </div>

                            <div className="col-lg-6">
                              <label htmlFor="postalCode" className={myClass.label}>
                                {MESSAGES.ZIPCODE}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="postalCode"
                                {...register('postalCode')}
                                placeholder={errors?.postalCode?.message}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    <hr />

                    <h5 className="card-title">{MESSAGES.SHIPPING_INFO}</h5>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="defaultChecked"
                        {...register('soldTo')}
                        onClick={(e) => setBillingSameAsShipping(e.target.checked)}
                        checked={billingSameAsShipping}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="defaultChecked"
                      >
                        {MESSAGES.SAME_AS_BILLING}
                      </label>
                    </div>
                    {billingSameAsShipping ? (
                      ''
                    ) : (
                      <div className="pt-2">
                        <div className="row">
                          <div className="col-lg-6">
                            <label
                              htmlFor="firstName"
                              className={myClass.label}
                            >
                              {MESSAGES.FIRST_NAME}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="soldtoFname"
                              {...register('soldtoFname')}
                              placeholder={errors?.soldtoFname?.message}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="lastName" className={myClass.label}>
                              {MESSAGES.LAST_NAME}
                            </label>
                            <input
                              type="text"
                              name="soldtoLname"
                              className="form-control"
                              {...register('soldtoLname')}
                              placeholder={errors?.soldtoLname?.message}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6">
                            <label htmlFor="email" className={myClass.label}>
                              {MESSAGES.PHONE_NO}
                            </label>
                            <input
                              type="text"
                              name="soldtoPhone"
                              id="soldtoPhone"
                              maxLength="15"
                              className="form-control"
                              {...register('soldtoPhone')}
                            />
                            {errors?.soldtoPhone && (
                              <p style={{ color: 'red', fontSize: 'small' }}>
                                {errors?.soldtoPhone?.message}
                              </p>
                            )}
                          </div>

                          <div className="col-lg-6">
                            <label htmlFor="email" className={myClass.label}>
                              {MESSAGES.EMAIL}
                            </label>
                            <input
                              type="text"
                              name="soldtoEmail"
                              id="soldtoEmail"
                              className="form-control"
                              {...register('soldtoEmail')}
                              placeholder={errors?.soldtoEmail?.message}
                            />
                            {errors.soldtoEmail?.message
                              && <FormError error={errors.soldtoEmail?.message} />}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <label htmlFor="address" className={myClass.label}>
                              {MESSAGES.ADDRESS}
                            </label>
                            <input
                              type="text"
                              name="soldtoAddress"
                              className="form-control"
                              {...register('soldtoAddress')}
                              placeholder={errors?.soldtoAddress?.message}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6">
                            <label htmlFor="city" className={myClass.label}>
                              {MESSAGES.CITY}
                            </label>
                            <input
                              type="text"
                              name="soldtoCity"
                              className="form-control"
                              {...register('soldtoCity')}
                              placeholder={errors?.soldtoCity?.message}
                            />
                          </div>

                          <div className="col-lg-6">
                            <label htmlFor="state" className={myClass.label}>
                              {MESSAGES.STATE}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="soldtoState"
                              {...register('soldtoState')}
                              placeholder={errors?.soldtoState?.message}
                            />
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-lg-6">
                            <label htmlFor="country" className={myClass.label}>
                              {MESSAGES.COUNTRY}
                            </label>
                            <Controller
                              name="soldtoCountry"
                              control={control}
                              render={({
                                field: {
                                  onChange, onBlur, value,
                                },
                              }) => (
                                <CountryDropdown
                                  value={value}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  className={myClass.select}
                                />
                              )}
                            />
                            {errors?.soldtoCountry && (
                              <p style={{ color: 'red', fontSize: 'small' }}>
                                {errors?.soldtoCountry?.message}
                              </p>
                            )}
                          </div>

                          <div className="col-lg-6">
                            <label
                              htmlFor="postalCode"
                              className={myClass.label}
                            >
                              {MESSAGES.ZIPCODE}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="soldtoPostalCode"
                              {...register('soldtoPostalCode')}
                              placeholder={errors?.soldtoPostalCode?.message}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="form-group" style={{ display: 'none' }}>
                      <button
                        id="formSubmitButton"
                        type="submit"
                        className="btn w-100"
                      >
                        {MESSAGES.SUBMIT}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card rounded-0 order-col pt-4">
                <OrderSummary
                  billing
                  quantity={billing.quantity}
                  shippingFee={billing.shippingFee}
                  subTotal={billing.subTotal}
                  total={billing.total}
                  handleContinue={handleContinue}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Billing.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editBillingData: PropTypes.object,
  existingCustomer: PropTypes.bool,
  handleBillingSubmit: PropTypes.func,
  setEditBillingData: PropTypes.func,
};

Billing.defaultProps = {
  editBillingData: {},
  existingCustomer: false,
  handleBillingSubmit: () => {},
  setEditBillingData: () => {},
};
