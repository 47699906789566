export const RATE_PLANS = [
  {
    id: 1,
    label: 'Standard',
    product: 'GuestView Guide',
    description: 'Standard - Monthly - USD $39.99',
    price: 39.99,
    ratePlan: 'Standard - Monthly',
    isMonthly: true,
    promoCode: '',
  },
];

export const RATE_PLANS_WITH_PROMO_CODE = [
  { productRatePlanName: 'Standard - Monthly', promoCode: 'FREESHIPPING', ratePlan: 'Standard - Monthly - Free Shipping' },
  { productRatePlanName: 'Standard - Monthly', promoCode: 'showspecial', ratePlan: '$19.99 per month show promo' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '90dayfree', ratePlan: 'Standard - Free Trial - 90' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '3324A5', ratePlan: '5% off first year - monthly' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '3324A5freeship', ratePlan: '5% off first year free shipping - monthly' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '3149B10', ratePlan: '10% off first year - monthly' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '3149B10freeship', ratePlan: '10% off first year free shipping - monthly' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '2974C15', ratePlan: '15% off first year - monthly' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '2974C15freeship', ratePlan: '15% off first year free shipping - monthly' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '2799D20', ratePlan: '20% off first year - monthly' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '2799D20freeship', ratePlan: '20% off first year free shipping - monthly' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '2624E25', ratePlan: '25% off first year - monthly' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '2624E25freeship', ratePlan: '25% off first year free shipping - monthly' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '2700F23freeship', ratePlan: '$27 first year - Monthly - Free Shipping' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '6mofree', ratePlan: 'Monthly 6 months free' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '2624E25', ratePlan: '25% off first year - monthly' },
  { productRatePlanName: 'Standard - Monthly', promoCode: 'onemofreeship', ratePlan: 'Free month plus free shipping' },
  { productRatePlanName: 'Standard - Monthly', promoCode: '1mofree', ratePlan: 'Free month' },
];
