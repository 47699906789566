import React, { Component } from "react";
import SecurePayment from '../../assets/images/icon_securepayment.png';
import myClass from "./order.module.css";
import Header from "../header/Header";
import { getProductList } from '../../actions/order_action';
import { connect } from 'react-redux';
import { client } from "../../actions/index";
import OrderError from './OrderError';
import Loader from '../model/Loader';
import { event } from "jquery";

class AddPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isQuantityError:false,
      isPromoCodeApplied: false,
      promocode: "",
      blankError: "",
      isInvalidPromo: false,
      quantity: "1",
      productName: 'GuestView Guide',
      productRatePlanName: '',
      isToggle: false,
      shiffingFee: '',
      subTotal: '',
      total: '',
      productRateplanId: '',
      productRatePlanChargeId: '',
      isCheckedMonthly: false,
      isCheckedAnnual: false,
      isLoading: false,
      rate: '',
      proratedAmount: '',
      promoNotMatched: false,
      isCheckedMonthlyOff: false,
      isCheckedAnnualOff: false,
     
      isError: false,
      isMonthly: true,
      opacity: "100%",
      isNotChecked: true,
    };
  }

  componentDidMount() {
    // this.props.getProductList()
    // this.setState({
    //   isLoading: true
    // }, () =>
    //   client.get('checkout/previewOrders', {
    //     params: {
    //       product: this.state.productName,
    //       ratePlan: this.state.productRatePlanName,
    //       quantity: this.state.quantity,
    //       promoCode: this.state.promocode,
    //       isMonthly: this.state.isMonthly
    //     }
    //   })
    //     .then((response) => {
    //       console.log(response)
    //       this.setState({
    //         isToggle: true,
    //         shiffingFee: response.data.shiffingFee,
    //         subTotal: response.data.subTotal,
    //         total: response.data.total,
    //         productRateplanId: response.data.productRateplanId,
    //         productRatePlanChargeId: response.data.productRatePlanChargeId,
    //         isLoading: false,
    //         rate: response.data.rate,
    //         proratedAmount: response.data.proratedAmount
    //       })

    //     })
    // );
  }
  onChangeQTY = (e) => {
    this.setState({ [e.target.name]: e.target.value });
   if(e.target.value>0 ){
    
    this.setState({isQuantityError:false});
   }else{
    this.setState({isQuantityError:true});
  }
    //this.setState({ [e.target.name]: e.target.value });
  }
  onChangeQuantity = (e) => {
   
    if (this.state.productRatePlanName != '' && this.state.quantity >0 ) {
      this.setState({
        // quantity: e.target.value,
        isError: false
        

        // isLoading: true
      }, () => {
        if ((parseInt(this.state.quantity) < 5 && this.state.productRatePlanName === 'Standard - Monthly - Special 10') || (parseInt(this.state.quantity) < 5 && this.state.productRatePlanName === 'Standard - Annual - Special 10')) {



          this.setState({
            isError: true,
            reasonOfFailure:"Minimum of 5 displays for this rate plan"
          })

        } else {
          if ((this.state.productRatePlanName === 'Standard - Monthly - Special 10') || (this.state.productRatePlanName === 'Standard - Annual - Special 10')) {
            this.setState({
              // quantity: e.target.value,
              isLoading: true,
              isNotChecked: true,
            }, () =>


              client.get('checkout/previewOrders', {
                params: {
                  product: this.state.productName,
                  ratePlan: this.state.productRatePlanName,
                  quantity: this.state.quantity,
                  promoCode: 'showspecial',
                  isMonthly: this.state.isMonthly
                }
              })
                .then((response) => {
                  // console.log(response)
                  this.setState({
                    isToggle: true,
                    shiffingFee: response.data.shiffingFee,
                    subTotal: response.data.subTotal,
                    total: response.data.total,
                    productRateplanId: response.data.productRateplanId,
                    productRatePlanChargeId: response.data.productRatePlanChargeId,
                    isLoading: false,
                    isNotChecked: false,
                    rate: response.data.rate,
                    proratedAmount: response.data.proratedAmount
                  })

               
                })
                
            );
          } else {
            this.setState({
              // quantity: e.target.value,
              isLoading: true,
              isNotChecked: true,
            }, () =>


              client.get('checkout/previewOrders', {
                params: {
                  product: this.state.productName,
                  ratePlan: this.state.productRatePlanName,
                  quantity: this.state.quantity,
                  promoCode: this.state.promocode,
                  isMonthly: this.state.isMonthly
                }
              })
                .then((response) => {
                  // console.log(response)
                  this.setState({
                    isToggle: true,
                    shiffingFee: response.data.shiffingFee,
                    subTotal: response.data.subTotal,
                    total: response.data.total,
                    productRateplanId: response.data.productRateplanId,
                    productRatePlanChargeId: response.data.productRatePlanChargeId,
                    isLoading: false,
                    isNotChecked: false,
                    rate: response.data.rate,
                    proratedAmount: response.data.proratedAmount
                  })
             
                  if(response.data.productRateplanId == null){
                    this.setState(
                      {
                         isToggle: false,
                        isCheckedMonthly: false,
                        isMonthly: false,
                        isNotChecked: true,
                        isError:true,
                        reasonOfFailure :response.data.message[0]
                      })
                  }
                })

            );
          }
        }
      });

    }

  };
  onChangeMonthly = (e) => {
    // if(this.state.promocode==='')
   
    this.setState({
      [e.target.name]: e.target.value,
      isCheckedAnnual: false,
      isCheckedMonthlyOff: false,
      isCheckedAnnualOff: false,
      isCheckedMonthly: true,
      isMonthly: true,
      quantity: '1',
      isLoading: true,
      isNotChecked: true,
      opacity: "40%",
      isError:false

    }, () =>
      client.get('checkout/previewOrders', {
        params: {
          product: this.state.productName,
          ratePlan: this.state.productRatePlanName,
          quantity: this.state.quantity,
          promoCode: this.state.promocode,
          isMonthly: this.state.isMonthly
        }
      })
        .then((response) => {
          console.log(response)
          this.setState({
            isToggle: true,
            shiffingFee: response.data.shiffingFee,
            subTotal: response.data.subTotal,
            total: response.data.total,
            productRateplanId: response.data.productRateplanId,
            productRatePlanChargeId: response.data.productRatePlanChargeId,
            isLoading: false,
            isNotChecked: false,
            opacity: '100%',
            proratedAmount: response.data.proratedAmount,
            rate: response.data.rate,
          });
          if(response.data.productRateplanId == null){
            this.setState(
              {
                 isToggle: false,
                isCheckedMonthly: false,
                isMonthly: false,
                isNotChecked: true,
                isError:true,
                reasonOfFailure :response.data.message[0]
              })
       
           

          }
          

        }));

  };

  
  // onChangeAnnual = (e) => {
  //   this.setState({
  //     [e.target.name]: e.target.value,
  //     isCheckedMonthly: false,
  //     isCheckedAnnual: true,
  //     isCheckedMonthlyOff: false,
  //     isCheckedAnnualOff: false,
  //     isMonthly: false,
  //     quantity: '1',
  //     isLoading: true,
  //     isNotChecked: true,
  //     opacity: "40%"
  //   }, () =>
  //     client.get('checkout/previewOrders', {
  //       params: {
  //         product: this.state.productName,
  //         ratePlan: this.state.productRatePlanName,
  //         quantity: this.state.quantity,
  //         promoCode: '',
  //         isMonthly: this.state.isMonthly
  //       }
  //     })
  //       .then((response) => {
  //         // console.log(response)
  //         this.setState({
  //           isToggle: true,
  //           shiffingFee: response.data.shiffingFee,
  //           subTotal: response.data.subTotal,
  //           total: response.data.total,
  //           productRateplanId: response.data.productRateplanId,
  //           productRatePlanChargeId: response.data.productRatePlanChargeId,
  //           isLoading: false,
  //           isNotChecked: false,
  //           opacity: "100%",
  //           proratedAmount: response.data.proratedAmount,
  //           rate: response.data.rate,
  //         })

  //       })
  //   );
  // };
  // onChangeMonthlyoff = (e) => {
  //   // if(this.state.promocode==='')
  //   this.setState({
  //     [e.target.name]: e.target.value,
  //     isCheckedMonthlyOff: true,
  //     isCheckedAnnualOff: false,
  //     isCheckedMonthly: false,
  //     isCheckedAnnual: false,
  //     isMonthly: true,
  //     quantity: '5',
  //     isLoading: true,
  //     isNotChecked: true,
  //     opacity: "40%"

  //   }, () =>
  //     client.get('checkout/previewOrders', {
  //       params: {
  //         product: this.state.productName,
  //         ratePlan: this.state.productRatePlanName,
  //         quantity: this.state.quantity,
  //         promoCode: 'showspecial',
  //         isMonthly: true,

  //       }
  //     })
  //       .then((response) => {
  //         // console.log(response)
  //         this.setState({
  //           isToggle: true,
  //           shiffingFee: response.data.shiffingFee,
  //           subTotal: response.data.subTotal,
  //           total: response.data.total,
  //           productRateplanId: response.data.productRateplanId,
  //           productRatePlanChargeId: response.data.productRatePlanChargeId,
  //           isLoading: false,
  //           isNotChecked: false,
  //           opacity: "100%",
  //           proratedAmount: response.data.proratedAmount,
  //           rate: response.data.rate,
  //           isError: false
  //           // isCheckedMonthly:this.state.isCheckedMonthly
  //         })

  //       }));

  // };

  // onChangeAnnualoff = (e) => {
  //   // if(this.state.promocode==='')

  //   this.setState({
  //     [e.target.name]: e.target.value,
  //     isCheckedMonthlyOff: false,
  //     isCheckedMonthly: false,
  //     isCheckedAnnual: false,
  //     isCheckedAnnualOff: true,
  //     isMonthly: false,
  //     quantity: '5',
  //     isLoading: true,
  //     isNotChecked: true,
  //     opacity: "40%"

  //   }, () =>
  //     client.get('checkout/previewOrders', {
  //       params: {
  //         product: this.state.productName,
  //         ratePlan: this.state.productRatePlanName,
  //         quantity: this.state.quantity,
  //         promoCode: 'showspecial',
  //         isMonthly: false
  //       }
  //     })
  //       .then((response) => {
  //         // console.log(response)
  //         this.setState({
  //           isToggle: true,
  //           shiffingFee: response.data.shiffingFee,
  //           subTotal: response.data.subTotal,
  //           total: response.data.total,
  //           productRateplanId: response.data.productRateplanId,
  //           productRatePlanChargeId: response.data.productRatePlanChargeId,
  //           isLoading: false,
  //           isNotChecked: false,
  //           opacity: "100%",
  //           proratedAmount: response.data.proratedAmount,
  //           rate: response.data.rate,
  //           isError: false,

  //           // isCheckedMonthly:this.state.isCheckedMonthly
  //         })

  //       }));

  // };


  handleAddPromo = () => {

   
    if (this.state.promocode === "") {
      this.setState((prevState) => {
        return {
          blankError: "no promo code",
          isInvalidPromo: false
        };
      });
    } else {
      // this.setState((prevState) => {
      //   return {
      //     isPromoCodeApplied: true,
      //     isInvalidPromo:false
      //   };
      // });
      if (this.state.productRatePlanName === 'Standard - Annual - Special 10') {
        this.setState({
          promoNotMatched: true,
          isPromoCodeApplied: true,
        })
      }

      else {
        if (this.state.promocode === 'FREESHIPPING' && this.state.productRatePlanName === 'Standard - Monthly') {
          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>

            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: 'Standard - Monthly - Free Shipping',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isCheckedMonthly,
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isCheckedMonthly: true,
                  isCheckedAnnual: false,
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: 'Standard - Monthly - Free Shipping',
                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        } else if (this.state.promocode === 'FREESHIPPING' && this.state.productRatePlanName === 'Standard - Annual') {
          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: 'Standard - Annual - Free Shipping',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isCheckedMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isCheckedMonthly: false,
                  isCheckedAnnual: true,
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: 'Standard - Annual - Free Shipping',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }
              })
          )
        } else if (this.state.promocode === 'showspecial' && this.state.productRatePlanName === 'Standard - Monthly') {
          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: 'Standard - Monthly - Special 10',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isCheckedMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isCheckedMonthly: true,
                  isCheckedAnnual: false,
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }
              })
          )
        }
        else if (this.state.promocode === '90dayfree' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: 'Standard - Free Trial - 90',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: 'Standard - Free Trial - 90',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        }
        //new ratePlans  @chpr starts
        else if (this.state.promocode === '3324A5' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: '5% off first year - monthly',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                 console.log(response);
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: '5% off first year - monthly',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }
              })
          )
        }
        else if (this.state.promocode === '3324A5freeship' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: '5% off first year free shipping - monthly',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: '5% off first year free shipping - monthly',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }
              })
          )
        }

        else if (this.state.promocode === '3149B10' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: '10% off first year - monthly',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: '10% off first year - monthly',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        }
        else if (this.state.promocode === '3149B10freeship' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: '10% off first year free shipping - monthly',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: '10% off first year free shipping - monthly',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        }

        else if (this.state.promocode === '2974C15' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: '15% off first year - monthly',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: '15% off first year - monthly',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        }
        else if (this.state.promocode === '2974C15freeship' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: '15% off first year free shipping - monthly',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: '15% off first year free shipping - monthly',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        }

        else if (this.state.promocode === '2799D20' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: '20% off first year - monthly',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: '20% off first year - monthly',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        }
        else if (this.state.promocode === '2799D20freeship' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: '20% off first year free shipping - monthly',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: '20% off first year free shipping - monthly',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        }

        else if (this.state.promocode === '2624E25' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: '25% off first year - monthly',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: '25% off first year - monthly',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        }

        else if (this.state.promocode === '2624E25freeship' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: '25% off first year free shipping - monthly',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: '25% off first year free shipping - monthly',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        }


        else if (this.state.promocode === '2700F23freeship' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: '$27 first year - Monthly - Free Shipping',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: '$27 first year - Monthly - Free Shipping',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        }
        else if (this.state.promocode === '6mofree' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: 'Monthly 6 months free',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: 'Monthly 6 months free',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        }
        else if (this.state.promocode === '90dayfreeplus10' && this.state.productRatePlanName === 'Standard - Monthly - Special 10') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: 'Monthly 90 days free plus 10% discount',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: 'Monthly 90 days free plus 10% discount',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        }

        else if (this.state.promocode === 'onemofreeship' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: 'Free month plus free shipping',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: 'Free month plus free shipping',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        }


        else if (this.state.promocode === '1mofree' && this.state.productRatePlanName === 'Standard - Monthly') {

          this.setState({

            isLoading: true,
            isNotChecked: true,
            opacity: "40%"

          }, () =>
            client.get('checkout/previewOrders', {
              params: {
                product: this.state.productName,
                ratePlan: 'Free month',
                quantity: this.state.quantity,
                promoCode: this.state.promocode,
                isMonthly: this.state.isMonthly
              }
            })
              .then((response) => {
                // console.log(response)
                this.setState({
                  isToggle: true,
                  shiffingFee: response.data.shiffingFee,
                  subTotal: response.data.subTotal,
                  total: response.data.total,
                  productRateplanId: response.data.productRateplanId,
                  productRatePlanChargeId: response.data.productRatePlanChargeId,
                  promoCode: this.state.promocode,
                  isPromoCodeApplied: true,
                  isInvalidPromo: false,
                  rate: response.data.rate,
                  proratedAmount: response.data.proratedAmount,
                  isLoading: false,
                  isNotChecked: false,
                  opacity: '100%',
                  promoNotMatched: false,
                  productRatePlanName: 'Free month',

                })
                if(response.data.productRateplanId == null){
                  this.setState(
                    {
                       isToggle: false,
                      isCheckedMonthly: false,
                      isMonthly: false,
                      isNotChecked: true,
                      isError:true,
                      reasonOfFailure :response.data.message[0]
                    })
                 
                 }

              })
          )
        }

        else {
          this.setState({
            promoNotMatched: true,
            isPromoCodeApplied: true,
          })
        }
      }
    }
  };

  handleRemovePromo = () => {
    this.setState((prevState) => {
      return {
        isPromoCodeApplied: !prevState.isPromoCodeApplied,
        promocode: "",
        blankError: "",
        isError:false
      };
    });

    if (this.state.isCheckedMonthly === true) {
      this.setState({

        isLoading: true,
        isNotChecked: true,
        opacity: "40%"

      }, () =>
        client.get('checkout/previewOrders', {
          params: {
            product: this.state.productName,
            ratePlan: 'Standard - Monthly',
            quantity: this.state.quantity,
            promoCode: '',
            isMonthly: this.state.isCheckedMonthly
          }
        })
          .then((response) => {

            this.setState({
              isToggle: true,
              shiffingFee: response.data.shiffingFee,
              subTotal: response.data.subTotal,
              total: response.data.total,
              productRateplanId: response.data.productRateplanId,
              productRatePlanChargeId: response.data.productRatePlanChargeId,
              promoCode: this.state.promocode,
              rate: response.data.rate,
              proratedAmount: response.data.proratedAmount,
              isLoading: false,
              isNotChecked: false,
              opacity: '100%',
              productRatePlanName: 'Standard - Monthly'
            })
           

          })
      )
    }


    


  };


  
  handleContinue = () => {




    localStorage.setItem('quantity', this.state.quantity);
    localStorage.setItem('shiffingFee', this.state.shiffingFee);
    localStorage.setItem('subTotal', this.state.subTotal);
    localStorage.setItem('total', this.state.total);
    localStorage.setItem('productRateplanId', this.state.productRateplanId);
    localStorage.setItem('productRatePlanChargeId', this.state.productRatePlanChargeId);
    localStorage.setItem('isMonthly', this.state.isMonthly);



    this.props.history.push({
      pathname: `/nec/existingCustomerBilling/`,

    });

  }
  handleAddToCart = () => {


  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };


  render() {
    let Spinner = null;
    if (this.state.isLoading === true) {
      Spinner = <Loader />;
    }

    const renderPromocode = () => {

      if (this.state.promoNotMatched === true) {

        if (this.state.productRatePlanName === 'Standard - Annual' || this.state.productRatePlanName === 'Standard - Annual - Special 10') {
          if (this.state.promocode === 'showspecial') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>
          }
          if (this.state.promocode === '90dayfree') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>
          }
          if (this.state.promocode === '6mofree') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>
          }
          if (this.state.promocode === '90dayfreeplus10') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>

          }
          if (this.state.promocode === '3324A5') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>

          } if (this.state.promocode === '3324A5freeship') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>
          } if (this.state.promocode === '3149B10') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>

          } if (this.state.promocode === '3149B10freeship') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>
          } if (this.state.promocode === '2974C15') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>
          } if (this.state.promocode === '2974C15freeship') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>

          } if (this.state.promocode === '2799D20') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>
          } if (this.state.promocode === '2799D20freeship') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>

          } if (this.state.promocode === '2624E25') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>

          } if (this.state.promocode === '2624E25freeship') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>
          } if (this.state.promocode === '2624E25freeship') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>

          }

          if (this.state.promocode === 'onemofreeship') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>

          }

          if (this.state.promocode === '1mofree') {

            return <p style={{ width: '135%' }}><small className="font-weight-bold text-danger" >This promo code is limited to the Standard Monthly Plan</small></p>

          }

        }

        return <small className="font-weight-bold text-danger">Invalid Promo Code</small>
      } else {

        if (this.state.promocode === 'showspecial') {
          return <small className="font-weight-bold">showspecial</small>
        } else if (this.state.promocode === 'FREESHIPPING') {
          return <small className="font-weight-bold">FREESHIPPING</small>
        } else if (this.state.promocode === '90dayfree') {
          return <small className="font-weight-bold">90dayfree</small>
        } else if (this.state.promocode === '6mofree') {
          return <small className="font-weight-bold">6mofree</small>
        } else if (this.state.promocode === '90dayfreeplus10') {
          return <small className="font-weight-bold">90dayfreeplus10</small>
        } else if (this.state.promocode === '3324A5') {
          return <small className="font-weight-bold">3324A5</small>
        } else if (this.state.promocode === '3324A5freeship') {
          return <small className="font-weight-bold">3324A5freeship</small>
        } else if (this.state.promocode === '3149B10') {
          return <small className="font-weight-bold">3149B10</small>
        } else if (this.state.promocode === '3149B10freeship') {
          return <small className="font-weight-bold">3149B10freeship</small>
        } else if (this.state.promocode === '2974C15') {
          return <small className="font-weight-bold">2974C15</small>
        } else if (this.state.promocode === '2974C15freeship') {
          return <small className="font-weight-bold">2974C15freeship</small>
        } else if (this.state.promocode === '2799D20') {
          return <small className="font-weight-bold">2799D20</small>
        } else if (this.state.promocode === '2799D20freeship') {
          return <small className="font-weight-bold">2799D20freeship</small>
        } else if (this.state.promocode === '2624E25') {
          return <small className="font-weight-bold">2624E25</small>
        } else if (this.state.promocode === '2624E25freeship') {
          return <small className="font-weight-bold">2624E25</small>
        } else if (this.state.promocode === '2700F23freeship') {
          return <small className="font-weight-bold">2700F23freeship</small>

        }
        else {
          return <small className="font-weight-bold text-danger">Invalid Promo Code</small>
        }
      }
    }

    let PromoCodeBox = null;
    if (this.state.isPromoCodeApplied) {
      PromoCodeBox = (
        <div>
          {" "}
          <div
            className="form-group pt-3 ml-n3 mr-n3"
            style={{ borderBottom: "1px solid #ced4da" }}
          ></div>
          <div className="form-row pt-2">
            <div className="col">

              {renderPromocode()}


            </div>
            <div className="col" style={{ cursor: 'pointer' }}>
              <a
                onClick={this.handleRemovePromo}
                className="float-right text-danger"
              >
                Remove
              </a>
            </div>
          </div>
          {
            this.state.promocode === 'SPECIAL10' ?
              <p className="font-italic small">
                {/* 10% disscount for one year subscription */}
              </p>
              :
              <p className="font-italic small">
                {/* FREESHIPPING */}
              </p>
          }
        </div>
      );

    }
    let InvalidPromo = null;
    if (this.state.isInvalidPromo) {
      InvalidPromo = (
        <div>
          <p className="mb-0 text-danger">
            <small>Invalid promocode</small>
          </p>
        </div>
      )
    };
    return (

      <div className="fill-window">
        <Header image={SecurePayment} text="secure payment" />
        <div className="d-flex mt-3 w-100 ">
          <div className="col-3"></div>
          <div className=" w-100">
            <div style={{ display: "inline-block" }}>
              <span className={myClass.dot}></span>
            </div>

            <div
              style={{
                display: "inline-block",
                width: "30%",
                border: "solid 1px #ced4da",
                position: "relative",
                top: -4,
              }}
            ></div>

            <div style={{ display: "inline-block" }}>
              <span
                className={myClass.dot}
                style={{ backgroundColor: "#ced4da" }}
              ></span>
            </div>

            <div
              style={{
                display: "inline-block",
                width: "30%",
                border: "solid 1px #ced4da",
                position: "relative",
                top: -4,
              }}
            ></div>

            <div style={{ display: "inline-block" }}>
              <span
                className={myClass.dot}
                style={{ backgroundColor: "#ced4da" }}
              ></span>
            </div>
          </div>
        </div>
        {/* test */}
        <div className="d-flex  mt-n2 w-100 ">
          {/* <div className="col-1"></div> */}
          <div className=" w-100">
            <div style={{ display: "inline-block", width: "29%" }}>
              <p className="float-right" style={{ color: "#3e92cc" }}>
                {" "}
                Your Order
              </p>
            </div>

            <div style={{ display: "inline-block", width: "21%" }}>
              <p className="float-right " style={{ color: "#ced4da" }}>
                Billing
              </p>
            </div>

            <div
              className="ml-3"
              style={{ display: "inline-block", width: "25%" }}
            >
              <p className="float-right" style={{ color: "#ced4da" }}>
                Confirmation
              </p>
            </div>
          </div>
        </div>
        {this.state.isError ? <OrderError reasonOfFailure={this.state.reasonOfFailure} /> : ''}
        {/*  */}
        {/* two boxes */}
        {Spinner}
        <div className="row w-100" style={{ opacity: this.state.opacity }}>
          <div className="col-md-2  mr-n2"></div>
          <div class="col-md-5 pt-2 ml-3 ">
            <div class="card">
              <div class="card-body">
                <div className="row pr-0">
                  <div className=" col pl-3">
                    <h5 class="card-title ">GuestView Guide</h5>
                  </div>

                  <div className="col pr-4 ">
                    <p class="card-text text-primary float-right">

                    </p>
                  </div>
                </div>
                {/* <p className="pt-1">Annual plan, pay monthly - USD $39.99</p> */}
                <div className="pt-2">

                  <div class="form-check d-flex pb-2">
                    <input class="form-check-input row " onChange={this.onChangeMonthly} type="radio" name="productRatePlanName" id="inlineRadio1" checked={this.state.isCheckedMonthly} value="Standard - Monthly" />
                    <label class="form-check-label row pl-3" for="inlineRadio1"><small> Standard - Monthly - USD $39.99</small></label>
                  </div>
                  {/* <div class="form-check d-flex pb-2">
                      <input class="form-check-input row" onChange={this.onChangeAnnual} type="radio" name="productRatePlanName" id="inlineRadio2" checked={this.state.isCheckedAnnual} value="Standard - Annual" />
                      <label class="form-check-label row pl-3" for="inlineRadio2"><small>Standard - Annual - USD $419.88</small></label>
                    </div>

                    <div class="form-check d-flex pb-2">
                      <input class="form-check-input row" onChange={this.onChangeMonthlyoff} type="radio" name="productRatePlanName" id="inlineRadio3" checked={this.state.isCheckedMonthlyOff} value="Standard - Monthly - Special 10" />
                      <label class="form-check-label row pl-3" for="inlineRadio3"><small>10% off for 5 or more – Monthly </small></label>
                    </div>

                    <div class="form-check d-flex pb-2">
                      <input class="form-check-input row" onChange={this.onChangeAnnualoff} type="radio" name="productRatePlanName" id="inlineRadio4" checked={this.state.isCheckedAnnualOff} value="Standard - Annual - Special 10" />
                      <label class="form-check-label row pl-3" for="inlineRadio4"><small>10% off for 5 or more – Annual</small></label>
                    </div> */}

                </div>

                <div className="w-50 h-25 d-flex pt-2 mt-4  ml-0">
                  
                  <p > QTY:</p><input className="  rounded form-control col-sm-3 h-25 ml-3 mt-n1  "  type="number"
                    min="1" name="quantity" value={this.state.quantity} onChange={this.onChangeQTY} onBlur={this.onChangeQuantity}></input>
                </div>
                {this.state.isQuantityError ? <div style={{color:"red", fontSize:"13px"}}>Please enter valid quantity.</div>: <div></div>}

                <div className="pt-4 border-bottom"></div>

                <div className="row pt-2">
                  {this.state.isMonthly ?
                    <div className="col font-weight-bold">
                      <p class="card-title">Total monthly rate</p>
                    </div>
                    :
                    <div className="col font-weight-bold">
                      <p class="card-title">Total annual rate</p>
                    </div>
                  }
                  <div className="col font-weight-bold">
                    {this.state.isToggle ?
                      this.state.isCheckedMonthly ?
                        <p class="float-right">${this.state.rate}</p>
                        :
                        <p class="float-right">${this.state.rate}</p>

                      :
                      <p class="float-right"></p>
                    }
                  </div>
                </div>
                <div className="mt-1">
                  {this.state.isMonthly ?
                    <p className="font-italic font-weight-bold small">
                      The total monthly rate plus taxes will be charged monthly to your selected
                      payment option
                  </p>
                    :
                    <p className="font-italic font-weight-bold small">
                      The total annual rate plus taxes will be charged annually to your selected
                      payment option
                  </p>
                  }

                </div>


              </div>
            </div>
          </div>

          {/* {Order Summary box} */}
          <div className="col-md-3 ml-3 pt-2">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Order Summary</h5>
                {/* <p className="small">First payment prorated to the 1st of the month</p> */}
                <div className="form-row">
                  <div className="col">
                    <p>Subtotal ({this.state.quantity} Qty)</p>
                  </div>
                  <div className="col ">

                    {this.state.isToggle ?
                      <p class="float-right">${this.state.subTotal}</p>
                      :
                      <p class="float-right">$0.00</p>
                    }

                  </div>
                </div>
                <div className="form-row mt-n3">
                  <div className="col">
                    <p>Shipping ({this.state.quantity} Qty)</p>
                  </div>
                  <div className="col ml-n5">

                    {this.state.isToggle ?
                      <p class="float-right">${this.state.shiffingFee}</p>
                      :
                      <p class="float-right">$0.00</p>
                    }
                  </div>
                </div>
                <div className="form-row mt-5">
                  <div className="col">
                    <p>Total</p>
                  </div>
                  <div className="col ml-n5">

                    {this.state.isToggle ?
                      <p class="float-right">${this.state.total}</p>
                      :
                      <p class="float-right">$0.00</p>
                    }
                  </div>
                </div>


                {/* {promocode box} */}
                <div
                  className="form-group pt-2 ml-n3 mr-n3"
                  style={{ borderBottom: "1px solid #ced4da" }}
                ></div>
                <div>
                  <small>Add a promo code</small>
                </div>
                <div className="form-row pt-2">
                  <div className="col">
                    <input
                      type="text"
                      name="promocode"
                      value={this.state.promocode}
                      placeholder={this.state.blankError}
                      onChange={this.onChange}
                      className="rounded form-control"
                      disabled={this.state.isNotChecked ||this.state.isPromoCodeApplied && !this.state.promoNotMatched }
                    ></input>{" "}
                  </div>
                  <div className="col">
                    <button
                      type="submit"
                      onClick={this.handleAddPromo}
                      className={myClass.addPromoButton}
                      disabled={this.state.isPromoCodeApplied && !this.state.promoNotMatched }
                    >
                      Apply
                    </button>
                  </div>
                </div>
                {InvalidPromo}
                {/* {promocode Applied box} */}
                {PromoCodeBox}
              </div>
            </div>
            <div >
            <button
            className={myClass.continueButton}
            disabled
          >
            Continue
          </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    pricing: state.order,

  };
}
export default connect(mapStateToProps, { getProductList })(AddPlan);