import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { upperCase } from 'lodash';
import { useHistory } from 'react-router-dom';

import Screen from '../../../assets/images/screen.jpg';
import OrderSummary from '../../../components/orderSummary';
import iconWaiting from '../../../assets/images/waitingSvg.svg';
import { previewOrder, previewOrderAsync, jobStatus } from '../../../services/selectPlan';

import MESSAGES from '../../../constants/messages';
import { RATE_PLANS_WITH_PROMO_CODE } from '../../../constants/ratePlans';
import { AppContext } from '../../../context/app';

// eslint-disable-next-line react/function-component-definition
export default function SelectPlan({
  isMonthly,
  rate,
  setRate,
  quantity,
  setQuantity,
  shippingFee,
  setShippingFee,
  subTotal,
  setSubTotal,
  total,
  setTotal,
  isPromoCodeApplied,
  setIsPromoCodeApplied,
  promocode,
  setPromocode,
  blankError,
  promoNotMatched,
  selectedPlanId,
  setselectedPlanId,
  ratePlans,
  handleContinue,
  setproductRatePlanChargeId,
  setproductRateplanId,
}) {
  const history = useHistory();
  const [enableSubmit, setEnableSubmit] = useState(
    history.location.state?.enableSubmit || false,
  );
  const [isValidPromo, setIsValidPromo] = useState(true);
  const [getSummaryRetry, setGetSummaryRetry] = useState(1);
  const { loading, showMessage, messageText } = useContext(AppContext);
  const [, setIsLoading] = loading;
  const [, setShowMsg] = showMessage;
  const [, setMessage] = messageText;

  const changePlan = () => {
    setselectedPlanId(null);
  };


  const showError = () => {
    setIsLoading(false);
    setEnableSubmit(false);
    setShowMsg(true);
    setMessage(MESSAGES.CONTACT_HELP_IF_PROBLEM_ARRIVE);
    setShippingFee(0);
    setSubTotal(0);
    setTotal(0);
    setRate(0);
  };

  const asyncApiForOrderSummary = async (jobId, params) => {
    try {
      const response = await jobStatus(params, jobId);
      const { status } = response.data;
      if (status === 'Completed') {
        if (
          !response.data.result
        ) {
          showError();
        } else if (response.data.result) {
          setIsLoading(false);
          setShippingFee(response.data.result.shiffingFee);
          setSubTotal(response.data.result.subTotal);
          setTotal(response.data.result.total);
          setproductRateplanId(response.data.result.productRateplanId);
          setproductRatePlanChargeId(response.data.result.productRatePlanChargeId);
          setRate(response.data.result.rate);
          setEnableSubmit(true);
        }
      } else if (status === 'Processing') {
        setTimeout(() => {
          asyncApiForOrderSummary(jobId, params);
          if (getSummaryRetry > 1) setGetSummaryRetry(1);
        }, 3000);
      } else if (status === 'Failed') {
        showError();
      } else if (status === null || status === undefined) {
        if (getSummaryRetry < 3) {
          setTimeout(() => {
            asyncApiForOrderSummary(jobId, params);
          }, process.env.REACT_APP_POLLING_DELAY);
          setGetSummaryRetry(getSummaryRetry + 1);
        } else {
          showError();
        }
      }
    } catch (e) {
      showError();
    }
  };

  
 
  const submitPlan = async (params) => {
    if (!quantity || quantity < 1) {
      setShowMsg(true);
      setMessage(
        MESSAGES.QUANTITY_NOT_PRESENT,
      );
      return;
    }
    setIsLoading(true);
    // const response = await previewOrder(params);
    try {
      const response = await previewOrderAsync(params);
      if (response.data.jobId) {
        asyncApiForOrderSummary(response.data.jobId, params);
      } else {
        showError();
      }
    } catch (e) {
      showError();
    }
  };

  const selectPlan = (id) => {
    setselectedPlanId(id);
    const selectedPlan = ratePlans[id - 1];
    const filteredPlan = (selectedPlan
        && promocode
        && RATE_PLANS_WITH_PROMO_CODE.find(
          (plan) => plan.productRatePlanName === selectedPlan.ratePlan
            && plan.promoCode === promocode,
        ))
      || {};
    submitPlan({
      quantity,
      product: selectedPlan.product,
      isMonthly: selectedPlan.isMonthly,
      ratePlan: selectedPlan.ratePlan,
      promoCode: promocode,
      ...filteredPlan,
    });
  };

  const changeQuantity = () => {
    const selectedPlan = ratePlans[selectedPlanId - 1];
    const filteredPlan = (selectedPlanId
        && selectedPlan
        && promocode
        && RATE_PLANS_WITH_PROMO_CODE.find(
          (plan) => plan.productRatePlanName === selectedPlan.ratePlan
            && plan.promoCode === promocode,
        ))
      || {};
    submitPlan({
      quantity,
      product: selectedPlan.product,
      isMonthly: selectedPlan.isMonthly,
      ratePlan: selectedPlan.ratePlan,
      promoCode: promocode,
      ...filteredPlan,
    });
  };

  const handleAddPromo = () => {
    const findFilteredPlan = (ratePlans[selectedPlanId - 1]
        && promocode
        && RATE_PLANS_WITH_PROMO_CODE.find(
          (plan) => plan.productRatePlanName
              === ratePlans[selectedPlanId - 1].ratePlan
            && plan.promoCode === promocode,
        ))
      || {};
    submitPlan({
      ...ratePlans[selectedPlanId - 1],
      quantity,
      ...findFilteredPlan,
    });
    if (Object.keys(findFilteredPlan).length === 0) {
      setIsValidPromo(false);
    }
    setIsPromoCodeApplied(true);
  };

  const handleRemovePromo = () => {
    const selectedPlan = ratePlans[selectedPlanId - 1];
    submitPlan({
      quantity,
      product: selectedPlan.product,
      isMonthly: selectedPlan.isMonthly,
      ratePlan: selectedPlan.ratePlan,
      promoCode: '',
    });
    setIsValidPromo(true);
  };

  return (
    <div className="w-100 has-top-border">
      <div className="container-fluid w-1120">
        <div className="row x-center">
          <div className="col-lg-8 col-sm-12 mt-4">
            <div className="card order-left-col mb-4">
              <div className="card-body">
                <form>
                  {selectedPlanId ? (
                    <div>
                      <div className="plan-detail">
                        <div className="gvg-screen pr-4">
                          <img src={Screen} alt="" />
                        </div>
                        <div className="flex-grow-1">
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="plan-title">
                              {MESSAGES.YOUR_GUESTVIEW_GUIDE}
                              &trade;
                              {' '}
                              {MESSAGES.PLAN}
                            </h5>
                            <a className="plan-change" onClick={changePlan}>
                              {MESSAGES.CHANGE_PLAN}
                            </a>
                          </div>
                          {ratePlans.map((plan) => {
                            if (plan.id === selectedPlanId) {
                              return (
                                <div key={plan.id} className="plan-name">
                                  <h6>
                                    {plan.label}
                                    <span>{plan.description}</span>
                                  </h6>
                                  <strong>{`$${plan.price}`}</strong>
                                </div>
                              );
                            }
                            return null;
                          })}

                          <ul className="plan-listing">
                            <li>
                              <i className="fal fa-tablet" />
                              {MESSAGES.HD_TOUCHSCREEN_2}
                            </li>
                            <li>
                              <i className="far fa-tachometer" />
                              {MESSAGES.WEBSITE_DASHBOARD_2}
                            </li>
                            <li>
                              <i className="fal fa-sync" />
                              {MESSAGES.AUTO_SOFTWARE_UPDATES_2}
                            </li>
                            <li>
                              <i className="fal fa-user-headset" />
                              {MESSAGES.SUPPORT_FROM_NEC}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="qty-rate">
                        <p>
                          <label htmlFor="quantity">
                            {`${upperCase(MESSAGES.QTY)}:`}
                          </label>
                          <span>
                            <input
                              type="number"
                              min="1"
                              max="250"
                              id="quantity"
                              name="quantity"
                              value={quantity}
                              onChange={(e) => setQuantity(e.target.value)}
                              onBlur={changeQuantity}
                            />
                          </span>
                        </p>

                        <p className="qty-total">
                          {isMonthly ? (
                            <label>{`${MESSAGES.TOTAL_MONTHLY_RATE}:`}</label>
                          ) : (
                            <label>{`${MESSAGES.TOTAL_ANNUAL_RATE}:`}</label>
                          )}
                          <span>{`$${rate}`}</span>
                        </p>

                        <p className="rate-note">
                          {isMonthly ? (
                            <small>
                              {`*${MESSAGES.MONTHLY_TAX_DISCLAIMER}`}
                            </small>
                          ) : (
                            <small>
                              {`*${MESSAGES.ANNUAL_TAX_DISCLAIMER}`}
                            </small>
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <h5 className="plan-title">
                          {MESSAGES.YOUR_GUESTVIEW_GUIDE}
                          &trade;
                          {' '}
                          {MESSAGES.PLAN}
                        </h5>
                      </div>
                      <div className="plan-detail plans">
                        <div className="gvg-screen pr-4">
                          <img src={Screen} alt="" />
                        </div>
                        <div className="flex-grow-1">
                          <p className="mb-2">{MESSAGES.CHOOSE_YOUR_PLAN}</p>
                          {ratePlans.map((plan) => (
                            <div
                              role="button"
                              tabIndex={0}
                              key={plan.id}
                              onClick={() => selectPlan(plan.id)}
                              className={`plan-name ${selectedPlanId
                                === plan.id && 'selected'}`}
                            >
                              <h6>
                                {plan.label}
                                <span>{plan.description}</span>
                              </h6>
                              <strong>{`$${plan.price}`}</strong>
                            </div>
                          ))}
                          <ul className="plan-listing">
                            <li>
                              <i className="fal fa-tablet" />
                              {MESSAGES.HD_TOUCHSCREEN}
                            </li>
                            <li>
                              <i className="fal fa-sync" />
                              {MESSAGES.AUTO_SOFTWARE_UPDATES}
                            </li>
                            <li>
                              <i className="far fa-tachometer" />
                              {MESSAGES.WEBSITE_DASHBOARD}
                            </li>
                            <li>
                              <i className="fal fa-user-headset" />
                              {MESSAGES.SUPPORT_FROM_NEC}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-12">
            <div className="card rounded-0 order-col pt-4">
              {selectedPlanId ? (
                <OrderSummary
                  order
                  ratePlans={ratePlans}
                  quantity={quantity}
                  shippingFee={shippingFee}
                  subTotal={subTotal}
                  total={total}
                  isPromoCodeApplied={isPromoCodeApplied}
                  setIsPromoCodeApplied={setIsPromoCodeApplied}
                  promocode={promocode}
                  selectedPlanId={selectedPlanId}
                  blankError={blankError}
                  promoNotMatched={promoNotMatched}
                  handleContinue={handleContinue}
                  handleAddPromo={handleAddPromo}
                  setPromocode={setPromocode}
                  enableSubmit={enableSubmit}
                  handleRemovePromo={handleRemovePromo}
                  isValidPromo={isValidPromo}
                />
              ) : (
                <div>
                  <div className="card-body">
                    <h5 className="card-title">{MESSAGES.ORDER_SUMMARY}</h5>
                    <div className="onWaiting">
                      <span className="onWaitText">
                        <img src={iconWaiting} alt="" />
                        {MESSAGES.SELECT_YOUR_PLAN}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SelectPlan.propTypes = {
  isMonthly: PropTypes.bool,
  rate: PropTypes.number,
  quantity: PropTypes.number,
  shippingFee: PropTypes.number,
  subTotal: PropTypes.number,
  total: PropTypes.number,
  isPromoCodeApplied: PropTypes.bool,
  promocode: PropTypes.string,
  blankError: PropTypes.string,
  promoNotMatched: PropTypes.bool,
  selectedPlanId: PropTypes.number,
  ratePlans: PropTypes.arrayOf(PropTypes.object),
  handleContinue: PropTypes.func,
  setproductRatePlanChargeId: PropTypes.func,
  setproductRateplanId: PropTypes.func,
  setRate: PropTypes.func,
  setQuantity: PropTypes.func,
  setShippingFee: PropTypes.func,
  setSubTotal: PropTypes.func,
  setTotal: PropTypes.func,
  setIsPromoCodeApplied: PropTypes.func,
  setselectedPlanId: PropTypes.func,
  setPromocode: PropTypes.func,
};

SelectPlan.defaultProps = {
  isMonthly: true,
  rate: 0,
  quantity: 1,
  shippingFee: 0,
  subTotal: 0,
  total: 0,
  isPromoCodeApplied: false,
  promocode: '',
  blankError: '',
  promoNotMatched: false,
  selectedPlanId: null,
  ratePlans: [],
  handleContinue: () => {},
  setproductRatePlanChargeId: () => {},
  setproductRateplanId: () => {},
  setRate: () => {},
  setQuantity: () => {},
  setShippingFee: () => {},
  setSubTotal: () => {},
  setTotal: () => {},
  setIsPromoCodeApplied: () => {},
  setselectedPlanId: () => {},
  setPromocode: () => {},
};
