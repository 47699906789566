import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class AccountPlanButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleNewtab = () => {
    window.open("/nec/setting/accountbilling/accountplan");
  };
  handleBack = () => {
    window.close();
    
  };
  handleClikPayment() {
    this.props.history.push({
      pathname: `/nec/billing`,
    });
  }
  handleAccountPlan = () => {
   
    window.location.href=`/nec/setting/accountbilling/accountplan`
  };
  handleReceipts = () => {
   
    window.location.href=`/nec/setting/accountbilling/receipts`
  };
  PaymentDetails = () => {
    
    window.location.href=`/nec/setting/accountbilling/paymentdetails`
  };
  handleNotifications = () => {};

  render() {
    
    let AccountPlan = null;
    if (
      this.props.location.pathname === "/nec/setting/accountbilling/accountplan"
    ) {
      AccountPlan = (
        <div
          className="pl-2 pr-3 pt-1 pb-1 border-right-0 border  text-white"
          style={{ backgroundColor: "#3e92cc", cursor: "pointer" }}
          onClick={this.handleAccountPlan}
        >
          Account & Plan
        </div>
      );
    } else {
      AccountPlan = (
        <div
          className="pl-2 pr-3 pt-1 pb-1 border-right-0 border bg-white"
          style={{ color: "#3e92cc", cursor: "pointer" }}
          onClick={this.handleAccountPlan}
        >
          Account & Plan
        </div>
      );
    }
    let Receipts = null;
    if (
      this.props.location.pathname === "/nec/setting/accountbilling/receipts"
    ) {
      Receipts = (
        <div
          className="pl-4 pr-4 pt-1 pb-1 border-left-0 border-right-0 border text-white pointer"
          style={{ backgroundColor: "#3e92cc", cursor: "pointer" }}
          onClick={this.handleReceipts}
        >
         &nbsp;&nbsp;Receipts&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      );
    } else {
      Receipts = (
        <div
          className="pl-4 pr-4 pt-1 pb-1 bg-white border-left-0 border-right-0 border"
          style={{ color: "#3e92cc", cursor: "pointer" }}
          onClick={this.handleReceipts}
        >
         &nbsp;&nbsp; Receipts&nbsp;&nbsp;&nbsp;
        </div>
      );
    }
   
   
    let Notification = null;
    if (this.state.isNotification) {
      Notification = (
        <div
          className="pl-3 pr-3 pt-1 pb-1  text-white border-left-0 border-right-0 border"
          style={{ backgroundColor: "#3e92cc", cursor: "pointer" }}
          onClick={this.handleNotifications}
        >
          Notifications&nbsp;&nbsp;
        </div>
      );
    } else {
      Notification = (
        <div
          className="pl-3 pr-3 pt-1 pb-1 bg-white border-left-0 border-right-0 border"
          style={{ color: "#3e92cc", cursor: "pointer" }}
          onClick={this.handleNotifications}
        >
          Notifications&nbsp;&nbsp;&nbsp;
        </div>
      );
    }
      let PaymentDetails = null;
      if (
        this.props.location.pathname === "/nec/setting/accountbilling/paymentdetails"
      ) {
        PaymentDetails = (
          <div
            className="pl-2 pr-2 pt-1 pb-1  border-left-0 border-right border text-white pointer"
            style={{ backgroundColor: "#3e92cc", cursor: "pointer" }}
            onClick={this.PaymentDetails}
          >
           Payment Details&nbsp;
          </div>
        );
      } else {
        PaymentDetails = (
          <div
            className="pl-2 pr-2 pt-1 pb-1 bg-white border-left-0 border-right border"
            style={{ color: "#3e92cc", cursor: "pointer" }}
            onClick={this.PaymentDetails}
          >
           Payment Details&nbsp;
          </div>
        );
      } 
     
      let Newtab = null;
      
        Newtab = (
          <div
          className="pl-2 pr-2 pt-1 pb-1 bg-white border-left-0 border-right-0 border"
            style={{ color: "#3e92cc",cursor: "pointer" }}
            onClick={this.handleNewtab}
          >
           &nbsp;&nbsp;Newtab&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        );
    
     let Back = null;

     Back = (
          <div
          className="pl-2 pr-2 pt-1 pb-1 bg-white border-left-0 border-right border"
            style={{ color: "#3e92cc", cursor: "pointer" }}
            onClick={this.handleBack}
          >
           &nbsp;&nbsp;Back&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        );
    return (
      <div className="ml-3 w-50">
        <div className="row">
          {AccountPlan}
          {Receipts}
         
         {PaymentDetails}
        
           
              
             
           
              
        
        </div>
      </div>
    );
  }
}
export default withRouter(AccountPlanButtons);