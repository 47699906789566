import React, { useState, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';

export const AppContext = createContext();

// eslint-disable-next-line react/function-component-definition
export default function AppContextProvider(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [message, setMessage] = useState(false);

  const value = useMemo(
    () => ({
      loading: [isLoading, setIsLoading],
      showMessage: [showMsg, setShowMsg],
      messageText: [message, setMessage],
    }),
    [isLoading, showMsg, message],
  );
  const { children } = props;
  return (
    <AppContext.Provider value={value}>
      <Router>{children}</Router>
    </AppContext.Provider>
  );
}

AppContextProvider.defaultProps = {
  children: React.node,
};

AppContextProvider.propTypes = {
  children: PropTypes.node,
};
