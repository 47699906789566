import * as yup from 'yup';
import MESSAGES from '../constants/messages';

const accountSchema = yup.object().shape({
  firstName: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  lastName: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  city: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  workEmail: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  address1: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  zipCode: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  state: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  country: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
});

export default accountSchema;
