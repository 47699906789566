import MESSAGES from './messages';
import { APP_ROUTES } from './routes';

export const BASE_URL = process.env.REACT_APP_BACKEND_URL;
export const NEC_URL = process.env.REACT_APP_NEC_URL;

export const ORDER_STEPS = [MESSAGES.YOUR_ORDER, MESSAGES.BILLING, MESSAGES.CONFIRMATION];
export const ORDER_STEP = {
  YOUR_ORDER: 0,
  BILLING: 1,
  CONFIRMATION: 2,
};
export const ORDER_STEPS_ROUTE = [
  APP_ROUTES.ORDER, APP_ROUTES.BILLING, APP_ROUTES.CONFIRMATION,
];

export const SUBSCRIPTION_TABLE_COLS = [
  MESSAGES.PLAN_TH,
  MESSAGES.BILLING_CYCLE,
  MESSAGES.AUTO_RENEW,
  MESSAGES.NEXT_BILLING,
  MESSAGES.AMOUNT,
];

export const RECEIPTS_TABLE_COLS = [
  MESSAGES.INVOICE_NO,
  MESSAGES.ACCOUNT_NAME,
  MESSAGES.INVOICE_DATE,
  MESSAGES.DUE_DATE,
  MESSAGES.AMOUNT,
  MESSAGES.BALANCE,
  MESSAGES.STATUS,
];

export const PAYMENT_TABLE_COLS = [
  MESSAGES.REFERENCE_NO,
  MESSAGES.ACCOUNT_NAME,
  MESSAGES.PAYMENT_DATE,
  MESSAGES.TYPE,
  MESSAGES.AMOUNT,
  MESSAGES.PAID_INVOICES,
  MESSAGES.STATUS,
];

export const PAYMENT_CARD_TYPES = {
  VISA: 'Visa',
  DISCOVER: 'Discover',
  MASTERCARD: 'MasterCard',
  AMERICAN_EXPRESS: 'AmericanExpress',
};

export const PAYMENT_CARDS_INITIAL_OPACITY = {
  [PAYMENT_CARD_TYPES.VISA]: '30%',
  [PAYMENT_CARD_TYPES.DISCOVER]: '30%',
  [PAYMENT_CARD_TYPES.MASTERCARD]: '30%',
  [PAYMENT_CARD_TYPES.AMERICAN_EXPRESS]: '30%',
};
