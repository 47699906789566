import React, { Component } from 'react';
import TopLogo from '../../assets/images/logo@2xWhite.png';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (

      <div>
        <nav className="navbar" style={{ backgroundColor: '#16425b' }}>

          <img onClick={this.props.handleLocation} src={TopLogo} style={{ width: '20%', paddingLeft: '1%', cursor: this.props.handleLocation ? 'pointer' : '' }} />
          <ul className="nav nav-pills">
            <img className="" src={this.props.image} style={{ width: '20%', paddingRight: '5px' }} />
            <li className="text-white">
              {this.props.text}

            </li>
          </ul>

        </nav>
      </div>

    );
  }
}
export default Header;
