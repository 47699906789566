import * as yup from 'yup';
import MESSAGES from '../constants/messages';

const signupSchema = yup.object().shape({
  email: yup.string().email(MESSAGES.INVALID_EMAIL)
    .required(MESSAGES.CANNOT_BE_EMPTY),
  password: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  confirmPassword: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  companyName: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
});

export default signupSchema;
