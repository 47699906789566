import React from 'react';

import Visa from '../assets/images/card_visa.png';
import Discover from '../assets/images/card_discover.png';
import masterCard from '../assets/images/card_master.png';
import amex from '../assets/images/card_amex.png';
import { PAYMENT_CARD_TYPES } from '../constants/app';
import MESSAGES from '../constants/messages';

// eslint-disable-next-line react/function-component-definition
export default function CardImage({ cardType, imgComponent }) {
  const getImage = ({ src, alt }) => React.cloneElement(imgComponent, { src, alt });

  switch (cardType) {
    case PAYMENT_CARD_TYPES.VISA:
      return getImage({ src: Visa, alt: MESSAGES.VISA_LOGO });

    case PAYMENT_CARD_TYPES.DISCOVER:
      return getImage({ src: Discover, alt: MESSAGES.DISCOVER_LOGO });

    case PAYMENT_CARD_TYPES.MASTERCARD:
      return getImage({ src: masterCard, alt: MESSAGES.MASTER_LOGO });

    case PAYMENT_CARD_TYPES.AMERICAN_EXPRESS:
      return getImage({ src: amex, alt: MESSAGES.AMERICAN_LOGO });

    default:
      return null;
  }
}
