import axios from 'axios';
import * as constant from '../constant';

// eslint-disable-next-line import/prefer-default-export
export const httpClient = axios.create({
  baseURL: constant.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
