import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';

import { CountryDropdown } from 'react-country-region-selector';
import myClass from './addCard.module.css';
import MESSAGES from '../../constants/messages';
import { PAYMENT_CARD_TYPES } from '../../constants/app';
import paymentCardSchema from '../../validations/paymentCard';
import PaymentCardsRow from '../paymentCardsRow';
import { addNewPaymentMethod, updatePaymentMethod } from '../../services/paymentDetail';
import CreditCardField from '../creditCardField';
import { AppContext } from '../../context/app';
import CvvField from '../cvvField';

// eslint-disable-next-line react/function-component-definition
export default function AddCardModal({ card, setIsLoading }) {
  const [isEdit, setIsEdit] = useState(false);
  const {
    register, setValue, handleSubmit, control, reset, formState: { errors },
  } = useForm({
    resolver: yupResolver(paymentCardSchema),
  });
  const [selectedCardType, setSelectedCardType] = useState('');
  const { showMessage, messageText } = useContext(AppContext);
  const [, setShowMsg] = showMessage;
  const [, setMessage] = messageText;

  useEffect(() => {
    const setFormValues = (creditCard) => {
      let expiration = null;
      if (creditCard.expirationMonth < 10) {
        expiration = `0${creditCard.expirationMonth}/${creditCard.expirationYear}`;
      } else {
        expiration = `${creditCard.expirationMonth}/${creditCard.expirationYear}`;
      }
      const formValues = {
        paymentMethodId: creditCard.id,
        creditCardNumber: creditCard.cardNumber,
        cardHolderName: creditCard.cardHolderInfo.cardHolderName,
        cardType: creditCard.cardType,
        address: creditCard.cardHolderInfo.addressLine1,
        city: creditCard.cardHolderInfo.city,
        state: creditCard.cardHolderInfo.state,
        postalCode: creditCard.cardHolderInfo.zipCode,
        country: creditCard.cardHolderInfo.country,
        phone: creditCard.cardHolderInfo.phone,
        isDisable: true,
        expiration,
      };
      Object.keys(formValues).forEach((key) => setValue(key, formValues[key]));
    };

    reset();
    if (card && Object.prototype.hasOwnProperty.call(card, 'id')) {
      setIsEdit(true);
      setFormValues(card);
      setSelectedCardType(Object.keys(PAYMENT_CARD_TYPES).filter(
        (key) => PAYMENT_CARD_TYPES[key].toLowerCase() === card.cardType.toLowerCase(),
      )[0]);
    } else {
      setIsEdit(false);
      setSelectedCardType('');
    }
  }, [card, reset, setValue]);

  const handleResponse = (response) => {
    if (response.data.success) {
      window.location.reload();
    } else {
      const errorMsg = response.data.reasons.map((i) => (<p key={i.value}>{i.message.replace(/[<]br[^>]*[>]/gi, ' ')}</p>));
      setShowMsg(true);
      setMessage(errorMsg);
      setIsLoading(false);
    }
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);

    const cardExpiry = formData.expiration.split('/', 2);
    const cardHolderName = formData.cardHolderName || `${formData.fname} ${formData.lname}`;
    const payload = {
      ...formData,
      cardHolderName,
      accId: localStorage.getItem('accountId'),
      creditCardType: PAYMENT_CARD_TYPES[selectedCardType.toUpperCase()],
      expirationMonth: cardExpiry[0],
      expirationYear: cardExpiry[1],
      newUser: false,
    };
    delete payload.expiration;

    if (formData.paymentMethodId) {
      const response = await updatePaymentMethod(payload);
      handleResponse(response);
    } else {
      const response = await addNewPaymentMethod(payload);
      handleResponse(response);
    }
  };

  return (
    <div>
      <div className="modal show active" id="addCardModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <form>
              <div>
                <button type="button" className="position-relative has-zindex close mr-3 mt-1" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <div className="col-8 pl-0 pr-0" style={{ display: 'flex' }}>
                  <div className="mb-2">
                    <PaymentCardsRow
                      cardType={selectedCardType}
                      imgComponent={(
                        <img
                          className="col-2 pl-0"
                          src=""
                          alt=""
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-6">
                    <div className="form-group ">
                      <label htmlFor="creditCardNumber" className={myClass.label}>
                        {MESSAGES.CARD_NO}
                      </label>
                      <CreditCardField
                        register={register}
                        setSelectedCardType={setSelectedCardType}
                        error={errors.creditCardNumber?.message}
                        readOnly={isEdit}
                      />
                    </div>
                  </div>

                  <div className="col-3">
                    <label htmlFor="expiration" className={myClass.label}>
                      {MESSAGES.EXPIRATION}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="expiration"
                      maxLength="7"
                      {...register('expiration')}
                    />
                    {errors.expiration
                    && (
                    <p style={{ color: 'red', fontSize: 'small' }}>
                      {errors.expiration.message}
                    </p>
                    )}
                  </div>

                  <div className="col-3">
                    <label htmlFor="cvv" className={myClass.label}>
                      {MESSAGES.CVV}
                    </label>
                    <CvvField
                      register={register}
                      selectedCardType={selectedCardType}
                      error={errors?.cvv?.message}
                    />
                  </div>
                </div>

                <div
                  className="form-group pt-2"
                  style={{ borderBottom: '1px solid #ced4da' }}
                />

                <h5 className="card-title">{MESSAGES.BILLING_INFORMATION}</h5>
                {isEdit && (
                <div className="form-row">
                  <div className="col-12">
                    <div className="form-group pt-3 ">
                      <label htmlFor="cardHolderName" className={myClass.label}>
                        {MESSAGES.NAME}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="cardHolderName"
                        {...register('cardHolderName')}
                        placeholder={errors.cardHolderName?.message}
                      />
                    </div>
                  </div>
                </div>
                )}
                {!isEdit && (
                <div className="form-row">
                  <div className="col">
                    <label htmlFor="firstName" className={myClass.label}>
                      {MESSAGES.FIRST_NAME}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="fname"
                      {...register('fname')}
                      placeholder={errors.fname?.message}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="lastName" className={myClass.label}>
                      {MESSAGES.LAST_NAME}
                    </label>
                    <input
                      type="text"
                      name="lname"
                      className="form-control"
                      {...register('lname')}
                      placeholder={errors.lname?.message}
                    />
                  </div>
                </div>
                )}
                <div className="form-row">
                  <div className="col-6">
                    <div className="form-group pt-3">
                      <label htmlFor="phone" className={myClass.label}>
                        {MESSAGES.PHONE_NUMBER}
                      </label>
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        {...register('phone')}
                      />
                    </div>
                    <div className="col mb-n4 ml-n2">
                      {errors.phone?.message && (
                      <p style={{ color: 'red', fontSize: 'small' }}>
                        {errors.phone.message}
                      </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12">
                    <div className="form-group pt-3 ">
                      <label htmlFor="address" className={myClass.label}>
                        {MESSAGES.ADDRESS}
                      </label>
                      <input
                        type="text"
                        name="address"
                        className="form-control"
                        {...register('address')}
                        placeholder={errors.address?.message}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-6">
                    <div className="form-group ">
                      <label htmlFor="city" className={myClass.label}>
                        {MESSAGES.CITY}
                      </label>
                      <input
                        type="text"
                        name="city"
                        className="form-control"
                        {...register('city')}
                        placeholder={errors.city?.message}
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group ">
                      <label htmlFor="state" className={myClass.label}>
                        {MESSAGES.STATE}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="state"
                        {...register('state')}
                        placeholder={errors.state?.message}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col">
                    <label htmlFor="country" className={myClass.label}>
                      {MESSAGES.COUNTRY}
                    </label>
                    <Controller
                      name="country"
                      control={control}
                      render={({
                        field: {
                          onChange, onBlur, value,
                        },
                      }) => (
                        <CountryDropdown
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className={myClass.select}
                        />
                      )}
                    />
                    {errors?.country && (
                    <p style={{ color: 'red', fontSize: 'small' }}>
                      {errors?.country?.message}
                    </p>
                    )}
                  </div>
                  <div className="col">
                    <label htmlFor="postalCode" className={myClass.label}>
                      {MESSAGES.ZIPCODE}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="postalCode"
                      {...register('postalCode')}
                      placeholder={errors.postalCode?.message}
                    />
                  </div>
                </div>
              </div>

              <div className="add-card-modal-btn">
                <button onClick={handleSubmit(onSubmit)} className={myClass.submitButton}>
                  {MESSAGES.CONTINUE}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

AddCardModal.propTypes = {
  card: PropTypes.arrayOf(PropTypes.object),
  setIsLoading: PropTypes.func,
};

AddCardModal.defaultProps = {
  card: {},
  setIsLoading: () => {},
};
