import React, { Component } from "react";
import { client } from "../../actions/index";
import * as constant from "../../constant";

// import FaPlus from "react-icons/lib/fa/plus";

const HEADER_STYLES = {
  textAlign: "center",
  marginBottom: 20,
};

export default class OrganizationSwitcherContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      selected: -1,
      addingOrganization: false,
      filteredOrganizations: [],
      organizations: [],
    };
  }

  componentDidMount() {
    const bulkOrderPermission = localStorage.getItem("bulkOrder");
    const userRole = localStorage.getItem("role");

    if (!bulkOrderPermission || userRole !== "ADMIN") {
      this.props.history.push({
        pathname: `/nec/order`,
      });
    }

    client
      .get(constant.NEC_URL + "/api/auth/organization")
      .then((res) => this.setState({ organizations: res.data.Organizations }));
  }

  handleSearch = (e) => {
    this.setState({ search: e.target.value });
    let filteredOrganizations = this.state.organizations.filter((org) =>
      org.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ filteredOrganizations });
  };

  onSelect = (org) => {
    localStorage.setItem("companyName", org.name);
    localStorage.setItem("organizationId", org.id);
    org.accountId
      ? localStorage.setItem("accountId", org.accountId)
      : alert(
          "Organization does not have Zuora account Id, Please choose different organization."
        );
    org.accountId &&
      this.props.history.push({
        pathname: `/nec/bulk-order`,
      });
  };

  render() {
    const { search } = this.state;

    const OrganizationList = this.state.search
      ? this.state.filteredOrganizations
      : this.state.organizations;

    return (
      <div className="org-selection">
        <div className="Card">
          <div className="card-content">
            <div className="org-card-header">
              <h2 style={HEADER_STYLES}>Select an Organization</h2>
              <input
                type="text"
                id="filter"
                className="form-control"
                placeholder="Search..."
                maxlength="100"
                value={search}
                onChange={(e) => {
                  this.handleSearch(e);
                }}
              />
            </div>
            <div className="org-card-body">
              {OrganizationList.map((org, idx) => (
                <div key={org.id}>
                  <div className="org-row" onClick={() => this.onSelect(org)}>
                    {org.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
