import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import master from '../../../assets/images/card-master.jpg';
import visa from '../../../assets/images/card-visa.jpg';
import discover from '../../../assets/images/card-discover.jpg';
import amex from '../../../assets/images/card-amex.jpg';
import myClass from '../../../components/confirmation/Confirmation.module.css';
import OrderSummary from '../../../components/orderSummary';
import {
  createExistingOrder, createOrder, jobTaxStatus, previewOrdersTax, previewOrdersTaxAsync,
} from '../../../services/confirmation';
import MESSAGES from '../../../constants/messages';
import { APP_ROUTES } from '../../../constants/routes';
import { AppContext } from '../../../context/app';

// eslint-disable-next-line react/function-component-definition
export default function OrderConfirmation({
  existingCustomer,
  backToBilling,
  backToRatePlans,
}) {
  const history = useHistory();
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const { billing, confirmation } = history.location.state;
  const { loading, showMessage, messageText } = useContext(AppContext);
  const [, setIsLoading] = loading;
  const [, setShowMsg] = showMessage;
  const [, setMessage] = messageText;
  const [getSummaryRetry, setGetSummaryRetry] = useState(1);

  const calculateTax = async () => {
    const {
      productRateplanId,
      productRatePlanChargeId,
      quantity,
      city,
      state,
      country,
      postalCode,
    } = confirmation;
    setIsLoading(true);
    const params = {
      productRateplanId,
      productRatePlanChargeId,
      quantity,
      city,
      state,
      country,
      postalCode,
    };
    try {
      const response = await previewOrdersTaxAsync({
        params,
      });
      if (response.data.jobId) {
        asyncApiForOrderTax(response.data.jobId, params);
      } else {
        showError();
      }
    } catch (e) {
      showError();
    }
  };

  const showError = () => {
    setIsLoading(false);
    setShowMsg(true);
    setMessage(MESSAGES.CONTACT_HELP_IF_PROBLEM_ARRIVE);
  };

  const asyncApiForOrderTax = async (jobId, params) => {
    try {
      const response = await jobTaxStatus(params, jobId);
      const { status } = response.data;
      if (status === 'Completed') {
        if (response.data.result) {
          setTax(response.data.result.taxAmount);
          setTotal(response.data.result.total);
          confirmation.shiffingFee = response.data.result.shiffingFee;
          history.push({ state: { ...history.location.state, confirmation } });
          setIsLoading(false);
        }
      } else if (status === 'Processing') {
        setTimeout(() => {
          asyncApiForOrderTax(jobId, params);
          if (getSummaryRetry > 1) setGetSummaryRetry(1);
        }, 3000);
      } else if (status === 'Failed') {
        showError();
      } else if (status === null || status === undefined) {
        if (getSummaryRetry < 3) {
          setTimeout(() => {
            asyncApiForOrderTax(jobId, params);
          }, process.env.REACT_APP_POLLING_DELAY);
          setGetSummaryRetry(getSummaryRetry + 1);
        } else {
          showError();
        }
      }
    } catch (e) {
      showError();
    }
  };

  useEffect(() => {
    calculateTax();
  }, []);

  const getPayload = () => {
    let payload = confirmation;
    payload.total = total;
    if (!payload.soldTo) {
      payload = omit(payload, ['soldTo', 'soldtoAddress', 'soldtoCity', 'soldtoCountry', 'soldtoEmail', 'soldtoFname',
        'soldtoLname', 'soldtoPhone', 'soldtoPostalCode', 'soldtoState']);
    }
    return payload;
  };

  const handlePlaceOrder = async () => {
    return
    setIsLoading(true);

    const payload = getPayload();
    const response = existingCustomer
      ? await createExistingOrder(payload)
      : await createOrder(payload);
    setIsLoading(false);
    if (response.data.success) {
      history.push({
        pathname: APP_ROUTES.RECEIPT,
        state: {
          orderData: { ...response.data, quantity: confirmation.quantity },
        },
      });
    } else if (response.data.msg) {
      setShowMsg(true);
      setMessage(response.data.msg);
    }
  };

  const renderImage = (type) => {
    switch (type) {
      case 'Visa':
        return <img src={visa} alt="Visa logo" />;
      case 'Discover':
        return <img src={discover} alt="Discover logo" />;
      case 'MasterCard':
        return <img src={master} alt="Master logo" />;
      case 'AmericanExpress':
        return <img src={amex} alt="Amex logo" />;
      default:
    }
  };

  return (
    <div className="container-fluid w-1120 order-confirmation">
      <div className="row w-100">
        <div className="col-md-8 mt-4">
          <div className="card ml-3 order-left-col mb-4">
            <div className="card-body">
              <div className="row">
                {/* <div className="col-sm-12 mb-1">
                  <h5>{MESSAGES.ADDITIONAL_USER}</h5>
                  <p>
                    {MESSAGES.ADDITIONAL_USER_TEXT}
                  </p>
                  <input
                    type="email"
                    placeholder="Optional"
                    className="mt-n2"
                  />
                </div>

                <div className="col-sm-12">
                  <hr className="w-100" />
                </div> */}

                <div className="col-sm-12 selected-plan has-label-value">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title">{MESSAGES.PLAN_DETAILS}</h5>
                    </div>
                    <div className={myClass.edit}>
                      <a className="cicon-pen" onClick={backToRatePlans} />
                    </div>
                  </div>
                  <strong>{MESSAGES.STANDARD}</strong>
                  <p>
                    <label>{MESSAGES.PER_MONTH_YEAR_RATE}</label>
                    <span>$39.99</span>
                  </p>
                  <p>
                    <label>{MESSAGES.QTY}</label>
                    <span>{confirmation?.quantity}</span>
                  </p>
                </div>
                <div className="col-sm-12">
                  <hr className="w-100" />
                </div>
              </div>

              <div className="row selected-card mb-1">
                <div className="col">
                  <h5 className="card-title">{MESSAGES.PAYMENT_DETAILS}</h5>
                </div>
                <div className={myClass.edit}>
                  <a className="cicon-pen" onClick={() => backToBilling(billing)} />
                </div>
                <div className="col-sm-12">
                  {renderImage(confirmation.creditCardType)}
                </div>
                <div className="col-sm-12 has-label-value">
                  <p>
                    <label>{confirmation.creditCardType}</label>
                    <span>{confirmation.cardNo || confirmation.creditCardNumber}</span>
                  </p>
                  <p>
                    <label>
                      {MESSAGES.EXPITY_DATE}
                    </label>
                    <span>{`${confirmation.expirationMonth}/${confirmation.expirationYear}`}</span>
                  </p>
                </div>
              </div>

              <hr className="w-100" />

              <div className="row">
                <div className="col">
                  <h5 className="card-title">
                    {MESSAGES.BILLING_ADDRESS}
                  </h5>
                </div>
                <div className={myClass.edit}>
                  <a className="cicon-pen" onClick={() => backToBilling(billing)} />
                </div>
                <div className="col-sm-12">
                  <ul className="info-list">
                    <li>{confirmation.cardHolderName}</li>
                    <li>
                      {`${confirmation.address},`}
                    </li>
                    <li>
                      {`${confirmation.city}, ${confirmation.state}, ${confirmation.postalCode}`}
                    </li>
                    <li>
                      {confirmation.phone}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row mt-3 mb-2">
                <div className="col-sm-12">
                  <h5 className="card-title">{MESSAGES.SHIPPING_ADDRESS}</h5>
                </div>
                <div className="col-sm-12">
                  <ul className="info-list">
                    <li>
                      {confirmation.handyman}
                    </li>
                    <li>
                      {confirmation.shiffingAddress}
                    </li>
                    <li>
                      {`${confirmation.shiffingCity}, ${confirmation.shiffingState}, ${confirmation.shiffingPostalCode}`}
                    </li>
                    <li>
                      {confirmation.shiffingPhone}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card rounded-0 order-col pt-4">
            <OrderSummary
              confirmation
              quantity={confirmation.quantity}
              shippingFee={confirmation.shiffingFee}
              subTotal={confirmation.subTotal}
              total={total}
              handleContinue={handlePlaceOrder}
              tax={tax}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

OrderConfirmation.propTypes = {
  existingCustomer: PropTypes.bool,
  backToBilling: PropTypes.func,
  backToRatePlans: PropTypes.func,
};

OrderConfirmation.defaultProps = {
  existingCustomer: false,
  backToBilling: () => {},
  backToRatePlans: () => {},
};
