import { httpClient } from './index';
import * as constant from '../constant';
import { API_ROUTES } from '../constants/routes';

export default function HMAC(payload) {
  return new Promise((resolve, reject) => {
    httpClient.post(`${constant.BASE_URL}${API_ROUTES.HMAC}`, payload)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
