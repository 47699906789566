import React, { Component } from "react";
import Header from "./../header/Header";
import iconprinter from "../../assets/images/icon_printer.png";
import myClass from "./Receipt.module.css";

class BulkOrderReceipt extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }
  handleDone = () => {
    localStorage.clear();
    window.location.href = `https://guestviewguide.com/support/`;
  };

  logout = () => {
    localStorage.clear();
    window.location.href = `/`;
  };
  goToSwitchOrganizationPage = () => {
    // localStorage.clear();
    this.props.history.push({
      pathname: `/switch-organizations`,
    });
  };
  render() {
    const role = localStorage.getItem("role");
    return (
      <div className="fill-window">
        <Header />

        <div className="container w-100 col-md-6 pt-4">
          <div className="card d-table w-100 mt-5 pb-5">
            <div className="card-body">
              <h3>
                Thank you for your purchase{" "}
                {/*<img className="float-right" src={iconprinter} /> */}
              </h3>
            </div>

            <p className={myClass.p}>
              Please retain this receipt for your orders.
            </p>

            <div className="form-group pt-3" className={myClass.border}></div>

            <h3 className={myClass.h3}>Order Summary</h3>

            <div class="form-row">
              <h6 className="col md-6" className={myClass.h6}>
                Order Number:
              </h6>
              <span className={myClass.text}>
                <h6 className="col md-6 ml-5 pl-4">
                  {this.props.location.state.orderData.orderNumber}
                </h6>
              </span>
            </div>

            <div class="form-row">
              <h6 className="col md-6" className={myClass.h6}>
                Order Date:
              </h6>
              <span className={myClass.text}>
                <h6 className="col md-6 ml-5 pl-5 ">
                  {this.props.location.state.orderData.orderDate}
                </h6>
              </span>
            </div>
            {this.props.location.state.orderData &&
              this.props.location.state.orderData.paidAmount ? (
            <div class="form-row">
            <h6 className="col md-6" className={myClass.h6}>
              Order Total:
            </h6>
            <span className={myClass.text}>
              <h6 className="col md-6 ml-5 pl-5">
                USD ${this.props.location.state.orderData.paidAmount}
              </h6>
            </span>
          </div>
              ) : (
                ""
              )}
            <div class="form-row">
              <h6 className="col md-5" className={myClass.h6}>
                Number of items:
              </h6>
              <span className={myClass.text}>
                <h6 className="col md-7 ml-5 pl-1 ">
                  {this.props.location.state.orderData.quantity}
                </h6>
              </span>
            </div>
            {role !== "ADMIN" && (
              <div>
            <div className="d-flex justify-content-center mt-3 mb-4">
              <button
                onClick={this.handleDone}
                type="button"
                className="w-75 btn "
                style={{ backgroundColor: "#3E92CC", color: "white" }}
              >
                Learn how to get started with your GuestView Guide
              </button>
            </div>

            <div className="form-group" className={myClass.border}></div>
            <div className="d-flex justify-content-center mt-3 ">
              <a
                href="https://guestviewguide.com/"
                style={{ color: "#3E92CC", fontWeight: 600 }}
              >
                Back to GuestViewGuide.com
              </a>
            </div>
            </div>
            )}
            {role === "ADMIN" && (
              <div className="d-flex justify-content-center mt-4 ">
                <button
                  onClick={this.goToSwitchOrganizationPage}
                  type="button"
                  className="w-75 btn "
                  style={{ backgroundColor: "#3E92CC", color: "white" }}
                >
                  Go to Organization Selection Page
                </button>
              </div>
            )}
            <div className="d-flex justify-content-center mt-3 ">
              <button
                type="button"
                className="w-40 btn "
                style={{ backgroundColor: "#3E92CC", color: "white" }}
                onClick={this.logout}
              >
                Logout{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BulkOrderReceipt;