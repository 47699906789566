import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import MESSAGES from '../../constants/messages';
import { deletePaymentMethodById, getUserPaymentMethods, updateDefaultPaymentMethod } from '../../services/paymentDetail';
import CardImage from '../paymentCardImage';
import AddCardModal from '../addCard';
import { AppContext } from '../../context/app';

// eslint-disable-next-line react/function-component-definition
export default function SavedPaymentMethods({ setIsLoading, setFormValues }) {
  const [userCards, setUserCards] = useState([]);
  const [cardFocused, setCardFocused] = useState({});
  const { showMessage, messageText } = useContext(AppContext);
  const [, setShowMsg] = showMessage;
  const [, setMessage] = messageText;

  const getPageData = async () => {
    setIsLoading(true);
    const accountId = localStorage.getItem('accountId');
    const cardsInfo = await getUserPaymentMethods({ params: { accountId } });
    setUserCards(cardsInfo.data.creditCards);

    const defaultCard = cardsInfo.data.creditCards.find(
      (card) => card.defaultPaymentMethod === true,
    );
    setFormValues(defaultCard);
    setIsLoading(false);
  };

  useEffect(() => {
    getPageData();
  }, []);

  const handleChange = async (event) => {
    setIsLoading(true);
    const response = await updateDefaultPaymentMethod(event.target.value);
    if (response.data.success) {
      getPageData();
    }
  };

  const addNewPaymentMethod = () => {
    setCardFocused(null);
  };

  const updateCard = (card) => {
    setCardFocused(card);
  };

  const deleteCard = async (cardId) => {
    setIsLoading(true);

    const response = await deletePaymentMethodById(cardId);
    if (response.data.success) {
      setShowMsg(true);
      setMessage(MESSAGES.CARD_DELETED);
      getPageData();
    } else {
      setIsLoading(false);
      setShowMsg(true);
      setMessage(MESSAGES.CANNOT_DELETE_CARD);
    }
  };

  return (
    <div>
      <h5>{MESSAGES.SAVED_PAYMENT_METHODS}</h5>
      <div className="row w-100">
        <div className="mt-4 card-wrapper">
          {userCards.length > 0
              && userCards.map((card) => (
                <div className="card-container">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="radio"
                    id={`card-${card.id}`}
                    checked={card.defaultPaymentMethod}
                    value={card.id}
                    onClick={handleChange}
                  />
                  <label htmlFor={`card-${card.id}`}>
                    <figure>
                      <CardImage
                        cardType={card.cardType}
                        imgComponent={(
                          <img
                            src=""
                            alt=""
                            width="40px"
                            height="30px"
                            color="white"
                            style={{ position: 'absolute' }}
                          />
                        )}
                      />
                    </figure>
                    <p>{card.cardNumber}</p>
                    <i className="radio-icon" />
                  </label>

                  <a
                    onClick={() => updateCard(card)}
                    data-toggle="modal"
                    href="#addCardModal"
                    className="action-icon fas fa-pencil"
                  />
                  <span className="btn-separator">|</span>
                  <i
                    onClick={() => deleteCard(card.id)}
                    className="action-icon fal fa-trash-alt"
                  />
                </div>
              ))}
        </div>
      </div>

      <div className="add-card mb-4 pb-2">
        <a data-toggle="modal" href="#addCardModal" onClick={() => addNewPaymentMethod()}>
          <i className="fas fa-plus" />
          <span>{MESSAGES.ADD_NEW_CARD}</span>
        </a>
      </div>
      <AddCardModal
        card={cardFocused}
        setIsLoading={setIsLoading}
      />
    </div>
  );
}

SavedPaymentMethods.propTypes = {
  setIsLoading: PropTypes.func,
  setFormValues: PropTypes.func,

};

SavedPaymentMethods.defaultProps = {
  setIsLoading: () => {},
  setFormValues: () => {},
};
