export const APP_ROUTES = {
  SIGNUP: '/nec/signup',
  RESET_PASSWORD: '/nec/forgot',
  ORDER: '/nec/order',
  ADD_PLAN: '/nec/addPlan',
  ACCOUNT_AND_PLANS: '/nec/setting/accountbilling/accountplan',
  RECEIPTS: '/nec/setting/accountbilling/receipts',
  PAYMENT_DETAILS: '/nec/setting/accountbilling/paymentdetails',
  RECEIPT: '/nec/receipt',
  SWITCH_ORGANIZATION: '/switch-organizations',
  BILLING: '/nec/order/billing',
  CONFIRMATION: '/nec/order/confirmation',
  EDIT_PROFILE: '/nec/editProfile',
};

export const API_ROUTES = {
  LOGIN: '/api/auth/login',
  SIGNUP: '/api/auth/signup',
  USER_DETAILS: '/users/getUserDetails',
  SUBSCRIPTIONS: '/users/getSubscription',
  ACCOUNT: '/users/updateUserProfileInZuora',
  USER_INVOICES: '/users/getInvoices',
  USER_PAYMENTS: '/users/getPayments',
  USER_PAYMENT_METHOD: '/users/getPaymentMethod',
  DEFAULT_PAYMENT_METHOD: '/users/updateDefaultPaymentMethod',
  PAYMENT_METHOD_BY_ID: '/users/deletePaymentMethodById',
  NEW_PAYMENT_METHOD: '/users/hmacSignature',
  UPDATE_PAYMENT_METHOD: '/users/updatePaymentMethod',
  PREVIEW_ORDERS: '/checkout/previewOrders',
  PREVIEW_ORDERS_ASYNC: '/checkout/previewOrdersAsync',
  HMAC: '/users/hmacSignature',
  PREVIEW_ORDER_TAX: '/checkout/previewOrdersTax',
  PREVIEW_ORDER_TAX_ASYNC: '/checkout/previewOrdersTaxAsync',
  CREATE_ORDER: '/checkout/createOrders',
  CREATE_EXISTING_ORDER: '/checkout/createOrderExisting',
  FORGOT_PASSWORD: '/api/auth/forgot',
  ASYNC_JOB_STATUS: '/checkout/preview/status/',
  ASYNC_TAX_STATUS: '/checkout/tax/status/'
};
