import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/function-component-definition
export default function Table({ columns, rows }) {
  return (
    <table className="table col-sm-10 bg-white">
      <thead>
        <tr>
          {columns.map((column) => (
            <th scope="col">
              <small className="h6">{column}</small>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.id}>
            {Object.keys(row).map((key) => {
              if (key !== 'id') {
                return <td>{row[key]}</td>;
              }
              return false;
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

Table.defaultProps = {
  columns: [],
  rows: [],
};
