import * as yup from 'yup';
import MESSAGES from '../constants/messages';

const paymentCardSchema = yup.object().shape({
  paymentMethodId: yup.string(),
  creditCardNumber: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  expiration: yup.string().max(7, MESSAGES.INVALID_EXPIRATION_DATE)
    .min(7, MESSAGES.INVALID_EXPIRATION_DATE)
    .matches(
      /([0-9]{2})\/([0-9][0-9][0-9][0-9])/,
      MESSAGES.INVALID_EXPIRATION_DATE,
    )
    .required(MESSAGES.CANNOT_BE_EMPTY)
    .isPastExpiration(MESSAGES.EXPIRATION_MUST_BE_FUTURE),
  cvv: yup.string().min(3, MESSAGES.INVALID_CVV)
    .required(MESSAGES.CANNOT_BE_EMPTY)
    .matches(/^\d+$/, MESSAGES.INVALID_CVV),
  city: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  address: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  fname: yup.string().when('paymentMethodId', {
    is: undefined,
    then: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  }),
  lname: yup.string().when('paymentMethodId', {
    is: undefined,
    then: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  }),
  postalCode: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  state: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  phone: yup.string().min(10, MESSAGES.INVALID_PHONE_NUMBER)
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      MESSAGES.INVALID_PHONE_NUMBER,
    )
    .required(MESSAGES.CANNOT_BE_EMPTY),
  country: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  cardHolderName: yup.string().when('paymentMethodId', {
    is: (paymentMethodId) => paymentMethodId !== undefined,
    then: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  }),
});

export default paymentCardSchema;
