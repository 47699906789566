import * as yup from 'yup';
import MESSAGES from '../constants/messages';

function isPastExpiry(errorMessage) {
  return this.test(
    'test-credit-card-expiration-date',
    errorMessage,
    (value) => {
      if (!value) {
        return false;
      }

      const today = new Date();
      const monthToday = today.getMonth() + 1;
      const yearToday = today
        .getFullYear()
        .toString();

      const [expMonth, expYear] = value.split('/');

      if (Number(expYear) < Number(yearToday)) {
        return false;
      } if (
        Number(expMonth) < monthToday
        && Number(expYear) <= Number(yearToday)
      ) {
        return false;
      }

      return true;
    },
  );
}

yup.addMethod(yup.string, 'isPastExpiration', isPastExpiry);

const billingSchema = yup.object().shape({
  existingCustomer: yup.bool(),
  creditCardNumber: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  expiration: yup
    .string().when('existingCustomer', {
      is: false,
      then: yup.string().max(7, MESSAGES.CARD_FORMAT)
        .min(7, MESSAGES.CARD_FORMAT)
        .matches(
          /([0-9]{2})\/([0-9][0-9][0-9][0-9])/,
          MESSAGES.CARD_FORMAT,
        )
        .required(MESSAGES.CANNOT_BE_EMPTY)
        .isPastExpiration(MESSAGES.EXPIRATION_MUST_BE_FUTURE),
    }),
  cvv: yup.string().when('existingCustomer', {
    is: false,
    then: yup.string().min(3, MESSAGES.INVALID_CVV)
      .required(MESSAGES.CANNOT_BE_EMPTY)
      .matches(/^\d+$/, MESSAGES.INVALID_CVV),
  }),
  // Shipping info
  fname: yup.string().when('existingCustomer', {
    is: false,
    then: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  }),
  lname: yup.string().when('existingCustomer', {
    is: false,
    then: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  }),
  phone: yup
    .string()
    .when('existingCustomer', {
      is: false,
      then: yup.string().min(10, MESSAGES.INVALID_PHONE_NUMBER)
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          MESSAGES.INVALID_PHONE_NUMBER,
        )
        .required(MESSAGES.CANNOT_BE_EMPTY),
    }),
  email: yup.string().when('existingCustomer', {
    is: false,
    then: yup.string().email(MESSAGES.MUST_BE_VALID_EMAIL).required(MESSAGES.CANNOT_BE_EMPTY),
  }),
  address: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  city: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  state: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  country: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  postalCode: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
  // Sold to
  soldTo: yup.bool(),
  soldtoFname: yup.string().when('soldTo', { is: false, then: yup.string().required(MESSAGES.CANNOT_BE_EMPTY) }),
  soldtoLname: yup.string().when('soldTo', { is: false, then: yup.string().required(MESSAGES.CANNOT_BE_EMPTY) }),
  soldtoPhone: yup.string().when('soldTo', { is: false, then: yup.string().min(10, MESSAGES.INVALID_PHONE_NUMBER).matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Not a valid phone number').required(MESSAGES.CANNOT_BE_EMPTY) }),
  soldtoEmail: yup.string().when('soldTo', { is: false, then: yup.string().email(MESSAGES.MUST_BE_VALID_EMAIL).required(MESSAGES.CANNOT_BE_EMPTY) }),
  soldtoAddress: yup.string().when('soldTo', { is: false, then: yup.string().required(MESSAGES.CANNOT_BE_EMPTY) }),
  soldtoCity: yup.string().when('soldTo', { is: false, then: yup.string().required(MESSAGES.CANNOT_BE_EMPTY) }),
  soldtoState: yup.string().when('soldTo', { is: false, then: yup.string().required(MESSAGES.CANNOT_BE_EMPTY) }),
  soldtoCountry: yup.string().when('soldTo', { is: false, then: yup.string().required(MESSAGES.CANNOT_BE_EMPTY) }),
  soldtoPostalCode: yup.string().when('soldTo', { is: false, then: yup.string().required(MESSAGES.CANNOT_BE_EMPTY) }),
});

export default billingSchema;
