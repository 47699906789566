import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';

import myClass from './signup.module.css';
import MESSAGES from '../../constants/messages';
import { APP_ROUTES } from '../../constants/routes';
import TopLogo from '../../assets/images/logo@1xWhite.png';
import chaosAnimate from '../../assets/images/GVGFrameNew.gif';
import { signup } from '../../services/auth';
import signupSchema from '../../validations/signup';
import FormError from '../../components/formError';
import { ORDER_STEP } from '../../constants/app';
import { AppContext } from '../../context/app';
import { useEffect } from 'react';

// eslint-disable-next-line react/function-component-definition
export default function Signup() {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(signupSchema),
  });
  const history = useHistory();
  const { loading, showMessage, messageText } = useContext(AppContext);
  const [, setIsLoading] = loading;
  const [, setShowMsg] = showMessage;
  const [, setMessage] = messageText;

  useEffect(() => {
    try {
    if(PostAffTracker && process.env.REACT_APP_AFFILIATION_PRO ===  'enable'){
      PostAffTracker.setAccountId('default1');
        PostAffTracker.track();
      }
  } catch (err) {
    console.log('err:', err)
  }
}, [])

  const onSubmit = async (formData) => {
    return
    const {
      email, password, confirmPassword, companyName, noOfProperty, pms,
    } = formData;
    setIsLoading(true);

    const userDto = {
      email,
      password,
      companyName,
      pms,
      noOfProperty: noOfProperty || 0,
      confirm_password: confirmPassword,
    };

    const response = await signup(userDto);
    setIsLoading(false);
    if (response.data.success) {
      localStorage.removeItem('existingCustomer');
      history.push({
        pathname: APP_ROUTES.ORDER,
        state: { step: ORDER_STEP.YOUR_ORDER },
      });
    } else if (response.data.errors.length > 0) {
      const errorMsg = response.data.errors.map((i) => (<p key={i.value}>{i.msg}</p>));
      setShowMsg(true);
      setMessage(errorMsg);
    }
  };

  return (
    <div className="signup-screen fill-window">
      <div className="container">
        <div className="card card-login">
          <div className="row">
            <div className="col-sm-5">
              <nav className={myClass.navbar}>
                <ul className="navbar-nav pt-2 pb-1">
                  <li className="nav-item">
                    <div className="form-group">
                      <img className="login-logo form-group container col-12 my-4 py-2 d-flex" src={TopLogo} alt="" />
                      <img className="chaos-device form-group container col-12 d-flex" src={chaosAnimate} alt="" />
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-sm-7 pb-3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <h5 className="pt-4 mt-1 mb-1">{MESSAGES.CREATE_NEW_ACCOUNT}</h5>
                </div>
                <div className="form-group">
                  <label htmlFor="email">
                    {`${MESSAGES.EMAIL}*`}
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-0"
                    placeholder={errors.email?.message}
                    id="email"
                    name="email"
                    {...register('email')}
                  />
                  {errors.email?.message && <FormError error={errors.email?.message} />}
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="pwd">
                      {`${MESSAGES.PASSWORD}*`}
                    </label>
                    <input
                      type="password"
                      className="form-control rounded-0"
                      placeholder={errors.password?.message}
                      id="pwd"
                      name="password"
                      {...register('password')}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="pwd">
                      {`${MESSAGES.CONFIRM_PASSWORD}*`}
                    </label>
                    <input
                      type="password"
                      className="form-control rounded-0"
                      placeholder={errors.confirmPassword?.message}
                      id="cpwd"
                      name="confirmPassword"
                      {...register('confirmPassword')}
                    />
                  </div>
                  <small className="col-12 pass-direction">{MESSAGES.PASSWORD_INSTRUCTION}</small>
                </div>

                <div className="form-group">
                  <label htmlFor="comapanyName">
                    {`${MESSAGES.COMPANY_NAME}*`}
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-0"
                    id="comapanyName"
                    placeholder={errors.companyName?.message}
                    name="companyName"
                    {...register('companyName')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="numberOfCompany">
                    {MESSAGES.NO_OF_PROPERTIES}
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-0"
                    pattern="\d*"
                    id="numberOfProperty"
                    name="noOfProperty"
                    {...register('noOfProperty')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="pms">{MESSAGES.PMS_CURRENTLY_USING}</label>
                  <input
                    type="text"
                    className="form-control rounded-0"
                    id="pms"
                    name="pms"
                    {...register('pms')}
                  />
                </div>
                <div className="form-group">
                 {/* <button
                    type="submit"
                    className="btn w-100"
                  >
                    {MESSAGES.CREATE_ACCOUNT}
  </button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
