import React from 'react';
import { Route } from 'react-router-dom';

import Order from '../pages/order/index';
import Setting from '../pages/settings';
import Login from '../pages/login/index';
import Receipt from '../pages/receipt/index';
import Signup from '../pages/signup/index';
import ResetPassword from '../pages/resetPassword/index';
import ExistingCustomerBilling from './billing/ExistingCustomerBilling';
import ExistingConfiramtion from './confirmation/ExistingConfiramtion';
import AddPlan from './order/AddPlan';
import TermsAndConditions from './confirmation/TermsAndCondition';
import OrderForSuperAdmin from './order/BulkOrder';
import OrganizationSwitcherContainer from './SwitchOrganization/OrganizationSwitcherContainer';
import ConfirmationForSuperUser from './confirmation/ConfirmationForSuperUser';
import BulkOrderReceipt from './receipt/Receipt';

import { APP_ROUTES } from '../constants/routes';

// eslint-disable-next-line react/function-component-definition
export default function PageContent() {
  return (
    <div>
      <Route path="/" exact name="Signin" component={Login} />
      <Route path={APP_ROUTES.SIGNUP} name="signup" component={Signup} />
      <Route path={APP_ROUTES.ORDER} name="Order" component={Order} />
      {/* <Route path="/nec/billing" name="Billing" component={Billing} /> */}
      {/* <Route
            path="/nec/confirmation"
            name="Confirmation"
            component={Confirmation}
          /> */}
      <Route path="/nec/receipt" name="receipt" component={Receipt} />
      <Route path="/nec/setting" name="setting" component={Setting} />
      <Route path="/nec/forgot" name="forgot" component={ResetPassword} />
      <Route
        path="/nec/existingCustomerBilling"
        name="existingCustomerBilling"
        component={ExistingCustomerBilling}
      />
      <Route path={APP_ROUTES.ADD_PLAN} name="addPlan" component={AddPlan} />
      <Route
        path="/nec/existingConfiramtion"
        name="existingConfiramtion"
        component={ExistingConfiramtion}
      />
      <Route
        path="/nec/termsAndConditions"
        name="termsAndConditions"
        component={TermsAndConditions}
      />
      <Route
        path="/nec/editProfile"
        name="editProfile"
        render={() => <Login fromAdmin />}
      />
      <Route
        path="/nec/bulk-order"
        name="Order"
        component={OrderForSuperAdmin}
      />
      <Route
        path={APP_ROUTES.SWITCH_ORGANIZATION}
        name="SwitchOrganization"
        component={OrganizationSwitcherContainer}
      />
      <Route
        path="/nec/bulk-order-confirmation"
        name="BulkOrderConfirmation"
        component={ConfirmationForSuperUser}
      />
      <Route
        path="/nec/bulk-order-receipt"
        name="bulkOrderReceipt"
        component={BulkOrderReceipt}
      />
      {/* <Route
            exact
            path={APP_ROUTES.ACCOUNT_AND_PLANS}
            name="accountplan"
            render={() => <AccountPlan />}
          />
          <Route
            path={APP_ROUTES.RECEIPTS}
            name="receipts"
            render={() => <Receipts />}
          />
          <Route
            path={APP_ROUTES.PAYMENT_DETAILS}
            name="paymentdetails"
            render={() => <PaymentDetail />}
          />
          /> */}

    </div>
  );
}
