import * as yup from 'yup';
import MESSAGES from '../constants/messages';

const loginSchema = yup.object().shape({
  email: yup.string().email(MESSAGES.INVALID_EMAIL)
    .required(MESSAGES.CANNOT_BE_EMPTY),
  password: yup.string().required(MESSAGES.CANNOT_BE_EMPTY),
});

export default loginSchema;
