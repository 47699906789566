import React, { Component } from 'react';
import myClass from './loader.module.css';

class Loader extends Component {
  render() {
    return (
      <div className="spinner-wrapper">
        <div className="fill-window " style={{ width: '100%', height: '100%' }} />
        <div className={`rounded-spinner ${myClass.loader}`}>Loading...</div>
      </div>

    );
  }
}
export default Loader;
