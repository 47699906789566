import React, { useState } from 'react';
import PropTypes from 'prop-types';

import myClass from '../order/order.module.css';
import MESSAGES from '../../constants/messages';
import { RATE_PLANS_WITH_PROMO_CODE } from '../../constants/ratePlans';

// eslint-disable-next-line react/function-component-definition
export default function OrderSummary({
  quantity, shippingFee, subTotal, total, isPromoCodeApplied,
  promocode, blankError, selectedPlanId, tax, order,
  confirmation, billing, isValidPromo, setIsPromoCodeApplied, handleAddPromo, handleContinue,
  setPromocode, enableSubmit, ratePlans, handleRemovePromo,
}) {
  const [termsAndCondition, settermsAndCondition] = useState(false);

  const getPromoText = () => {
    const { ratePlan } = RATE_PLANS_WITH_PROMO_CODE.find(
      (plan) => plan.productRatePlanName === ratePlans[selectedPlanId - 1].ratePlan
      && plan.promoCode === promocode,
    ) || {};
    if (ratePlan) {
      return `${MESSAGES.USE_THIS_PROMO_CODE} ${ratePlan}`;
    }
    return null;
  };

  return (
    <div className="card-body">
      <h5 className="card-title">{MESSAGES.ORDER_SUMMARY}</h5>
      <div className="order-total-calculation">
        <p>
          <span className="label">
            {MESSAGES.SUBTOTAL}
            {` (${quantity} ${MESSAGES.QTY})`}
          </span>
          <span>
            {`$${subTotal}`}
          </span>
        </p>

        { confirmation && (
        <p>
          <span className="label">{MESSAGES.TAX}</span>
          <span>
            {`$${tax}`}
          </span>
        </p>
        )}

        <p>
          <span className="label">
            {MESSAGES.SHIPPING}
            {` (${quantity} ${MESSAGES.QTY})`}
          </span>
          <span className="float-right">
            {`$${shippingFee}`}
          </span>
        </p>

        <p className="order-total">
          <span className="label">
            {MESSAGES.TOTAL}
          </span>
          <span>
            {`$${total}`}
          </span>
        </p>
      </div>

      { order
        && (
        <div className="promo-box">
          <div className="promo-form">
            <span className="label">{MESSAGES.PROMO_CODE}</span>
            <input
              type="text"
              name="promocode"
              value={promocode}
              placeholder={blankError}
              onChange={(e) => setPromocode(e.target.value)}
              disabled={!selectedPlanId || isPromoCodeApplied}
              className="rounded form-control"
            />
            <button
              type="submit"
              onClick={handleAddPromo}
              disabled={!selectedPlanId || isPromoCodeApplied || !promocode}
              className={myClass.addPromoButton}
            >
              {MESSAGES.APPLY}
            </button>
          </div>

          { promocode && isPromoCodeApplied
                && (
                <div className="promo-code">
                  <p>
                    {isValidPromo && <span>{promocode}</span>}
                    {!isValidPromo && <span style={{ color: '#cd4057' }}>{MESSAGES.INVALID_PROMO_CODE}</span>}
                    <em
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setPromocode('');
                        setIsPromoCodeApplied(false);
                        handleRemovePromo();
                      }}
                    >
                      {MESSAGES.REMOVE}
                    </em>
                  </p>
                  <p className="promo-description">
                    {getPromoText()}
                  </p>
                </div>
                )}
        </div>
        )}

      { confirmation
        && (
        <div>
          <ul className="terms">
            <li>
              <i className="cicon-arrow" />
              {MESSAGES.CONFIRMATION_TEXT_1}
            </li>
            <li>
              <i className="cicon-arrow" />
              {MESSAGES.CONFIRMATION_TEXT_2}
            </li>
            <li>
              <i className="cicon-arrow" />
              {MESSAGES.CONFIRMATION_TEXT_3}
            </li>
          </ul>
          <div className="font-weight-normal row ml-1 pr-2 mt-2 pt-2 pb-4">
            <p>{MESSAGES.CONFIRMATION_TEXT_4}</p>
            <div className="terms-privacy">
              <input type="checkbox" onClick={(e) => settermsAndCondition(e.target.checked)} />
              <a href="https://guestviewguide.com/terms-and-conditions/" target="_blank" className="has-border" rel="noreferrer">
                {MESSAGES.TERMS_AND_CONDITION}
              </a>
              <a href="https://guestviewguide.com/privacy-policy/" target="_blank" rel="noreferrer">
                {MESSAGES.PRIVACY_POLICY}
              </a>
            </div>
          </div>

          <div className="mt-1">
            <div className="btn-continue w-100 d-flex">
        {/* <button className={myClass.continueButton} onClick={handleContinue} disabled={!termsAndCondition} type="button">{MESSAGES.PLACE_ORDER}</button> */}
            </div>
            <div className="contact-info">
              <h6>{MESSAGES.NEED_HELP}</h6>
              <span>{MESSAGES.CONTACT_US}</span>
              <ul>
                <li>
                  <i className="cicon-phone" />
                  {MESSAGES.CONTACT_NUMBER}
                </li>
                <li>
                  <i className="cicon-mail" />
                  <a href="mailto:guestview@sharpnec-displays.com">{MESSAGES.EMAIL_GVG}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        )}

        { (order || billing) && (
          <div className="btn-continue">
            <button
              type="button"
              className={myClass.continueButton}
              disabled
            >
              {MESSAGES.COUNTINUE}
              <i className="fal fa-long-arrow-right" />
            </button>
          </div>
        )}
    </div>
  );
}

OrderSummary.propTypes = {
  quantity: PropTypes.number,
  shippingFee: PropTypes.number,
  subTotal: PropTypes.number,
  total: PropTypes.number,
  isPromoCodeApplied: PropTypes.bool,
  promocode: PropTypes.string,
  blankError: PropTypes.string,
  selectedPlanId: PropTypes.number,
  tax: PropTypes.number,
  order: PropTypes.bool,
  confirmation: PropTypes.bool,
  billing: PropTypes.bool,
  setIsPromoCodeApplied: PropTypes.func,
  ratePlans: PropTypes.arrayOf(PropTypes.object),
  enableSubmit: PropTypes.bool,
  isValidPromo: PropTypes.bool,
  handleAddPromo: PropTypes.func,
  handleContinue: PropTypes.func,
  setPromocode: PropTypes.func,
  handleRemovePromo: PropTypes.func,
};

OrderSummary.defaultProps = {
  quantity: 0,
  shippingFee: 0.00,
  subTotal: 0.00,
  total: 0.00,
  isPromoCodeApplied: false,
  promocode: null,
  blankError: '',
  selectedPlanId: null,
  tax: 0.00,
  order: false,
  confirmation: false,
  billing: false,
  enableSubmit: false,
  ratePlans: [],
  isValidPromo: true,
  setIsPromoCodeApplied: () => {},
  handleAddPromo: () => {},
  handleContinue: () => {},
  setPromocode: () => {},
  handleRemovePromo: () => {},
};
