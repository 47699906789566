import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import MESSAGES from '../../constants/messages';
import { deletePaymentMethodById, getUserPaymentMethods, updateDefaultPaymentMethod } from '../../services/paymentDetail';
import Plus from '../../assets/images/plus-button.png';
import Edit from '../../assets/images/edit.png';
import Delete from '../../assets/images/delete-button.png';
import PaymentCardModal from '../paymentCardModal';
import CardImage from '../paymentCardImage';
import Error from '../error';
import { AppContext } from '../../context/app';

// eslint-disable-next-line react/function-component-definition
export default function CardDetail({
  setIsLoading,
}) {
  const [userCards, setUserCards] = useState([]);
  const [cardFocused, setCardFocused] = useState({});
  const { showMessage, messageText } = useContext(AppContext);
  const [, setShowMsg] = showMessage;
  const [, setMessage] = messageText;

  useEffect(() => {
    async function getPageData() {
      setIsLoading(true);
      const accountId = localStorage.getItem('accountId');
      const cardsInfo = await getUserPaymentMethods({ params: { accountId } });
      setUserCards(cardsInfo.data.creditCards);
      setIsLoading(false);
    }
    getPageData();
  }, []);

  const handleChange = async (event) => {
    setIsLoading(true);
    const response = await updateDefaultPaymentMethod(event.target.value);
    if (response.data.success) {
      window.location.reload();
    }
  };

  const addNewPaymentMethod = () => {
    setCardFocused(null);
  };

  const updateCard = (card) => {
    setCardFocused(card);
  };

  const deleteCard = async (cardId) => {
    setIsLoading(true);
    const response = await deletePaymentMethodById(cardId);
    if (response.data.success) {
      setShowMsg(true);
      setMessage(MESSAGES.CARD_DELETED);
      window.location.reload();
    } else {
      setIsLoading(false);
      setShowMsg(true);
      setMessage(MESSAGES.CANNOT_DELETE_CARD);
    }
  };

  return (
    <div>
      <h4 className="ml-3 mt-5">{MESSAGES.SAVED_PAYMENT_METHODS}</h4>
      <div className="row w-100">
        <div className="ml-3 mt-4 ">
          {userCards.length > 0
              && userCards.map((card) => (
                <div className="d-flex ml-3 mt-2">
                  <div
                    className="col"
                    style={{
                      borderStyle: 'solid',
                      borderColor: '#2483f4',
                      borderWidth: '1px',
                      borderRadius: '6px',
                      padding: '10px',
                      marginLeft: '1.5%',
                      marginBottom: '3%',
                    }}
                  >
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radio"
                        id="inlineRadio1"
                        checked={card.defaultPaymentMethod}
                        value={card.id}
                        onClick={handleChange}
                      />
                    </div>
                    <CardImage
                      cardType={card.cardType}
                      imgComponent={(
                        <img
                          src=""
                          alt=""
                          width="40px"
                          height="30px"
                          color="white"
                          style={{ position: 'absolute' }}
                        />
                        )}
                    />
                    <div className="d-flex ml-5 ">
                      <p className="float-right mr-3 ml-5 mt-n4">
                        {card.cardNumber}
                      </p>
                    </div>
                  </div>

                  <div className="mt-2 ml-4 ">
                    <img
                      className=""
                      onClick={() => updateCard(card)}
                      alt="edit logo"
                      color="white"
                      src={Edit}
                      data-toggle="modal"
                      href="#paymentCardModal"
                    />
                  </div>

                  <div className="mt-2 ml-4 mr-2">
                    <img
                      onClick={() => deleteCard(card.id)}
                      alt="delete logo"
                      color="white"
                      src={Delete}
                    />
                  </div>
                </div>
              ))}
        </div>
      </div>

      <div className="form-row w-50 mt-4">
        <a>
          <img
            className="ml-4 mb-5"
            onClick={() => addNewPaymentMethod()}
            alt="add"
            src={Plus}
            data-toggle="modal"
            href="#paymentCardModal"
          />
        </a>
        <h6 className="ml-2 mt-1">{MESSAGES.ADD_NEW_CARD}</h6>

        <PaymentCardModal card={cardFocused} setIsLoading={setIsLoading} />
      </div>
    </div>
  );
}

CardDetail.propTypes = {
  setIsLoading: PropTypes.func,
};

CardDetail.defaultProps = {
  setIsLoading: () => {},
};
