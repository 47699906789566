export default {
  YOUR_GVG_ACCOUNT: 'Login to your GuestView Guide Account',
  PLEASE_SIGN_IN: 'Sign in to your account to place order',
  INVALID_CREDENTIALS: 'Email or password is incorrect',
  EMAIL: 'Email Address',
  PASSWORD: 'Password',
  FORGOT_PASSWORD: 'Forgot Password?',
  SIGN_IN: 'Sign In',
  CREATE_NEW_ACCOUNT: 'Create New Account',
  LOGIN_TEXT: 'This account will be used for placing your order as well as managing content on your GuestView Guide.',
  SIGN_UP: 'Sign Up',
  CONFIRM_PASSWORD: 'Retype Password',
  PASSWORD_INSTRUCTION: 'At least 8 characters, 1 letter(a,b,c), 1 number, Include both Upper case and Lower case characters',
  COMPANY_NAME: 'Company Name',
  NO_OF_PROPERTIES: 'Number of properties listed by your company',
  PMS_CURRENTLY_USING: 'PMS you are currently using',
  CREATE_ACCOUNT: 'Create Account',
  CANNOT_BE_EMPTY: 'Cannot be empty',
  YOUR_ORDER: 'Your Order',
  BILLING: 'Billing',
  CONFIRMATION: 'Confirmation',
  ACCOUNT: 'Account',
  USERNAME: 'User Name',
  NAME: 'Name',
  ADDRESS: 'Address',
  PHONE: 'Phone',
  EDIT: 'Edit',
  PLAN: 'Plan',
  PLAN_TH: 'Plan',
  BILLING_CYCLE: 'Billing Cycle',
  AUTO_RENEW: 'Auto Renew',
  NEXT_BILLING: 'Next Billing',
  AMOUNT: 'Amount',
  ADD_PLAN: 'Add Plan',
  CONTACT_FOR_QUESTION: 'Questions? Contact us at guestview@necdisplay.com',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  CITY: 'City',
  STATE: 'State',
  COUNTRY: 'Country',
  ZIPCODE: 'Zipcode',
  SAVE_CHANGES: 'Save Changes',
  RECEIPTS: 'Receipts',
  INVOICE_NO: 'Invoice Number',
  ACCOUNT_NAME: 'Account Name',
  INVOICE_DATE: 'Invoice Date',
  DUE_DATE: 'Due Date',
  INVOICE_AMOUNT: 'Amount',
  BALANCE: 'Balance',
  STATUS: 'Status',
  PAYMENTS: 'Payments',
  REFERENCE_NO: 'Payment Number',
  PAYMENT_DATE: 'Payment Date',
  TYPE: 'Type',
  PAID_INVOICES: 'Paid Invoices',
  SAVED_PAYMENT_METHODS: 'Saved Payment Methods',
  VISA_LOGO: 'Visa logo',
  DISCOVER_LOGO: 'Discover logo',
  MASTER_LOGO: 'Master logo',
  AMERICAN_LOGO: 'amex logo',
  ADD_NEW_CARD: 'Add new card',
  CARD_NO: 'Card Number',
  EXPIRATION: 'Expiration',
  CVV: 'CVV',
  BILLING_INFORMATION: 'Billing Information',
  PHONE_NUMBER: 'Phone Number',
  CARD_DELETED: 'Card Deleted Successfully',
  CANNOT_DELETE_CARD: 'Cannot delete default payment method',
  CONTINUE: 'Continue',
  SOLDTO: 'soldTo',
  PAYMENT_DETAILS: 'Payment Details',
  CARD_NUMBER: 'Card Number',
  VALID_CARD_NUMBER: 'Please enter valid card number',
  CARD_EXPIRY: 'Card Expiry Date',
  CARD_FORMAT: 'Expected format: MM/YYYY',
  INVALID_CVV: 'Invalid CVV',
  BILLING_INFO: 'Billing Information',
  PHONE_NO: 'Phone Number',
  SHIPPING_INFO: 'Shipping Information',
  SAME_AS_BILLING: 'Same as Billing Information',
  SUBMIT: 'submit',
  SUBTOTAL: 'Subtotal',
  QTY: 'Qty',
  TAX: 'Tax',
  SHIPPING: 'Shipping',
  TOTAL: 'Total',
  PROMO_CODE: 'Promo Code',
  APPLY: 'Apply',
  REMOVE: 'Remove',
  CONFIRMATION_TEXT_1: '1 year initial term from the date of the initial transaction.',
  CONFIRMATION_TEXT_2: 'You may cancel your subscription by giving Sharp NEC a minimum of 5 working days notice prior to the auto-renewal date.',
  CONFIRMATION_TEXT_3: 'The auto-renewal date is the date that is one (1) year after the date of your initial transaction.',
  CONFIRMATION_TEXT_4: 'GuestView Guide is leased from Sharp NEC with a 1 year commitment, subject to user agreement period. By placing this order, you agree to our',
  TERMS_AND_CONDITION: 'Terms & Conditions',
  PRIVACY_POLICY: 'Privacy Policy',
  NEED_HELP: 'Need Help?',
  CONTACT_US: 'Contact us:',
  CONTACT_NUMBER: '1-800-632-4662',
  EMAIL_GVG: 'guestview@sharpnec-displays.com',
  COUNTINUE: 'Continue',
  ACCOUNT_AND_PLAN: 'Account & Plan',
  ALL_SET: 'You\'re all set!',
  THANKYOU_FOR_PURCHASE: ' Please retain this receipt for your orders.',
  ORDER_SUMMARY: 'Order Summary',
  ORDER_NO: 'Order Number',
  ORDER_DATE: 'Order Date',
  NO_OF_ITEMS: 'Number Of Items',
  ORDER_TOTAL: 'Order Total',
  GET_STARTED_WITH_GVG: 'Learn how to get started with your GuestView Guide',
  LOGIN_WITH_GVG: 'Log into the GuestView Guide Dashboard',
  BACK_TO_GVG_SITE: 'Back to GuestViewGuide.com',
  YOUR_GUESTVIEW_GUIDE: 'Your GuestView Guide',
  CHOOSE_YOUR_PLAN: 'Choose your plan',
  HD_TOUCHSCREEN: '21.5” HD Touchscreen',
  AUTO_SOFTWARE_UPDATES: 'Automatic Software Updates',
  WEBSITE_DASHBOARD: 'Website Dashboard',
  SUPPORT_FROM_NEC: 'Support from SHARP NEC',
  SELECT_YOUR_PLAN: 'Please select your plan',
  PLACE_ORDER: 'Place Order',
  CHANGE_PLAN: 'Change Plan',
  HD_TOUCHSCREEN_2: 'Stunning 21.5” high-definition touchscreen display',
  WEBSITE_DASHBOARD_2: 'Website dashboard to setup and manage your property information',
  AUTO_SOFTWARE_UPDATES_2: 'Automatic software updates with all future enhancements',
  TOTAL_MONTHLY_RATE: 'Total monthly rate',
  TOTAL_ANNUAL_RATE: 'Total annual rate',
  MONTHLY_TAX_DISCLAIMER: 'The total monthly rate plus taxes will be charged monthly to your selected payment option',
  ANNUAL_TAX_DISCLAIMER: 'The total annual rate plus taxes will be charged annually to your selected payment option',
  MM_YYYY: 'MM/YYYY',
  ADDITIONAL_USER: 'Additional User',
  ADDITIONAL_USER_TEXT: 'Are you the person who will be setting up the GuestView Guide? If no, please provide the email address of the person who will be setting up the GuestView Guide.',
  PLAN_DETAILS: 'Your Plan Details',
  EXPITY_DATE: 'Expiry Date',
  BILLING_ADDRESS: 'Billing Address',
  SHIPPING_ADDRESS: 'Shipping Address',
  STANDARD: 'Standard',
  PER_MONTH_YEAR_RATE: 'Per-Month / 1-year commitment',
  INVALID_PHONE_NUMBER: 'Invalid Phone Number',
  RESET_PASSWORD: 'Reset Password',
  INVALID_EMAIL: 'Please enter valid email address',
  USE_THIS_PROMO_CODE: 'Use this promo code and get',
  EXPIRATION_MUST_BE_FUTURE: 'Expiration date must be a future date',
  BACK_TO_LOGIN: 'Back to Sign In',
  CHECK_EMAIL_FOR_PASSWORD_RESET_LINK: 'Check your email for a reset password link.',
  INVALID_EXPIRATION_DATE: 'Not a valid expiration date. Example: MM/YYYY',
  MUST_BE_VALID_EMAIL: 'Must be a valid email',
  INVALID_PROMO_CODE: 'Invalid promo code',
  ACCOUNT_NOT_BILLING_OWNER: 'Your account is not the billing owner for your organization. Please log in with the billing owner account (normally the first account to create an organization) to access billing and account information.',
  INVALID_EMAIL_2: 'Invalid email address',
  ACCOUNT_NOT_EXIST_ON_ZUORA: 'Your account does not exist on zuora',
  QUANTITY_NOT_PRESENT: 'Please enter quantity',
  CONTACT_HELP_IF_PROBLEM_ARRIVE: 'If the problem persists please contact the help desk at help@guestviewguide.com',
  NOT_AVAILABLE : 'New device purchase is not available at the moment'
};
