import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { clone } from 'lodash';
import visa from '../assets/images/card_visa.png';
import discover from '../assets/images/card_discover.png';
import master from '../assets/images/card_master.png';
import amex from '../assets/images/card_amex.png';
import { PAYMENT_CARDS_INITIAL_OPACITY, PAYMENT_CARD_TYPES } from '../constants/app';
import MESSAGES from '../constants/messages';

// eslint-disable-next-line react/function-component-definition
export default function PaymentCardsRow({ cardType, imgComponent }) {
  const [cardsOpacity, setCardsOpacity] = useState(PAYMENT_CARDS_INITIAL_OPACITY);
  const visaOpacity = cardsOpacity[PAYMENT_CARD_TYPES.VISA];
  const discoverOpacity = cardsOpacity[PAYMENT_CARD_TYPES.DISCOVER];
  const masterOpacity = cardsOpacity[PAYMENT_CARD_TYPES.MASTERCARD];
  const amexOpacity = cardsOpacity[PAYMENT_CARD_TYPES.AMERICAN_EXPRESS];

  const getImage = ({ src, alt, style }) => React.cloneElement(imgComponent, { src, alt, style });

  useEffect(() => {
    const cardOpacity = clone(PAYMENT_CARDS_INITIAL_OPACITY);
    cardOpacity[PAYMENT_CARD_TYPES[cardType.toUpperCase()]] = '100%';
    setCardsOpacity(cardOpacity);
  }, [cardType]);

  return (
    <div>
      {getImage({ src: visa, alt: MESSAGES.VISA_LOGO, style: { opacity: visaOpacity } })}
      {getImage({ src: master, alt: MESSAGES.MASTER_LOGO, style: { opacity: masterOpacity } })}
      {getImage({
        src: discover,
        alt: MESSAGES.DISCOVER_LOGO,
        style: { opacity: discoverOpacity },
      })}
      {getImage({ src: amex, alt: MESSAGES.AMERICAN_LOGO, style: { opacity: amexOpacity } })}
    </div>
  );
}

PaymentCardsRow.propTypes = {
  cardType: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  imgComponent: PropTypes.object,
};

PaymentCardsRow.defaultProps = {
  cardType: '',
  imgComponent: <img alt="" src="" />,
};
