import React, { Component } from "react";
import myClass from "./order.module.css";

export default class BulkOrderError extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidUpdate() {
    if (this.props.error) {
      document.getElementById("ErrorModal").click();
    }
  }
  onClose = () => {
    document.getElementById("ErrorModal").click();
    this.props.isErrorFalse(false);
    this.props.closeModal.current.focus({ preventScroll: false });
  };
  render() {
    return (
      <div>
        <button
          type="button"
          id="ErrorModal"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
          style={{ display: "none" }}
        >
          error
        </button>

        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">{this.props.reasonOfFailure}</div>
              <div class="modal-footer">
                <button
                  type="button"
                  className={myClass.modalButton}
                  data-dismiss="modal"
                  onClick={this.onClose}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
