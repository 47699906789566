import { httpClient } from './index';
import * as constant from '../constant';
import { API_ROUTES } from '../constants/routes';

export function getUserDetails(payload) {
  return new Promise((resolve, reject) => {
    httpClient.post(`${constant.BASE_URL}${API_ROUTES.USER_DETAILS}`, payload)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getUserSubscriptions(payload) {
  return new Promise((resolve, reject) => {
    httpClient.get(`${constant.BASE_URL}${API_ROUTES.SUBSCRIPTIONS}`, payload)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateAccount(payload) {
  return new Promise((resolve, reject) => {
    httpClient.put(`${constant.BASE_URL}${API_ROUTES.ACCOUNT}`, payload)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
