import React, { Component } from "react";
import myClass from "./Billing.module.css";
import { client } from "../../actions/index";
import BillingError from "./BillingError";
import Loader from "../model/Loader";
class BillingForSuperUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCardNumber: "",
      creditCardType: "",
      expiration: "",
      cvv: "",
      fname: "",
      lname: "",
      address: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      errors: "",
      cardNumbererrors: false,
      visaopacity: "30%",
      masteropacity: "30%",
      discoveropacity: "30%",
      amexopacity: "30%",
      errorInExpiration: false,
      errorInCvv: false,
      paymentMethodId: "",
      status: false,
      errorInPhone: false,
      shiffingFee: "",
      subTotal: "",
      total: "",
      isSoldTo: true,
      soldtoFname: "",
      soldtoLname: "",
      soldtoAddress: "",
      soldtoCity: "",
      soldtoState: "",
      soldtoCountry: "",
      soldtoPostalCode: "",
      soldtoPhone: "",
      errorInEmail: false,
      email: "",
      errorInEmail: false,
      hasError: false,
      isLoading: false,
      opacity: "100%",
      isNotChecked: false,
      maxLength: "3",
      phone: "",
      regexp: /^[0-9\b]+$/,
    };
    this.onChange = this.onChange.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.props.onAddressChange()

    if (e.target.name == "soldtoAddress") {
      this.props.getAddressFromBill(e.target.value);
    }
  }

  handleContinue() {
    this.setState({
      hasError: false,
    });

    if (this.checkData()) {
      this.setState({
        isLoading: true,
        opacity: "40%",
        isNotChecked: true,
      });

      let payment;
      if (this.state.isSoldTo) {
        payment = {
          cardHolderName: this.state.fname + " " + this.state.lname,
          handyman: this.state.fname + " " + this.state.lname,
          fname: this.state.fname,
          lname: this.state.lname,
          address: this.state.address,
          city: this.state.city,
          state: this.state.state,
          phone: this.state.phone,
          country: this.state.country,
          postalCode: this.state.postalCode,
          userEmail: this.state.email,
          paymentMethodId: this.state.paymentMethodId,
          productRateplanId: this.props.preview.productRateplanId,
          productRatePlanChargeId: this.props.preview.productRatePlanChargeId,
          quantity: this.props.preview.quantity,
          shiffingFee: this.props.preview.shiffingFee,
          subTotal: this.props.preview.subTotal,
          total: this.props.preview.total,
          shiffingAddress: this.state.address,
          shiffingCity: this.state.city,
          shiffingState: this.state.state,
          shiffingCountry: this.state.country,
          shiffingPostalCode: this.state.postalCode,
          shiffingPhone: this.state.phone,
          loggedInEmail: localStorage.getItem("email"),
          companyName: localStorage.getItem("companyName"),
          noOfProperty: localStorage.getItem("noOfProperty"),
          pms: localStorage.getItem("pms"),
          isMonthly: this.props.preview.isMonthly,
          accountId: localStorage.getItem("accountId"),
        };
      } else {
        payment = {
          cardHolderName: this.state.fname + " " + this.state.lname,
          handyman: this.state.soldtoFname + " " + this.state.soldtoLname,
          fname: this.state.fname,
          lname: this.state.lname,
          address: this.state.address,
          city: this.state.city,
          state: this.state.state,
          country: this.state.country,
          postalCode: this.state.postalCode,
          phone: this.state.phone,
          userEmail: this.state.email,
          paymentMethodId: this.state.paymentMethodId,
          productRateplanId: this.props.preview.productRateplanId,
          productRatePlanChargeId: this.props.preview.productRatePlanChargeId,
          quantity: this.props.preview.quantity,
          shiffingFee: this.props.preview.shiffingFee,
          subTotal: this.props.preview.subTotal,
          total: this.props.preview.total,
          shiffingAddress: this.state.soldtoAddress,
          shiffingCity: this.state.soldtoCity,
          shiffingState: this.state.soldtoState,
          shiffingCountry: this.state.soldtoCountry,
          shiffingPostalCode: this.state.soldtoPostalCode,
          shiffingPhone: this.state.soldtoPhone,
          loggedInEmail: localStorage.getItem("email"),
          companyName: localStorage.getItem("companyName"),
          noOfProperty: localStorage.getItem("noOfProperty"),
          pms: localStorage.getItem("pms"),
          isMonthly: this.props.preview.isMonthly,
          accountId: localStorage.getItem("accountId"),
        };
      }

      this.props.history.push({
        pathname: `/nec/bulk-order-confirmation`,
        state: { paymentData: payment },
      });
    }
  }

  checkData = () => {
    if (this.state.isSoldTo) {
      return true;
    }

    if (this.state.soldtoCity === "") {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;
    }

    if (this.state.soldtoAddress === "") {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;
    }
    if (this.state.soldtoFname === "") {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;
    }
    if (this.state.soldtoLname === "") {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;
    }
    if (this.state.soldtoPostalCode === "") {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;
    }
    if (this.state.soldtoState === "") {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;
    }
    if (this.state.soldtoCountry === "") {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;
    }
    if (this.state.soldtoPhone === "" || this.state.soldtoPhone === null) {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;
    }
    if (this.state.errorInPhone) {
      return false;
    }
    if (this.state.isSoldTo === false) {
      if (
        (this.state.soldtoFname === "") |
        (this.state.soldtoFname === "") |
        (this.state.soldtoAddress === "") |
        (this.state.soldtoCity === "") |
        (this.state.soldtoPhone === "") |
        (this.state.soldtoState === "") |
        (this.state.soldtoCountry === "") |
        (this.state.soldtoPostalCode === "")
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  handleSoldTo = () => {
    this.setState((prevState) => {
      if (prevState.isSoldTo == true) {
        this.props.getAddressFromBill(this.state.address);
        return {
          isSoldTo: !prevState.isSoldTo,
          soldtoFname: this.state.fname,
          soldtoLname: this.state.lname,
          soldtoAddress: this.state.address,
          soldtoCity: this.state.city,
          soldtoState: this.state.state,
          soldtoCountry: this.state.country,
          soldtoPostalCode: this.state.postalCode,
          soldtoPhone: this.state.phone,
        };
      } else {
        this.props.getAddressFromBill(this.state.address);

        return {
          isSoldTo: !prevState.isSoldTo,
          soldtoFname: this.state.fname,
          soldtoLname: this.state.lname,
          soldtoAddress: this.state.address,
          soldtoCity: this.state.city,
          soldtoState: this.state.state,
          soldtoCountry: this.state.country,
          soldtoPostalCode: this.state.postalCode,
          soldtoPhone: this.state.phone,
          errors: "",
        };
      }
    });
  };
  isValidPhone = () => {
    if (this.state.phone.length < 10) {
      this.setState({ errorInPhone: true });
    } else {
      this.setState({ errorInPhone: false });
    }
  };
  isValidEmailAddress = () => {
    var userEmail = document.getElementById("email").value;
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (pattern.test(userEmail)) {
      this.setState({
        errorInEmail: false,
      });
    } else {
      this.setState({
        errorInEmail: true,
      });
    }
    return pattern.test(userEmail);
  };
  numberInputHandle = (e) => {
    let input = e.target.value;
    this.props.onAddressChange()
    if (input === "" || this.state.regexp.test(input)) {
      this.setState({ [e.target.name]: input });
    }
  };
  componentDidMount() {
    const accId = localStorage.getItem("accountId");
    this.props.isLoadingOnBilling(true);
    client
      .get("users/get-account-details?accountId=" + accId, {})
      .then((res) => {
        res.data &&
          res.data.billToContact &&
          this.setState({
            fname: res.data.billToContact.firstName,
            lname: res.data.billToContact.lastName,
            address: res.data.billToContact.address1,
            city: res.data.billToContact.city,
            state: res.data.billToContact.state,
            country: res.data.billToContact.country,
            postalCode: res.data.billToContact.zipCode,
            email: res.data.billToContact.workEmail,
            phone: res.data.billToContact.workPhone,
            // For Shipping Address
            soldtoFname: res.data.billToContact.firstName,
            soldtoLname: res.data.billToContact.lastName,
            soldtoAddress: res.data.billToContact.address1,
            soldtoCity: res.data.billToContact.city,
            soldtoState: res.data.billToContact.state,
            soldtoCountry: res.data.billToContact.country,
            soldtoPostalCode: res.data.billToContact.zipCode,
            soldtoPhone: res.data.billToContact.workPhone,
          });

        if (
          this.props.history.location.state &&
          this.props.history.location.state.shiffingAddress !==
            this.props.history.location.state.address
        ) {
          const editAddress = this.props.history.location.state;
          this.setState({
            isSoldTo: false,
            soldtoFname: editAddress.handyman.split(" ")[0],
            soldtoLname: editAddress.handyman.split(" ")[1],
            soldtoAddress: editAddress.shiffingAddress,
            soldtoCity: editAddress.shiffingCity,
            soldtoState: editAddress.shiffingState,
            soldtoCountry: editAddress.shiffingCountry,
            soldtoPostalCode: editAddress.shiffingPostalCode,
            soldtoPhone: editAddress.shiffingPhone,
          });
          document
            .getElementById("soldtoFname")
            .focus({ preventScroll: false });
        }
        if (res.data && res.data.billToContact) {
          this.props.getAddressFromBill(res.data.billToContact.address1);
          this.props.isLoadingOnBilling(false);
        }
      });
  }
  render() {
    let Spinner = null;
    if (this.state.isLoading === true) {
      Spinner = <Loader />;
    }
    const isShippingFieldDisabled = this.state.isSoldTo;
    return (
      <div className="card-body">
        {/* add break line */}
        <h5 className="card-title ">Shipping Information</h5>
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="defaultChecked"
            onClick={this.handleSoldTo}
            checked={this.state.isSoldTo}
          />

          <label className="custom-control-label" htmlFor="defaultChecked">
            Same as Billing Information
          </label>
        </div>

        <div className="pt-2">
          <div className="form-row">
            <div className="col">
              <label htmlFor="firstName" className={myClass.label}>
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                name="soldtoFname"
                id="soldtoFname"
                placeholder={this.state.errors}
                onChange={this.onChange}
                value={this.state.soldtoFname}
                disabled={isShippingFieldDisabled}
              ></input>
            </div>
            <div className="col">
              <label htmlFor="lastName" className={myClass.label}>
                Last Name
              </label>
              <input
                type="text"
                name="soldtoLname"
                className="form-control"
                onChange={this.onChange}
                placeholder={this.state.errors}
                value={this.state.soldtoLname}
                disabled={isShippingFieldDisabled}
              ></input>
            </div>
          </div>
          <div className="form-group pt-3 ">
            <label htmlFor="email" className={myClass.label}>
              Phone Number
            </label>
            <input
              type="text"
              name="soldtoPhone"
              id="soldtoPhone"
              maxLength="15"
              className="form-control"
              onChange={this.numberInputHandle}
              value={this.state.soldtoPhone}
              disabled={isShippingFieldDisabled}
              placeholder={this.state.errors}
            ></input>
          </div>
          <div className="form-group pt-3">
            <label htmlFor="address" className={myClass.label}>
              Address
            </label>
            <input
              type="text"
              name="soldtoAddress"
              className="form-control"
              onChange={this.onChange}
              placeholder={this.state.errors}
              value={this.state.soldtoAddress}
              disabled={isShippingFieldDisabled}
            ></input>
          </div>
          <div className="form-group ">
            <label htmlFor="city" className={myClass.label}>
              City
            </label>
            <input
              type="text"
              name="soldtoCity"
              className="form-control"
              onChange={this.onChange}
              placeholder={this.state.errors}
              value={this.state.soldtoCity}
              disabled={isShippingFieldDisabled}
            ></input>
          </div>
          <div className="form-group ">
            <label htmlFor="state" className={myClass.label}>
              State
            </label>
            <input
              type="text"
              className="form-control"
              name="soldtoState"
              onChange={this.onChange}
              placeholder={this.state.errors}
              value={this.state.soldtoState}
              disabled={isShippingFieldDisabled}
            ></input>
          </div>
          <div className="form-row">
            <div className="col">
              <label htmlFor="country" className={myClass.label}>
                Country
              </label>
              <select
                id="country"
                name="soldtoCountry"
                onChange={this.onChange}
                className={myClass.select}
                value={this.state.soldtoCountry}
                disabled={isShippingFieldDisabled}
              >
                <option></option>
                <option>Afghanistan</option>
                <option>Albania</option>
                <option>American Samoa</option>
                <option>Andorra</option>
                <option>Anguilla</option>
                <option>Antarctica</option>
                <option>Antiqua and Barbuda</option>
                <option>Argentina</option>
                <option>Armenia</option>
                <option>Aruba</option>
                <option>Australia</option>
                <option>Austria</option>
                <option>Azerbaijan</option>
                <option>Bahamas</option>
                <option>Bahrain</option>
                <option>Bangladesh</option>
                <option>Barbados</option>
                <option>Benin</option>
                <option>Belarus</option>
                <option>Belize</option>
                <option>Belgium</option>
                <option>Bermuda</option>
                <option>Bhutan</option>
                <option>Bolivia</option>
                <option>Bouvet Islands</option>
                <option>Brazil</option>
                <option>British Indian Ocean Territory</option>
                <option>British Virgin Islands</option>
                <option>Brunei</option>
                <option>Bulgaria</option>
                <option>Burkina Faso</option>
                <option>Burundi</option>
                <option>Canada</option>
                <option>Cambodia</option>
                <option>Cameroon</option>
                <option>Cape Verde</option>
                <option>Cayman Islands</option>
                <option>Central African Republic</option>
                <option>Chad</option>
                <option>Chile</option>
                <option>China</option>
                <option>Colombia</option>
                <option>Comoros</option>
                <option>Congo</option>
                <option>Costa Rica</option>
                <option>Cote D'Ivoire</option>
                <option>Croatia</option>
                <option>Cyprus</option>
                <option>Czech Republic</option>
                <option>Denmark</option>
                <option>Djibouti</option>
                <option>Dominica</option>
                <option>Dominican Republic</option>
                <option>Ecuador</option>
                <option>Egypt</option>
                <option>El Salvador</option>
                <option>Equatorial Guinea</option>
                <option>Eritrea</option>
                <option>Estonia Faroe Islands</option>
                <option>Ethiopia</option>
                <option>Falkland Islands</option>
                <option>Faroe Islands</option>
                <option>Federated States of Micronesia</option>
                <option>Fiji</option>
                <option>Finland</option>
                <option>France</option>
                <option>French Guiana</option>
                <option>French Polynesia</option>
                <option>Gabon</option>
                <option>Gambia</option>
                <option>Georgia</option>
                <option>Germany</option>
                <option>Ghana</option>
                <option>Gibraltar</option>
                <option>Greece</option>
                <option>Greenland</option>
                <option>Grenada</option>
                <option>Guadeloupe</option>
                <option>Guam</option>
                <option>Guatemala</option>
                <option>Guinea- Bissau</option>
                <option>Guyana</option>
                <option>Haiti</option>
                <option>Honduras</option>
                <option>Hong Kong</option>
                <option>Hungary</option>
                <option>Iceland</option>
                <option>India</option>
                <option>Indonesia</option>
                <option>Ireland</option>
                <option>Israel</option>
                <option>Italy</option>
                <option>Jamaica</option>
                <option>Japan</option>
                <option>Jordan</option>
                <option>Kazakhstan</option>
                <option>Kenya</option>
                <option>Kiribati</option>
                <option>Kuwait</option>
                <option>Kyrgyzstan</option>
                <option>Laos</option>
                <option>Latvia</option>
                <option>Lebanon</option>
                <option>Lesotho</option>
                <option>Liechtenstein</option>
                <option>Lithuania</option>
                <option>Luxembourg</option>
                <option>Macau</option>
                <option>Madagascar</option>
                <option>Malawi</option>
                <option>Malaysia</option>
                <option>Maldives</option>
                <option>Mali</option>
                <option>Malta</option>
                <option>Marshall Islands</option>
                <option>Martinique</option>
                <option>Mauritania</option>
                <option>Mayotte</option>
                <option>Metropolitan France</option>
                <option>Mexico</option>
                <option>Moldova</option>
                <option>Mongolia</option>
                <option>Morocco</option>
                <option>Mozambique</option>
                <option>Namibia</option>
                <option>Nauru</option>
                <option>Nepal</option>
                <option>Netherlands</option>
                <option>Netherlands Antilles</option>
                <option>New Caledonia</option>
                <option>New Zealand</option>
                <option>Nicaragua</option>
                <option>Niger</option>
                <option>Nigeria</option>
                <option>Northern Mariana Islands</option>
                <option>Norway</option>
                <option>Oman</option>
                <option>Pakistan</option>
                <option>Palau</option>
                <option>Panama</option>
                <option>Papua New Guinea</option>
                <option>Paraguay</option>
                <option>Peru</option>
                <option>Philippines</option>
                <option>Pitcairn</option>
                <option>Poland</option>
                <option>Portugal</option>
                <option>Puerto Rico</option>
                <option>Qatar</option>
                <option>Reunion</option>
                <option>Republic of Korea</option>
                <option>Romania</option>
                <option>Russia</option>
                <option>Sao Tome and Principe</option>
                <option>Saudi Arabia</option>
                <option>Senegal</option>
                <option>Seychelles</option>
                <option>Sierra Leone</option>
                <option>Singapore</option>
                <option>Slovakia</option>
                <option>Slovenia</option>
                <option>Solomon Islands</option>
                <option>Somalia</option>
                <option>South Africa</option>
                <option>Spain</option>
                <option>Sri Lanka</option>
                <option>St. Helena</option>
                <option>St. Kitts and Nevis</option>
                <option>St. Lucia</option>
                <option>St. Vincent and the Grenadines</option>
                <option>Sudan</option>
                <option>Suriname</option>
                <option>Svalbard and Jan Mayen Islands</option>
                <option>Swaziland</option>
                <option>Sweden</option>
                <option>Switzerland</option>
                <option>Syria</option>
                <option>Taiwan</option>
                <option>Tajikistan</option>
                <option>Tanzania</option>
                <option>Thailand</option>
                <option>Togo</option>
                <option>Tonga</option>
                <option>Trinidad and Tobago</option>
                <option>Turkey</option>
                <option>Turkmenistan</option>
                <option>Turks and Caicos Islands</option>
                <option>Tuvalu</option>
                <option>Uganda</option>
                <option>Ukraine</option>
                <option>United Arab Emirates</option>
                <option>United Kingdom</option>
                <option>United States</option>
                <option>Uruguay</option>
                <option>Uzbekistan</option>
                <option>Vanuatu</option>
                <option>Vatican City</option>
                <option>Vietnam</option>
                <option>Venezuela</option>
                <option>Western Sahara</option>
                <option>Yemen</option>
                <option>The former Yugoslav Republic of Macedonia</option>
                <option>Yugoslavia</option>
                <option>Zaire</option>
                <option>Zambia</option>
                <option>Zimbabwe</option>
              </select>
            </div>
            <div className="col">
              <label htmlFor="postalCode" className={myClass.label}>
                Zipcode
              </label>
              <input
                type="text"
                className="form-control"
                name="soldtoPostalCode"
                onChange={this.onChange}
                placeholder={this.state.errors}
                value={this.state.soldtoPostalCode}
                disabled={isShippingFieldDisabled}
              ></input>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BillingForSuperUser;
