import React, { useContext } from 'react';
import Modal from 'react-modal';
import { AppContext } from '../../context/app';

// eslint-disable-next-line react/function-component-definition
export default function Error() {
  const { showMessage, messageText } = useContext(AppContext);
  const [showMsg, setShowMsg] = showMessage;
  const [message] = messageText;

  const closeModal = () => {
    setShowMsg(false);
  };

  return (
    <Modal
      isOpen={showMsg}
      onRequestClose={closeModal}
      className="error-modal"
    >
      <div className="error-alert">
        <div className="modal-header">
          <h5>&nbsp;</h5>
          <i role="button" tabIndex={0} className="fal fa-times" onClick={closeModal} />
        </div>
        <div className="modal-body pb-4">
          <p className="text-center">{message}</p>
        </div>
        <div className="modal-footer">
          <button type="button" onClick={closeModal}>Ok</button>
        </div>
      </div>
    </Modal>
  );
}
