import { httpClient } from './index';
import * as constant from '../constant';
import { API_ROUTES } from '../constants/routes';

export function signin(payload) {
  return new Promise((resolve, reject) => {
    httpClient.post(`${constant.NEC_URL}${API_ROUTES.LOGIN}`, payload)
      .then(async (response) => {
        const {
          email, accountId, companyName, noOfProperty, pms,
        } = response.data;
        localStorage.setItem('email', email);
        localStorage.setItem('accountId', accountId);
        localStorage.setItem('companyName', companyName);
        localStorage.setItem('noOfProperty', noOfProperty);
        localStorage.setItem('pms', pms);
        localStorage.setItem('role', response.data.role);
        if (response.data.permissions) {
          localStorage.setItem(
            'bulkOrder',
            response.data.permissions.bulkOrder,
          );
        }
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function signup(payload) {
  return
  return new Promise((resolve) => {
    httpClient.post(`${constant.NEC_URL}${API_ROUTES.SIGNUP}`, payload)
      .then(async (response) => {
        const {
          companyName, noOfProperty, pms,
        } = response.data;
        localStorage.setItem('email', payload.email);
        localStorage.setItem('companyName', companyName);
        localStorage.setItem('noOfProperty', noOfProperty);
        localStorage.setItem('pms', pms);
        resolve(response);
      })
      .catch((err) => {
        resolve(err.response);
      });
  });
}

export function resetPassword(payload) {
  return new Promise((resolve, reject) => {
    httpClient.post(`${constant.NEC_URL}${API_ROUTES.FORGOT_PASSWORD}`, payload)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
