import React from "react";
import { withRouter } from "react-router-dom";

import MESSAGES from "../../constants/messages";
import { APP_ROUTES } from "../../constants/routes";

// eslint-disable-next-line func-names
const SettingsTabs = function ({ history }) {
  return (
    <div className="ml-3 w-50">
      <div className="row">
        <div
          role="menuitem"
          tabIndex={0}
          className="pl-2 pr-3 pt-1 pb-1 border-right-0 border  text-white"
          style={{ backgroundColor: "#3e92cc", cursor: "pointer" }}
          onClick={() => {
            // window.location.href = APP_ROUTES.RECEIPTS;
            history.push(APP_ROUTES.ACCOUNT_AND_PLANS);
          }}
        >
          {MESSAGES.ACCOUNT_AND_PLAN}
        </div>

        <div
          role="menuitem"
          tabIndex={0}
          className="pl-4 pr-4 pt-1 pb-1 border-left-0 border-right-0 border text-white pointer"
          style={{ backgroundColor: "#3e92cc", cursor: "pointer" }}
          onClick={() => {
            // window.location.href = APP_ROUTES.RECEIPTS;
            history.push(APP_ROUTES.RECEIPTS);
          }}
        >
          {MESSAGES.RECEIPTS}
        </div>

        <div
          role="menuitem"
          tabIndex={0}
          className="pl-2 pr-2 pt-1 pb-1  border-left-0 border-right border text-white pointer"
          style={{ backgroundColor: "#3e92cc", cursor: "pointer" }}
          onClick={() => {
            // window.location.href = APP_ROUTES.PAYMENT_DETAILS;
            history.push(APP_ROUTES.PAYMENT_DETAILS);
          }}
        >
          {MESSAGES.PAYMENT_DETAILS}
        </div>
      </div>
    </div>
  );
};

export default withRouter(SettingsTabs);
