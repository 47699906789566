import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import printJS from 'print-js';
import printer from '../../assets/images/printer.png';

import Header from '../../components/header/Header';
import MESSAGES from '../../constants/messages';
// eslint-disable-next-line react/function-component-definition
export default function Reciept({ location }) {
  const history = useHistory();
  const [isPrinting, setisPrinting] = useState(false);
  const handleLocation = () => {
    // localStorage.clear();
    window.location.href = 'https://guestviewguide.com/';
  };

  const handleLoginWithGVG = () => {
    // localStorage.clear();
    window.location.href = 'https://manage.guestviewguide.com/';
  };

  useEffect(() => {
    history.listen((loc, action) => {
      if (action === "POP") {
        history.replace({ pathname: "/" });
      }
    });
    try {
    if (PostAffTracker && process.env.REACT_APP_AFFILIATION_PRO === "enable") {
      const { orderNumber, paidAmount } = location.state.orderData;
      PostAffTracker.setAccountId("default1");
      var sale = PostAffTracker.createSale();
      sale.setTotalCost(paidAmount);
      sale.setOrderID(orderNumber);
      sale.setProductID("GuestView Guide Plan");
      PostAffTracker.register();
    }
  } catch (err) {
    console.log('err:', err)
  }
  });

  async function takePrint() {
    setisPrinting(true);
  }

  useEffect(() => {
    if (isPrinting) {
      const div = document.getElementById('receipt');
      html2canvas(div).then((canvas) => {
        const dataURL = canvas.toDataURL();
        printJS({
          printable: dataURL,
          type: 'image',
        });
        setisPrinting(false);
      });
    }
  }, [isPrinting]);

  const {
    orderDate, orderNumber, paidAmount, quantity,
  } = location.state
    ? location.state.orderData
    : {};
  return (
    <div className="fill-white" id="receipt">
      <div>
        <Header handleLocation={handleLocation} />
      </div>
      <div className="centered-card">
        <div
          className={`container-fluid thank-you-card ${
            isPrinting ? "printing-thankyou-card" : ""
          } `}
        >
          <div className="row">
            <div className={isPrinting ? "col-sm-12" : "col-sm-8"}>
              <div className="d-flex align-items-center allset ">
                <i className="cicon-checked" />
                <p> {MESSAGES.ALL_SET} </p>
              </div>
              <p>{MESSAGES.THANKYOU_FOR_PURCHASE}</p>
            </div>
            <div className="col-sm-12 ">
              <hr />
            </div>
            <div className="d-flex col-sm-12">
              <div
                className={`order-summary ${
                  isPrinting ? "col-sm-12" : "col-sm-8"
                }`}
              >
                <h5>{MESSAGES.ORDER_SUMMARY}</h5>
                <div className="order-summary-details">
                  <div className="row">
                    <div className="col-6">{`${MESSAGES.ORDER_NO}:`}</div>
                    <div className="col-6">{orderNumber}</div>
                    <div className="col-6">{`${MESSAGES.ORDER_DATE}:`}</div>
                    <div className="col-6">{orderDate}</div>
                    <div className="col-6">{`${MESSAGES.NO_OF_ITEMS}:`}</div>
                    <div className="col-6">{quantity}</div>
                    <div className="col-6">{`${MESSAGES.ORDER_TOTAL}:`}</div>
                    <div className="col-6">{`$${paidAmount || "0.00"}`}</div>
                  </div>
                </div>
              </div>
              {!isPrinting && (
                <div className="col-md-4 d-flex hideOnPrint">
                  <div className="vertical-line"> </div>
                  <div
                    className="d-flex flex-column align-items-center print-text "
                    onClick={takePrint}
                  >
                    <img src={printer} alt="printer img" className="printer" />
                    <p className="text-reciepts "> Print Receipt </p>
                  </div>
                </div>
              )}
            </div>
            {!isPrinting && (
              <div className="thank-you-footer hideOnPrint">
                <button
                  onClick={handleLoginWithGVG}
                  type="button"
                  className="btn"
                >
                  {MESSAGES.LOGIN_WITH_GVG}
                </button>
                <a
                  className="text-reciepts learn"
                  href="https://knowledgebase.guestviewguide.com/resources/getting-started"
                >
                  {MESSAGES.GET_STARTED_WITH_GVG}
                </a>
              </div>
            )}
          </div>
        </div>
        {!isPrinting && (
          <a
            className="text-reciepts hideOnPrint"
            href="https://guestviewguide.com/"
          >
            {/* <i className="fal fa-long-arrow-right" /> */}
            {MESSAGES.BACK_TO_GVG_SITE}
          </a>
        )}
      </div>
    </div>
  );
}

Reciept.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
};

Reciept.defaultProps = {
  location: { state: { orderData: {} } },
};
