import React, { Component } from "react";
import AccountPlanButtons from "../accountplanbutton/AccountPlanButtons";
//import myClass from "./PaymentDetails.module.css";
import Visa from "../../assets/images/card_visa.png";
import Discover from "../../assets/images/card_discover.png";
import masterCard from "../../assets/images/card_master.png";
import amex from "../../assets/images/card_amex.png";
import Edit from "../../assets/images/edit.png";
import Delete from "../../assets/images/delete-button.png";
import Plus from "../../assets/images/plus-button.png";
import { client } from "../../actions/index";
import PaymentModelPopup from "../model/PaymentModelPopup";
import { UserProvider } from "../userContext/UserContext";
import Loader from '../model/Loader';
import AddNewPayment from "../model/AddNewPayment";

class CardDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: "",
      defaultPaymentMethodCardId: "",
      cardInfo: [],
      indvCardInfo: {},
      togleState: false,
      payments:[],
      isLoading:false,
      opacity:'100%',
     
    };
  }

  renderImage = (type) => {
    switch (type) {
      case "Visa":
        return (
          <img
            src={Visa}
            alt="Visa logo"
            width="40px"
            height="30px"
            color="white"
            style={{ position: "absolute" }}
          />
        );
      case "Discover":
        return (
          <img
            src={Discover}
            alt="Visa logo"
            width="40px"
            height="30px"
            color="white"
            style={{ position: "absolute" }}
          />
        );
      case "MasterCard":
        return (
          <img
            src={masterCard}
            alt="Master logo"
            width="40px"
            height="30px"
            color="white"
            style={{ position: "absolute" }}
          />
        );
        case "AmericanExpress":
          return (
            <img
              src={amex}
              alt="amex logo"
              width="40px"
              height="30px"
              color="white"
              style={{ position: "absolute" }}
            />
          );
    }
  };
  handleChange = (event) => {
   
    this.setState({ defaultPaymentMethodCardId: event.target.value , isLoading:true,
      opacity:'30%'});
   
    client
      .post("users/updateDefaultPaymentMethod", event.target.value)
      .then((response) => {
       
        if (response.data.success) {
         
        window.location.reload();
        }
      });
  };
  getItemId = (indvData) => {
    // <AccountModelPopup/>
    this.setState(
      {
        indvCardInfo: indvData,
      },
      () => {
        this.setState({ togleState: true });
      }
    );
  };
  removePaymentMethodById = (id) => {
    this.setState({ isLoading:true, opacity:'30%'});
     
    client.post("users/deletePaymentMethodById", id).then((response) => {
      if (response.data.success) {
        alert("Card Deleted Successfully");
        window.location.reload();
      } else {
        this.setState({ isLoading:false, opacity:'100%'});
        alert("Can Not Delete Default Payment Method");
       
      }
    });
  };
  componentDidMount() {
    const accountId = localStorage.getItem('accountId');
    client.get('users/getPaymentMethod', {
        params: {
          accountId: accountId
       }
      })
      .then((response) => {
        // console.log("resopnse", response);
        this.setState({
          defaultPaymentMethodCardId: response.data.creditCards.find(
            (i) =>
             i.defaultPaymentMethod === true).id
       });
  
        this.setState({ cardInfo: response.data.creditCards });
      });
}
  render() {
    let Spinner = null;
    if (this.state.isLoading === true) {
      Spinner = <Loader />;
    }
    const elements = this.state.cardInfo;

    const items = this.state.cardInfo.map((i, index) => (
      <li key={index}>{i.id}</li>
    ));
    return (
      
      <div opacity={this.state.opacity}>
      
        <h5>Saved payment Methods</h5>

        <div className="row w-100">
          <UserProvider value={this.state.indvCardInfo}>
            <div className="mt-4 card-wrapper">
              {this.state.cardInfo.length > 0 &&
                this.state.cardInfo.map((i, index) => (

                  // <div className="d-flex ml-3 mt-2">
                  //   <div
                  //     className="col"
                  //     style={{
                  //       borderStyle: "solid",
                  //       borderColor: "#2483f4",
                  //       borderWidth: "1px",
                  //       borderRadius: "6px",
                  //       padding: "10px",
                  //       marginLeft: "1.5%",
                  //       marginBottom: "3%",
                  //     }}
                  //   >
                  //     <div className="form-check form-check-inline">
                       
                  //         <input
                  //           className="form-check-input"
                  //           type="radio"
                  //           name="radio"
                  //           id="inlineRadio1"
                  //           checked={i.defaultPaymentMethod}
                  //           value={i.id}
                  //           onClick={this.handleChange}
                  //         />
                       
                  //     </div>

                  //     {this.renderImage(this.state.cardInfo[index].cardType)}
                  //     <div className="d-flex ml-5 ">
                  //       <p className="float-right mr-3 ml-5 mt-n4">
                  //         {" "}
                  //         {this.state.cardInfo[index].cardNumber}
                  //       </p>
                  //     </div>
                  //   </div>

                  //   <div className="mt-2 ml-4 ">
                  //     <img
                  //       className=""
                  //       onClick={() => this.getItemId(i)}
                  //       alt="edit logo"
                  //       color="white"
                  //       src={Edit}
                  //       data-toggle="modal"
                  //       href="#myModal"
                  //     />
                      
                  //     <PaymentModelPopup />
                  //   </div>

                  //   <div className="mt-2 ml-4 mr-2">
                  //     <img
                  //       //className={myClass.Delete}
                  //       onClick={() => this.removePaymentMethodById(i.id)}
                  //       alt="delete logo"
                  //       color="white"
                  //       src={Delete}
                  //     />
                  //   </div>
                  // </div>

                  <div className="card-container">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="radio"
                      id="inlineRadio1"
                      checked={i.defaultPaymentMethod}
                      value={i.id}
                      onClick={this.handleChange}
                    />
                      <label for="inlineRadio1">
                        <figure>{this.renderImage(this.state.cardInfo[index].cardType)}</figure>
                        <p>{this.state.cardInfo[index].cardNumber}</p>
                        <i className="radio-icon"></i>
                      </label>
                      <a
                        onClick={() => this.getItemId(i)}
                        data-toggle="modal"
                        href="#myModal"
                        className="action-icon fas fa-pencil"
                      ></a>
                      <span className="btn-separator">|</span>
                      <i
                        onClick={() => this.removePaymentMethodById(i.id)}
                      className="action-icon fal fa-trash-alt"></i>
                  </div>

                ))}
            </div>
          </UserProvider>
        </div>

        <div className="add-card mb-4 pb-2">
          <a href="#myModalAdd">
            <i className="fas fa-plus"></i><span>Add New Card</span>
          </a>
        </div>

        {/* <div className="form-row w-50 mt-4">
          <a>
            <img
              className="mb-5"
              src={Plus}
              data-toggle="modal"
              href="#myModalAdd"
            />
            <AddNewPayment />
          </a>
          <h6 className="ml-2 mt-1">Add new card</h6>
        </div> */}
       
        
      </div>
    );
  }
}
export default CardDetails;