import React, { Component } from "react";
import SecurePayment from "../../assets/images/icon_securepayment.png";
import myClass from "./order.module.css";
import Header from "../header/Header";
import { getProductList } from "../../actions/order_action";
import { connect } from "react-redux";
import { client } from "../../actions/index";
import BulkOrderError from "./BulkOrderError";
import Loader from "../model/LoaderWithText";
import * as constant from "../../constant";
import BillingForSuperUser from "../billing/BillingForSuperUser";

class BulkOrder extends Component {
  constructor(props) {
    super(props);
    this.child1 = React.createRef();
    this.child2 = React.createRef();
    this.state = {
      blankError: "",
      quantity: "0",
      productName: "",
      productRatePlanName: "",
      isToggle: false,
      shiffingFee: "",
      subTotal: "",
      total: "",
      productRateplanId: "",
      productRatePlanChargeId: "",
      isCheckedMonthly: false,
      isCheckedAnnual: false,
      isLoading: false,
      rate: "",
      proratedAmount: "",
      isCheckedMonthlyOff: false,
      isCheckedAnnualOff: false,
      errorQty: "Minimum of 5 displays for this rate plan",
      isError: false,
      isMonthly: true,
      opacity: "100%",
      isNotChecked: true,
      isShippingAddressFilled: false,
      ratePlans: [],
      editAddress: {},
      enableCreate: false,
      getSummaryRetry: 1
    };
  }

  componentDidMount() {
    client.get(constant.NEC_URL + "/api/auth/rate-plan").then((res) => {
      const ratePlansUnique = res.data.ratePlans.filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => t.productRatePlanID === item.productRatePlanID)
      );
      this.setState({ ratePlans: ratePlansUnique });

      if (this.props.history.location.state) {
        const dataForEdit = this.props.history.location.state;
        const ratePlan = ratePlansUnique.find(
          (plan) => plan.productRatePlanID == dataForEdit.productRateplanId
        );
        this.setState({ ...dataForEdit, isToggle: true, ...ratePlan });
      }
    });
  }
  onChangeQTY = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      //For Order Summary
      isToggle: false,
      shiffingFee: "",
      subTotal: "",
      total: "",
      isCheckedMonthly: false,
      isCheckedAnnual: false,
      isLoading: false,
      rate: "",
      enableCreate: false
    });
  };

  validateQuantity= (e)=>{
    if(!(e.key.charCodeAt(0) >= 48 && e.key.charCodeAt(0) <= 57 ) && !(e.key === "ArrowLeft" ||  e.key === "ArrowRight" || e.key === "ArrowUp" ||  e.key === "ArrowDown" )  ){
      document.getElementById("quantity").value = this.state.quantity
      this.setState({
        [e.target.name]: this.state.quantity,
      })
    }
  }

  checkQuantity = () => {
    if (this.state.quantity < 1 || this.state.quantity > 250) {
      this.setState({
        isError: true,
        reasonOfFailure: "Please add quantity between 1 to 250.",
      });

      return;
    }
  };
  onChangeRatePlan = (e) => {
    const ratePlan = this.state.ratePlans.find(
      (plan) => plan.productRatePlanName == e.target.value
    );
    this.setState({
      [e.target.name]: e.target.value,
      isCheckedAnnual: false,
      isNotChecked: true,
      isError: false,
      ...ratePlan,
      productRateplanId: ratePlan.productRatePlanID,
      productRatePlanChargeId: ratePlan.productRatePlanChargeId,
      //For Order Summary
      isToggle: false,
      shiffingFee: "",
      subTotal: "",
      total: "",
      isCheckedMonthly: false,
      isCheckedAnnual: false,
      isLoading: false,
      rate: "",
      enableCreate: false
    });
  };

  onAddressChange =(e) =>{
    this.setState({
      isToggle: false,
      shiffingFee: "",
      subTotal: "",
      total: "",
      isCheckedMonthly: false,
      isCheckedAnnual: false,
      isLoading: false,
      rate: "",
      enableCreate: false
    });
  }

  getAddressFromBill = (address) => {
    //subtotal  and shipping should be reset when billing info changes
    const summaryData = this.state.isEdit ? {isEdit: false} : {
      isToggle: false,
      shiffingFee: "",
      subTotal: "",
      total: "",
    }

    address
      ? this.setState({ isShippingAddressFilled: true, ...summaryData })
      : this.setState({ isShippingAddressFilled: false });
  };

  isErrorFalse = () => {
    this.setState({ isError: false });
  };

  isLoadingOnBilling = (value) => {
    this.setState({
      isLoading: value,
      opacity: value ? "40%" : "100%",
    });
  };

  handleUpdateSummary = () => {
    if (this.state.quantity < 1 || this.state.quantity > 250) {
      this.setState({
        isError: true,
        reasonOfFailure: "Please add quantity between 1 to 250.",
      });

      return;
    } else if (!this.state.productRatePlanName) {
      this.setState({
        isError: true,
        reasonOfFailure: "Please select rate plan.",
      });
      return;
    }

    if(this.child1.current.checkData()) {
      this.setState(
        {
          isLoading: true,
          opacity: "40%",
          isError: false,
        },
        () =>
          client
            .get("checkout/previewOrdersAsync", {
              params: {
                ratePlan: this.state.productRatePlanName,
                quantity: this.state.quantity,
                promoCode: "",
              },
            })
            .then((response) => {
              this.asyncApiForOrderSummary(response.data.jobId);
            })
      );
    }
  };

  asyncApiForOrderSummary = (jobId) => {
    client
      .get("checkout/preview/status/" + jobId, {
        params: {
          ratePlan: this.state.productRatePlanName,
          quantity: this.state.quantity,
          promoCode: "",
        },
      })
      .then((response) => {
        const status = response.data.status
        if (status === "Completed") {
          response.data &&
            response.data.result &&
            this.setState({
              isToggle: true,
              shiffingFee: response.data.result.shiffingFee,
              subTotal: response.data.result.subTotal,
              total: response.data.result.total,
              productRateplanId: response.data.result.productRateplanId,
              productRatePlanChargeId:
                response.data.result.productRatePlanChargeId,
              isLoading: false,
              isNotChecked: false,
              opacity: "100%",
              proratedAmount: response.data.result.proratedAmount,
              rate: response.data.result.rate,
              enableCreate: true,
              getSummaryRetry: 1
            });
          if (
            response.data &&
            response.data.result &&
            response.data.result.productRateplanId == null
          ) {
            this.setState({
              isToggle: false,
              isCheckedMonthly: false,
              isMonthly: false,
              isNotChecked: true,
              isError: true,
              getSummaryRetry: 1,
              reasonOfFailure:
                response.data.result &&
                response.data.result.message &&
                response.data.result.message[0],
            });
          }
        } else if (status === "Processing") {
          setTimeout(() => {
            this.asyncApiForOrderSummary(jobId);
            if(this.state.getSummaryRetry > 1) this.setState({getSummaryRetry: 1})
         }, process.env.REACT_APP_POLLING_DELAY);
        } else if (status === "Failed") {
          response.data &&
            this.setState({
              isLoading: false,
              isNotChecked: false,
              opacity: "100%",
              isError: true,
              reasonOfFailure: "Something went wrong.",
              getSummaryRetry: 1,
            });
        } else if(status === null || status === undefined){
          if(this.state.getSummaryRetry < 3){
            setTimeout(() => {
              this.asyncApiForOrderSummary(jobId);
           }, process.env.REACT_APP_POLLING_DELAY);
           this.setState({getSummaryRetry: this.state.getSummaryRetry+1})
          } else {
            this.setState({
              isLoading: false,
              isNotChecked: false,
              opacity: "100%",
              isError: true,
              reasonOfFailure: "Something went wrong.",
              getSummaryRetry: 1,
            });
          }
        }
      });
  };

  componentWillUnmount() {
    clearInterval(this.state.timerId);
  }

  handleContinue = () => {
    this.child1.current.handleContinue();
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (
      e.target.name === "quantity" ||
      e.target.name === "productRatePlanName"
    ) {
      this.setState({
        isToggle: false,
        shiffingFee: "",
        subTotal: "",
        total: "",
        productRateplanId: "",
        productRatePlanChargeId: "",
        isCheckedMonthly: false,
        isCheckedAnnual: false,
        isLoading: false,
        rate: "",
      });
    }
  };

  render() {
    let Spinner = null;
    if (this.state.isLoading === true) {
      Spinner = <Loader />;
    }
    if (localStorage.getItem("email") === null) {
      this.props.history.push({
        pathname: `/`,
      });
    }

    let preview = {
      quantity: this.state.quantity,
      shiffingFee: this.state.shiffingFee,
      subTotal: this.state.subTotal,
      total: this.state.total,
      productRateplanId: this.state.productRateplanId,
      productRatePlanChargeId: this.state.productRatePlanChargeId,
      isMonthly: this.state.isMonthly,
    };
    return (
      <div className="fill-window">
        <Header image={SecurePayment} text="secure payment" />
        <div className="d-flex mt-3 w-100 ">
          <div className="col-3"></div>
          <div className=" w-100">
            <div style={{ display: "inline-block" }}>
              <span className={myClass.dot}></span>
            </div>

            <div
              style={{
                display: "inline-block",
                width: "30%",
                border: "solid 1px #ced4da",
                position: "relative",
                top: -4,
              }}
            ></div>

            <div style={{ display: "inline-block" }}>
              <span
                className={myClass.dot}
                style={{ backgroundColor: "#ced4da" }}
              ></span>
            </div>

            <div
              style={{
                display: "inline-block",
                width: "30%",
                border: "solid 1px #ced4da",
                position: "relative",
                top: -4,
              }}
            ></div>

            <div style={{ display: "inline-block" }}>
              <span
                className={myClass.dot}
                style={{ backgroundColor: "#ced4da" }}
              ></span>
            </div>
          </div>
        </div>
        {/* test */}
        <div className="d-flex  mt-n2 w-100 ">
          {/* <div className="col-1"></div> */}
          <div className=" w-100">
            <div style={{ display: "inline-block", width: "29%" }}>
              <p className="float-right" style={{ color: "#3e92cc" }}>
                {" "}
                Your Order
              </p>
            </div>

            <div style={{ display: "inline-block", width: "21%" }}>
              <p className="float-right " style={{ color: "#ced4da" }}>
                Billing
              </p>
            </div>

            <div
              className="ml-3"
              style={{ display: "inline-block", width: "25%" }}
            >
              <p className="float-right" style={{ color: "#ced4da" }}>
                Confirmation
              </p>
            </div>
          </div>
        </div>

        <BulkOrderError
          error={this.state.isError}
          reasonOfFailure={this.state.reasonOfFailure}
          closeModal={this.child2}
          isErrorFalse={this.isErrorFalse}
        />

        {/*  */}
        {/* two boxes */}
        {Spinner}
        <div className="row w-100" style={{ opacity: this.state.opacity }}>
          <div className="col-md-2  mr-n2"></div>
          <div className="col-md-5 pt-2 ml-3 ">
            <div className="card">
              <div className="card-body">
                <div className="row pr-0">
                  <div className=" col pl-3">
                    <h5 className="card-title ">GuestView Guide</h5>
                  </div>

                  <div className="col pr-4 ">
                    <p className="card-text text-primary float-right"></p>
                  </div>
                </div>
                {/* Rate Plans */}
                <div className="pt-2">
                  <fieldset>
                    <legend className="customlegend">
                      <h5 className="legendHeading"> Rate Plans </h5>
                    </legend>
                    <div className="plans-conatiner">
                      {this.state.ratePlans.map((plan) => {
                        return (
                          <div className="form-check d-flex pb-2" key={plan.id}>
                            <input
                              className="form-check-input row "
                              onChange={this.onChangeRatePlan}
                              type="radio"
                              name="productRatePlanName"

                              checked={
                                plan.productRatePlanID ==
                                this.state.productRateplanId
                              }
                              value={plan.productRatePlanName}
                            />
                            <label
                              className="form-check-label row pl-3"
                              htmlFor="ratePlans"
                            >
                              <small>{plan.productRatePlanName}</small>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </fieldset>
                </div>

                <div className="qty-field h-25 d-flex pt-2 mt-4 ml-0">
                  <p> QTY:</p>
                  <input
                    className="  rounded form-control col-sm-3 h-25 ml-3 mt-n1  "
                    type="number"
                    min="0"
                    name="quantity"
                    value={this.state.quantity}
                    onKeyUp={this.validateQuantity}
                    id="quantity"
                    onChange={this.onChangeQTY}
                    onBlur={this.checkQuantity}
                    ref={this.child2}
                  ></input>
                </div>
                {this.state.isQuantityError ? (
                  <div style={{ color: "red", fontSize: "13px" }}>
                    Please enter valid quantity.
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="pt-4 border-bottom"></div>
                <br />

                <div className="d-flex flex-column">
                  <BillingForSuperUser
                    preview={preview}
                    history={this.props.history}
                    handleContinue={this.handleContinue}
                    getAddressFromBill={this.getAddressFromBill}
                    isLoadingOnBilling={this.isLoadingOnBilling}
                    ref={this.child1}
                    editAddress={this.state.editAddress}
                    onAddressChange={this.onAddressChange}
                  />
                </div>
                <div className="d-flex flex-row-reverse">
                  <button
                    style={  this.state.productRateplanId &&
                    this.state.quantity > 0 &&
                  this.state.isShippingAddressFilled ? 
                  {width: "160px", marginTop: "20px"} : 
                  { opacity:0.5 , width: "160px", marginTop: "20px" }}
                    className={myClass.updateSummaryButton}
                    onClick={this.handleUpdateSummary}
                    disabled={this.state.productRateplanId &&
                      this.state.quantity > 0 &&
                    this.state.isShippingAddressFilled
                        ? false
                        : true
                    }
                  >
                    Update Summary
                  </button>
                </div>
                {/*     <div className="row pt-2">
                  {this.state.isMonthly ? (
                    <div className="col font-weight-bold">
                      <p className="card-title">Total monthly rate</p>
                    </div>
                  ) : (
                    <div className="col font-weight-bold">
                      <p className="card-title">Total annual rate</p>
                    </div>
                  )}
                  <div className="col font-weight-bold">
                    {this.state.isToggle ? (
                      this.state.isCheckedMonthly ? (
                        <p className="float-right">${this.state.rate}</p>
                      ) : (
                        <p className="float-right">${this.state.rate}</p>
                      )
                    ) : (
                      <p className="float-right"></p>
                    )}
                  </div>
                </div>
                <div className="mt-1">
                  {this.state.isMonthly ? (
                    <p className="font-italic font-weight-bold small">
                      The total monthly rate plus taxes will be charged monthly
                      to your selected payment option
                    </p>
                  ) : (
                    <p className="font-italic font-weight-bold small">
                      The total annual rate plus taxes will be charged annually
                      to your selected payment option
                    </p>
                  )}
                </div>
                  */}
              </div>
            </div>
          </div>

          {/* {Order Summary box} */}
          <div className="col-md-3 ml-3 pt-2">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Order Summary</h5>

                <div className="form-row">
                  <div className="col">
                    <p>Subtotal ({this.state.quantity} Qty)</p>
                  </div>
                  <div className="col ">
                    {this.state.isToggle ? (
                      <p className="float-right">${this.state.subTotal}</p>
                    ) : (
                      <p className="float-right">$0.00</p>
                    )}
                  </div>
                </div>
                <div className="form-row mt-n3">
                  <div className="col">
                    <p>Shipping ({this.state.quantity} Qty)</p>
                  </div>
                  <div className="col ml-n5">
                    {this.state.isToggle ? (
                      <p className="float-right">${this.state.shiffingFee}</p>
                    ) : (
                      <p className="float-right">$0.00</p>
                    )}
                  </div>
                </div>
                <div className="form-row mt-5">
                  <div className="col">
                    <p>Total</p>
                  </div>
                  <div className="col ml-n5">
                    {this.state.isToggle ? (
                      <p className="float-right">${this.state.total}</p>
                    ) : (
                      <p className="float-right">$0.00</p>
                    )}
                  </div>
                </div>

                <div
                  className="form-group pt-2 ml-n3 mr-n3"
                  style={{ borderBottom: "1px solid #ced4da" }}
                ></div>
              </div>
            </div>
            <div>
              <button
                onClick={this.handleContinue}
                className={myClass.continueButton}
                disabled={!this.state.enableCreate}
                style={ !this.state.enableCreate ? { opacity:0.5 } : {} }
              >
                Create Order
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    pricing: state.order,
  };
}
export default connect(mapStateToProps, { getProductList })(BulkOrder);
