import React, { useState, useEffect, useContext } from 'react';

import getUserInvoices from '../../../services/receipt';
import MESSAGES from '../../../constants/messages';
import Table from '../../../components/table';
import { RECEIPTS_TABLE_COLS } from '../../../constants/app';
import { AppContext } from '../../../context/app';

// eslint-disable-next-line react/function-component-definition
export default function Receipt() {
  const [invoices, setInvoices] = useState([]);
  const [, setIsLoading] = useContext(AppContext).loading;

  useEffect(() => {
    async function getPageData() {
      setIsLoading(true);
      const accountId = localStorage.getItem('accountId');
      const userInvoices = await getUserInvoices({ params: { accountId } });
      setInvoices(userInvoices.data);
      setIsLoading(false);
    }
    getPageData();
  }, []);

  const getInvoiceRows = () => invoices.map((invoice) => (
    {
      invoiceNo: invoice.invoiceNumber,
      accountName: invoice.accountName,
      invoiceDate: invoice.invoiceDate,
      dueDate: invoice.dueDate,
      amount: `$${invoice.amount}`,
      balance: invoice.balance,
      status: invoice.status,
    }));

  return (
    <div className="col">
      <h2 className="pt-4 ">
        <small>{MESSAGES.RECEIPTS}</small>
      </h2>

      <div className="table-responsive w-auto bg-white mb-4">
        <Table columns={RECEIPTS_TABLE_COLS} rows={getInvoiceRows()} />
      </div>
    </div>
  );
}
