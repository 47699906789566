import React, { Component } from "react";
import Header from "../header/Header";
import myClass from "./Confirmation.module.css";
import iconSecurePayment from "../../assets/images/icon_securepayment.png";
import { client } from "../../actions/index";
import Loader from "../model/LoaderWithText";
import PaymentDetailsForSuperUser from "../paymentDetails/PaymentDetailsForSuperUser";

class BulkOrderConfiramtion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taxAmount: "",
      shiffingFee: "",
      subTotal: "",
      total: "",
      isNotChecked: true,
      isMonthly: false,
      isLoading: false,
      opacity: "100%",
      cardInfo: {},
      getTaxRetry: 1
    };
  }
  componentDidMount() {
    this.setState(
      {
        isLoading: true,
        opacity: "40%",
        isMonthly: localStorage.getItem("isMonthly"),
        isNotChecked: false,
      },
      () =>
        client
          .get("checkout/previewOrdersTaxAsync", {
            params: {
              productRateplanId:
                this.props.location.state.paymentData.productRateplanId,
              productRatePlanChargeId:
                this.props.location.state.paymentData.productRatePlanChargeId,
              quantity: this.props.location.state.paymentData.quantity,
              city: this.props.location.state.paymentData.shiffingCity,
              state: this.props.location.state.paymentData.shiffingState,
              country: this.props.location.state.paymentData.shiffingCountry,
              postalCode:
                this.props.location.state.paymentData.shiffingPostalCode,
              isMonthly: this.props.location.state.paymentData.isMonthly,
            },
          })
          .then((response) => {
            this.asyncApiForOrderTax(response.data.jobId);
          })
    );
  }

  asyncApiForOrderTax = (jobId) => {
    client
      .get("checkout/tax/status/" + jobId, {
        params: {
          productRateplanId: this.state.productRateplanId,
          productRatePlanChargeId: this.state.productRatePlanChargeId,
        },
      })
      .then((response) => {
        const status = response.data.status
        if (status === "Completed") {
          response.data &&
            response.data.result &&
            this.setState({
              isToggle: true,
              shiffingFee: response.data.result.shiffingFee,
              subTotal: response.data.result.subTotal,
              total: response.data.result.total,
              // productRateplanId: response.data.result.productRateplanId,
              // productRatePlanChargeId: response.data.result.productRatePlanChargeId,
              taxAmount: response.data.result.taxAmount,
              isLoading: false,
              opacity: "100%",
              getTaxRetry: 1,
              isNotChecked: true,
            });
        } else if (status === "Processing") {
          setTimeout(() => {
            this.asyncApiForOrderTax(jobId);
            if(this.state.getTaxRetry > 1) {this.setState({getTaxRetry: 1}) }
         }, process.env.REACT_APP_POLLING_DELAY);
        } 
        else if (status === "Failed") {
          response.data &&
            this.setState({
              isLoading: false,
              isNotChecked: false,
              opacity: "100%",
              isError: true,
              reasonOfFailure: "Something went wrong.",
              getTaxRetry: 1,
            });
        } else if(status === null || status === undefined){
          if(this.state.getTaxRetry < 3){
            setTimeout(() => {
              this.asyncApiForOrderTax(jobId);
           }, process.env.REACT_APP_POLLING_DELAY);
           this.setState({getTaxRetry: this.state.getTaxRetry+1})
          } else {
            this.setState({
              isLoading: false,
              isNotChecked: false,
              opacity: "100%",
              isError: true,
              reasonOfFailure: "Something went wrong.",
              getTaxRetry: 1,
            });
          }
        }
      });
  };

  handleEdit = () => {
    const data = this.props.location.state.paymentData
    this.props.history.push({
      pathname: `/nec/bulk-order`,
      state: {enableCreate: true, isEdit: true, ...data},
    });
  };

  getSelectedCard = (cardInfo) => {
    this.setState({ cardInfo });
  };

  handleClikOrder = () => {
    this.setState({
      isLoading: true,
      opacity: "40%",
      isNotChecked: false,
    });
    client
      .post("checkout/order/bulk", {
        ...this.state.cardInfo,
        ...this.props.location.state.paymentData,
      })
      .then((response) => {
        this.createAsyncOrders(response.data.jobId);
      });
  };

  createAsyncOrders = (jobId) => {
    client.get("checkout/order/status/" + jobId).then((response) => {
      if (response.data.status === "Completed") {
        let order = {
          creditCardNumber: this.state.cardInfo.creditCardNumber,
          orderNumber: response.data.result.orderNumber,
          accountNumber: response.data.result.accountNumber,
          paidAmount: response.data.result.paidAmount,
          productRatePlan:
            this.props.location.state.paymentData.productRatePlanChargeId,
          quantity: this.props.location.state.paymentData.quantity,
          isOrder: true,
          orderDate: response.data.result.orderDate,
        };
        this.props.history.push({
          pathname: `/nec/bulk-order-receipt`,
          state: { orderData: order },
        });
      } else if (response.data.status === "Processing") {
        setTimeout(() => {
          this.createAsyncOrders(jobId);
       }, process.env.REACT_APP_POLLING_DELAY);
      }
    });
  };

  render() {
    let Spinner = null;
    if (this.state.isLoading === true) {
      Spinner = <Loader />;
    }
    return (
      <div className="fill-window">
        <Header image={iconSecurePayment} text="secure payment" />
        <div className="d-flex mt-3 w-100 ">
          <div className="col-3"></div>
          <div className=" w-100">
            <div className={myClass.dot}>
              <span></span>
            </div>

            <div className={myClass.line1}></div>

            <div className={myClass.dot}>
              <span></span>
            </div>

            <div className={myClass.line2}></div>

            <div className={myClass.dot}>
              <span></span>
            </div>
          </div>
        </div>
        {/* test */}
        <div className="d-flex  mt-n2 w-100 ">
          <div className=" w-100">
            <div className={myClass.yourorder}>
              <p className="float-right"> Your Order</p>
            </div>

            <div className={myClass.billing}>
              <p className="float-right">Billing</p>
            </div>

            <div className="ml-3" className={myClass.confirmation}>
              <p className="float-right">Confirmation</p>
            </div>
          </div>
        </div>

        {/* test Over*/}

        {Spinner}
        <div className="row w-100" style={{ opacity: this.state.opacity }}>
          <div className="col-md-2 ml-n5"></div>
          <div className="col-md-6  pt-4">
            <div className="card ml-3">
              <div className="card-body">
                <PaymentDetailsForSuperUser
                  getSelectedCard={this.getSelectedCard}
                />

                <div
                  className="form-group pt-3"
                  style={{ borderBottom: "1px solid #ced4da" }}
                ></div>

                <div className="row  ">
                  <div className="col">
                    <h5 className="card-title">Billing Address</h5>
                  </div>
                  <div className="float-right" className={myClass.edit}>
                    <a onClick={this.handleEdit} class="card-text ">
                      Edit
                    </a>
                  </div>
                </div>
                <div className="font-weight-normal Address ml-3 ">
                  <div class="row">
                    {this.props.location.state.paymentData.cardHolderName}
                  </div>
                  <div class="row">
                    {this.props.location.state.paymentData.address},{" "}
                  </div>
                  <div class="row">
                    {this.props.location.state.paymentData.city},{" "}
                    {this.props.location.state.paymentData.state},{" "}
                    {this.props.location.state.paymentData.postalCode}
                  </div>
                  <div class="row">
                    {this.props.location.state.paymentData.phone}{" "}
                  </div>
                </div>
                <div className="row mt-4 ">
                  <div className="col">
                    <h5 className="card-title">Shipping Address</h5>
                  </div>
                  <div className="float-right" className={myClass.edit}></div>
                </div>
                <div className="font-weight-normal Address ml-3 ">
                  <div class="row">
                    {this.props.location.state.paymentData.handyman}
                  </div>
                  <div class="row">
                    {this.props.location.state.paymentData.shiffingAddress},{" "}
                  </div>
                  <div class="row">
                    {this.props.location.state.paymentData.shiffingCity},{" "}
                    {this.props.location.state.paymentData.shiffingState},{" "}
                    {this.props.location.state.paymentData.shiffingPostalCode}
                  </div>
                  <div class="row">
                    {this.props.location.state.paymentData.shiffingPhone}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {Order Summary box} */}
          <div className="col-md-3  pt-4">
            <div className="card ml-3">
              <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Order Summary</h5>
                <div className="float-right" className={myClass.edit}>
                <a onClick={this.handleEdit} class="card-text ">
                  Edit
                </a>
              </div>
              </div>
                <div className="form-row">
                  <div className="col">
                    <p className="">
                      Subtotal ({this.props.location.state.paymentData.quantity}{" "}
                      Qty)
                    </p>
                  </div>
                  <div className="col ">
                    <p className="float-right">${this.state.subTotal}</p>
                  </div>
                </div>
                <div className="form-row mt-n3">
                  <div className="col">
                    <p>
                      Shipping ({this.props.location.state.paymentData.quantity}{" "}
                      Qty)
                    </p>
                  </div>
                  <div className="col ml-n5">
                    <p className="float-right">${this.state.shiffingFee}</p>
                  </div>
                </div>
                <div className="form-row mt-n3">
                  <div className="col">
                    <p>Tax </p>
                  </div>
                  <div className="col ml-n5">
                    <p className="float-right">${this.state.taxAmount}</p>
                  </div>
                </div>
                <div className="form-row font-weight-bold mt-3">
                  <div className="col">
                    <b>Total</b>
                  </div>
                  <div className="col ">
                    <b className="float-right">${this.state.total}</b>
                  </div>
                </div>
              </div>
            </div>
            <div className=" mt-3 ml-3">
              <button
                onClick={this.handleClikOrder}
                type="button"
                className="w-100 btn"
                disabled={!this.state.isNotChecked}
                style={{ backgroundColor: "#3e92cc", color: "white" }}
              >
                Place Order
              </button>

              <div className="form-row justify-content-center font-weight-normal pt-3 mb-5  ">
                Need help? Contact us:
                <br />
                Phone: 1-800-632-4662
                <br />
                Email: guestview@sharpnec-displays.com
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BulkOrderConfiramtion;
