import { client } from './index';

export function getProductList() {
  return function () {
    client.get('checkout/previewOrders')
      .then((response) => {
        console.log('from action order', response);
        //   dispatch({
        //     type: 'GET_ALL_PRODUCTS',
        //     payload: response.data,
        //   })
      });
  };
}
