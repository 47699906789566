import { httpClient } from './index';
import * as constant from '../constant';
import { API_ROUTES } from '../constants/routes';

export default function getUserPayments(payload) {
  return new Promise((resolve, reject) => {
    httpClient.get(`${constant.BASE_URL}${API_ROUTES.USER_PAYMENTS}`, payload)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getUserPaymentMethods(payload) {
  return new Promise((resolve, reject) => {
    httpClient.get(`${constant.BASE_URL}${API_ROUTES.USER_PAYMENT_METHOD}`, payload)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateDefaultPaymentMethod(payload) {
  return new Promise((resolve, reject) => {
    httpClient.post(`${constant.BASE_URL}${API_ROUTES.DEFAULT_PAYMENT_METHOD}`, payload)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deletePaymentMethodById(payload) {
  return new Promise((resolve, reject) => {
    httpClient.post(`${constant.BASE_URL}${API_ROUTES.PAYMENT_METHOD_BY_ID}`, payload)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function addNewPaymentMethod(payload) {
  return new Promise((resolve, reject) => {
    httpClient.post(`${constant.BASE_URL}${API_ROUTES.NEW_PAYMENT_METHOD}`, payload)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updatePaymentMethod(payload) {
  return new Promise((resolve, reject) => {
    httpClient.post(`${constant.BASE_URL}${API_ROUTES.UPDATE_PAYMENT_METHOD}`, payload)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
