import React, { useState } from 'react';
import PropTypes from 'prop-types';
import creditCardType from 'credit-card-type';

import MESSAGES from '../constants/messages';
import { PAYMENT_CARD_TYPES } from '../constants/app';

// eslint-disable-next-line react/function-component-definition
export default function CreditCardField({
  register, setSelectedCardType, error, readOnly,
}) {
  const [invalidCard, setInvalidCard] = useState(false);

  const setCardInvalid = () => {
    setSelectedCardType('');
    setInvalidCard(true);
  };

  const onCreditCardChange = (cardNumber) => {
    if (cardNumber) {
      if (creditCardType(cardNumber).length > 0) {
        const cardType = creditCardType(cardNumber)[0].niceType.replace(/\s/g, '');
        const selectedType = Object.keys(PAYMENT_CARD_TYPES).filter(
          (key) => PAYMENT_CARD_TYPES[key].toLowerCase() === cardType.toLowerCase(),
        )[0];
        if (Object.keys(PAYMENT_CARD_TYPES).includes(selectedType)) {
          setSelectedCardType(selectedType);
          setInvalidCard(false);
        } else {
          setCardInvalid();
        }
      } else {
        setCardInvalid();
      }
    } else {
      setCardInvalid();
    }
  };

  return (
    <>
      <input
        type="text"
        className="form-control"
        id="creditCardNumber"
        maxLength="16"
        readOnly={readOnly}
        name="creditCardNumber"
        {...register('creditCardNumber', {
          onBlur: (e) => (!readOnly ? onCreditCardChange(e.target.value) : null),
        })}
        placeholder={error}
      />
      {invalidCard && (
      <p style={{ color: 'red', fontSize: 'small' }}>
        {MESSAGES.VALID_CARD_NUMBER}
      </p>
      )}
    </>
  );
}

CreditCardField.propTypes = {
  register: PropTypes.func,
  setSelectedCardType: PropTypes.func,
  error: PropTypes.string,
  readOnly: PropTypes.bool,
};

CreditCardField.defaultProps = {
  register: () => {},
  setSelectedCardType: () => {},
  error: '',
  readOnly: false,
};
