import { httpClient } from './index';
import * as constant from '../constant';
import { API_ROUTES } from '../constants/routes';

export function previewOrder(params) {
  return new Promise((resolve, reject) => {
    httpClient.get(`${constant.BASE_URL}${API_ROUTES.PREVIEW_ORDERS}`, { params })
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}


export function previewOrderAsync(params) {
  return new Promise((resolve, reject) => {
    httpClient.get(`${constant.BASE_URL}${API_ROUTES.PREVIEW_ORDERS_ASYNC}`, { params })
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function jobStatus(params,jobId) {
  return new Promise((resolve, reject) => {
    httpClient.get(`${constant.BASE_URL}${API_ROUTES.ASYNC_JOB_STATUS}/${jobId}`, { params })
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}