import React, { Component } from "react";
import Header from "../header/Header";
import visa from "../../assets/images/card_visa.png";
import discover from "../../assets/images/card_discover.png";
import cardmaster from "../../assets/images/card_master.png";
import amex from "../../assets/images/card_amex.png";
import myClass from "./Confirmation.module.css";
import iconSecurePayment from "../../assets/images/icon_securepayment.png";
import { client } from "../../actions/index";
import Loader from '../model/Loader';

class ExistingConfiramtion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taxAmount: "",
      shiffingFee: "",
      subTotal: "",
      total: "",
      isNotChecked: true,
      isMonthly:false,
      isLoading: false,
      opacity: "100%",
    };
  }
  componentDidMount() {
   
    this.setState({
      isLoading: true,
      opacity: "40%",
      isMonthly:localStorage.getItem('isMonthly')
    }, () =>
      client.get('checkout/previewOrdersTax', {
        params: {
           
          productRateplanId:  localStorage.getItem('productRateplanId'),
          productRatePlanChargeId:  localStorage.getItem('productRatePlanChargeId'),
          quantity:  localStorage.getItem('quantity'),
          city: this.props.location.state.paymentData.city,
          state: this.props.location.state.paymentData.state,
          country: this.props.location.state.paymentData.country,
          postalCode: this.props.location.state.paymentData.postalCode,
          isMonthly:localStorage.getItem('isMonthly'),
        }
      })
        .then((response) => {
        
          this.setState({
            isToggle: true,
            shiffingFee: response.data.shiffingFee,
            subTotal: response.data.subTotal,
            total: response.data.total,
            productRateplanId: response.data.productRateplanId,
            productRatePlanChargeId: response.data.productRatePlanChargeId,
            taxAmount: response.data.taxAmount,
            isLoading: false,
            opacity: "100%"
          })

        })
    );
  }
  handleEdit=()=> {
    
    this.props.history.push({
      pathname: `/nec/existingCustomerBilling/`,
     
    });
  }

  handleClikOrder=()=> {
    return
    this.setState({
      isLoading:true,
      opacity:"40%",
      isNotChecked:true
    })
    client
      .post("checkout/createOrderExisting", this.props.location.state.paymentData)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success) {
          let order = {
            creditCardNumber: this.props.location.state.paymentData
              .creditCardNumber,
           
            orderNumber: response.data.orderNumber,
            accountNumber: response.data.accountNumber,
            paidAmount: response.data.paidAmount,
           
            productRatePlan: localStorage.getItem('productRatePlan'),
            quantity: localStorage.getItem('quantity'),
            isOrder: true,
            orderDate: response.data.orderDate,
            
          };
          this.props.history.push({
            pathname: `/nec/receipt`,
            state: { orderData: order },
          });
        }
      });
  }

  
  renderImage = (type) => {
    switch (type) {
      case "Visa":
        return (
          <img
            src={visa}
            alt="Visa logo"
            className=" rounded border img-fluid-float left "
            style={{ height: "35px", borderColor: "#6c757d" }}
          />
        );
      case "Discover":
        return (
          <img
            src={discover}
            alt="Discover logo"
            className=" rounded border img-fluid-float left "
            style={{ height: "35px", borderColor: "#6c757d" }}
          />
        );
      case "MasterCard":
        return (
          <img
            src={cardmaster}
            alt="Master logo"
            className=" rounded border img-fluid-float left "
            style={{ height: "35px", borderColor: "#6c757d" }}
          />
        );
        case "AmericanExpress":
          return (
            <img
              src={amex}
              alt="amex logo"
              className=" rounded border img-fluid-float left "
              style={{ height: "35px", borderColor: "#6c757d" }}
            />
          );
    }
  };
  handleCheck=()=>{
    this.setState((prevState) => {
      return {
       
        isNotChecked :!prevState.isNotChecked 
      };
    });
  }
  render() {
    let Spinner = null;
    if (this.state.isLoading === true) {
      Spinner = <Loader />;
    }
    return (
      <div className="fill-window">
        <Header image={iconSecurePayment} text="secure payment" />
        <div className="d-flex mt-3 w-100 ">
          <div className="col-3"></div>
          <div className=" w-100">
            <div className={myClass.dot}>
              <span></span>
            </div>

            <div className={myClass.line1}></div>

            <div className={myClass.dot}>
              <span></span>
            </div>

            <div className={myClass.line2}></div>

            <div className={myClass.dot}>
              <span></span>
            </div>
          </div>
        </div>
        {/* test */}
        <div className="d-flex  mt-n2 w-100 ">
         
          <div className=" w-100">
            <div className={myClass.yourorder}>
              <p className="float-right"> Your Order</p>
            </div>

            <div className={myClass.billing}>
              <p className="float-right">Billing</p>
            </div>

            <div className="ml-3" className={myClass.confirmation}>
              <p className="float-right">Confirmation</p>
            </div>
          </div>
        </div>

        {/* test Over*/}
     
        {Spinner}
        <div className="row w-100" style={{ opacity: this.state.opacity }}>
          <div className="col-md-2 ml-n5"></div>
          <div className="col-md-6  pt-4">
            <div className="card ml-3">
              <div className="card-body">
                <div className="row pt-3 ">
                  <div className="col">
                    <h5 className="card-title">Payment Details</h5>
                  </div>
                  <div className="float-right" className={myClass.edit}>
                    <a
                      onClick={this.handleEdit}
                      class="card-text "
                    >
                      Edit
                    </a>
                  </div>
                </div>
                <div className="col-md-12 d-flex">
                  <div className="col mt-1 ml-n2">
                    {this.renderImage(this.props.location.state.paymentData.creditCardType)}
                  </div>
                  <h6 className="col-md-11  mt-2 row " >
                    <span className={myClass.h6}>
                      <h6 className="" >{this.props.location.state.paymentData.creditCardType==='AmericanExpress'?'American Express':this.props.location.state.paymentData.creditCardType} {this.props.location.state.paymentData.creditCardNumber}</h6>
                      <h6 className="">Expiration: {this.props.location.state.paymentData.expirationMonth}/{this.props.location.state.paymentData.expirationYear} </h6></span></h6>
                </div>
                <div>
                  {
                  localStorage.getItem('isMonthly')=='true'
                  ?
                  <p className="font-italic small mt-3"> ${localStorage.getItem('subTotal')} plus taxes will be charged monthly to your selected payment option.</p>
                  :
                  <p className="font-italic small mt-3"> ${localStorage.getItem('subTotal')} plus taxes will be charged annually to your selected payment option.</p>
                }
                </div>
               

                <div
                  className="form-group pt-3"
                  style={{ borderBottom: "1px solid #ced4da" }}
                ></div>
               
                <div className="row  ">
                  <div className="col">
                    <h5 className="card-title">Billing Address</h5>
                  </div>
                  <div className="float-right" className={myClass.edit}>
                    <a
                      onClick={this.handleEdit}
                      class="card-text "
                    >
                      Edit
                    </a>
                  </div>
                </div>
                <div className="font-weight-normal Address ml-3 ">
                  <div class="row">
                    {this.props.location.state.paymentData.cardHolderName} 
                  </div>
                  <div class="row">
                    {this.props.location.state.paymentData.address},{" "}
                  </div>
                  <div class="row">
                    {this.props.location.state.paymentData.city},{" "}
                     {this.props.location.state.paymentData.state},{" "}
                      {this.props.location.state.paymentData.postalCode}
                  </div>
                  <div class="row">
                    {this.props.location.state.paymentData.phone}{" "}
                  </div>
                </div>
                <div className="row mt-4 ">
                  <div className="col">
                    <h5 className="card-title">Shipping Address</h5>
                  </div>
                  <div className="float-right" className={myClass.edit}>
                 
                  </div>
                </div>
                <div className="font-weight-normal Address ml-3 ">
                  <div class="row">
                    {this.props.location.state.paymentData.handyman} 
                  </div>
                  <div class="row">
                    {this.props.location.state.paymentData.shiffingAddress},{" "}
                  </div>
                  <div class="row">
                    {this.props.location.state.paymentData.shiffingCity},{" "}
                     {this.props.location.state.paymentData.shiffingState},{" "}
                      {this.props.location.state.paymentData.shiffingPostalCode}
                  </div>
                  <div class="row">
                    {this.props.location.state.paymentData.shiffingPhone}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {Order Summary box} */}
          <div className="col-md-3  pt-4">
            <div className="card ml-3">
              <div className="card-body">
                <h5 className="card-title">Order Summary</h5>
                <div className="form-row">
                  <div className="col">
                    <p className="">Subtotal ({localStorage.getItem('quantity')} Qty)</p>
                  </div>
                  <div className="col ">
                    <p className="float-right">${this.state.subTotal}</p>
                  </div>
                </div>
                <div className="form-row mt-n3">
                  <div className="col">
                    <p>Shipping ({localStorage.getItem('quantity')} Qty)</p>
                  </div>
                  <div className="col ml-n5">
                    <p className="float-right">${this.state.shiffingFee}</p>
                  </div>
                </div>
                <div className="form-row mt-n3">
                  <div className="col">
                    <p>Tax </p>
                  </div>
                  <div className="col ml-n5">
                    <p className="float-right">${this.state.taxAmount}</p>
                  </div>
                </div>
                <div className="form-row font-weight-bold mt-3">
                  <div className="col">
                    <b>Total</b>
                  </div>
                  <div className="col ">
                    <b className="float-right">${this.state.total}</b>
                  </div>
                </div>
<div className="pl-0 ml-n4 mt-3">
  <ul>
<li><small>1 year initial term from the date of the initial transaction.</small></li>
              <li><small>You may cancel your subscription by giving Sharp NEC a minimum of 5 working days notice prior to the auto-renewal date.</small></li>
              <li><small>The auto-renewal date is the date that is one (1) year after the date of your initial transaction.</small></li>
              </ul>
</div>
                <div className="font-weight-normal row ml-1 pr-2 mt-2 pt-2 pb-4">
                  <p className="mb-1"><small>GuestView Guide is leased from Sharp NEC with a 1 year commitment, subject to user agreement period.
                                      By placing this order, you agree to our</small>
                  </p>
                  <div className="checkbox ">
                    <input type="checkbox" onClick={this.handleCheck} />
                    
                    <a href="https://guestviewguide.com/terms-and-conditions/" target="_blank" className="ml-1">
                      <small> Terms & Conditions</small>
                     </a>
                    
                   {"  "}&nbsp;|
                   <a href="https://guestviewguide.com/privacy-policy/" target="_blank" className="ml-2">
                      <small> Privacy Policy</small>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className=" mt-3 ml-3">

            {/* 
            <button onClick={this.handleClikOrder} type="button" className="w-100 btn" disabled={this.state.isNotChecked} style={{backgroundColor:"#3e92cc",color:"white"}} >Place Order</button>
          */} 


              <div className="form-row justify-content-center font-weight-normal pt-3 mb-5  ">
                Need help? Contact us:
                <br />
                Phone: 1-800-632-4662
                <br />
                Email: guestview@sharpnec-displays.com
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ExistingConfiramtion;