import React from 'react';
import PropTypes from 'prop-types';

import MESSAGES from '../constants/messages';

// eslint-disable-next-line react/function-component-definition
export default function FormError({ error }) {
  return (
    <div>
      {(error && error !== MESSAGES.CANNOT_BE_EMPTY) && (
      <p style={{ color: 'red', fontSize: 'small' }}>
        {error}
      </p>
      )}
    </div>
  );
}

FormError.propTypes = {
  error: PropTypes.string,
};

FormError.defaultProps = {
  error: '',
};
