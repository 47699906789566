import React from 'react';
import PropTypes from 'prop-types';

import MESSAGES from '../constants/messages';

// eslint-disable-next-line react/function-component-definition
export default function CvvField({
  register, selectedCardType, error,
}) {
  return (
    <>
      <input
        type="password"
        className="form-control"
        name="cvv"
        {...register('cvv')}
        minLength={3}
        maxLength={selectedCardType === 'AMERICAN_EXPRESS' ? 4 : 3}
      />
      {error && (
      <p style={{ color: 'red', fontSize: 'small' }}>
        {MESSAGES.INVALID_CVV}
      </p>
      )}
    </>
  );
}

CvvField.propTypes = {
  register: PropTypes.func,
  selectedCardType: PropTypes.string,
  error: PropTypes.string,
};

CvvField.defaultProps = {
  register: () => {},
  selectedCardType: '',
  error: '',
};
