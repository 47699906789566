import React, {
  useContext,
  useEffect, useMemo, useState,
} from 'react';
import { useForm } from 'react-hook-form';

import EditAccount from '../../../components/editAccount';
import Table from '../../../components/table';
import { SUBSCRIPTION_TABLE_COLS } from '../../../constants/app';
import MESSAGES from '../../../constants/messages';
import { APP_ROUTES } from '../../../constants/routes';
import { getUserDetails, getUserSubscriptions } from '../../../services/account';
import myClass from './accountAndPlan.module.css';
import { AppContext } from '../../../context/app';

// eslint-disable-next-line react/function-component-definition
export default function AccountAndPlan() {
  const defaultValues = useMemo(() => ({
    firstName: '',
    lastName: '',
    city: '',
    state: '',
    zipCode: '',
    address1: '',
    workEmail: '',
    workPhone: '',
    country: '',
  }), []);
  const { setValue, getValues } = useForm({
    defaultValues,
  });
  const [subscriptions, setSubscriptions] = useState([]);
  const [, setIsLoading] = useContext(AppContext).loading;

  useEffect(() => {
    const setFormValues = (values) => {
      Object.keys(defaultValues).forEach((key) => setValue(key, values[key]));
    };

    async function getPageData() {
      setIsLoading(true);
      const accountId = localStorage.getItem('accountId');
      const userDetails = await getUserDetails(accountId);
      setFormValues(userDetails.data);

      const userSubscriptions = await getUserSubscriptions({ params: { accountId } });
      setSubscriptions(userSubscriptions.data.subscriptions);
      setIsLoading(false);
    }
    getPageData();
  }, [defaultValues, setValue]);

  const handleNewOrder = () => {
    window.location.href = APP_ROUTES.ORDER;
  };

  // TODO: this function will be optimized after backend data
  const getSubscriptionRows = () => subscriptions.map((subscription) => ({
    id: subscription.id,
    ratePlan: subscription.ratePlans[0].ratePlanName,
    billingPeriod: subscription.ratePlans[0].ratePlanCharges.find(
      (i) => (i.name === 'Software Monthly Fee') || (i.name === 'Software Yearly Fee'),
    ).billingPeriod,
    autoRenew: subscription.autoRenew ? 'TRUE' : 'FALSE',
    nextBilling: subscription.ratePlans[0].ratePlanCharges.find(
      (i) => (i.name === 'Software Monthly Fee') || (i.name === 'Software Yearly Fee'),
    ).chargedThroughDate,
    amount: `$${subscription.ratePlans[0].ratePlanCharges.find(
      (i) => (i.name === 'Display Yearly Fee')
      || (i.name === 'Software Yearly Fee'),
    )
      ? subscription.ratePlans[0].ratePlanCharges.find(
        (i) => (i.name === 'Software Yearly Fee'),
      ).tcv
    + subscription.ratePlans[0].ratePlanCharges.find(
      (i) => (i.name === 'Display Yearly Fee'),
    ).tcv
      : subscription.contractedMrr}`,
  }));

  return (
    <div className="ml-3">
      <h2 className="pt-4">
        <small>{MESSAGES.ACCOUNT}</small>
      </h2>
      <div className="col-sm-8 pl-0 ml-0">
        <div className="card  pl-4">
          <form>
            <div className="form-group row mt-3">
              <p className="col-sm-2 col-form-label">
                {`${MESSAGES.USERNAME}:`}
              </p>
              <div className="col-sm-9">
                <p className="border-bottom ">{getValues('workEmail')}</p>
              </div>
            </div>
            <div className="form-group row">
              <p className="col-sm-2 col-form-label">
                {`${MESSAGES.NAME}:`}
              </p>
              <div className="col-sm-9 ">
                <p className="border-bottom ">
                  {`${getValues('firstName')} ${getValues('lastName')}`}
                </p>
              </div>
            </div>
            <div className="form-group row">
              <p className="col-sm-2 col-form-label">
                {`${MESSAGES.ADDRESS}:`}
              </p>
              <div className="col-sm-9 ">
                <p className="border-bottom">
                  {`${getValues('address1')}, ${getValues('city')}, ${getValues('state')} ${getValues('zipCode')}`}
                </p>
              </div>
            </div>
            <div className="form-group row">
              <p className="col-sm-2 col-form-label ">{`${MESSAGES.PHONE}:`}</p>
              <div className="col-sm-9 ">
                <p className="border-bottom">{getValues('workPhone')}</p>
              </div>
            </div>

            <div className="form-group row">
              <div className="col">
                <button
                  type="button"
                  className={myClass.editPlan}
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {MESSAGES.EDIT}
                </button>
                <EditAccount
                  defaultValues={getValues()}
                  setIsLoading={setIsLoading}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="d-flex mt-4 mb-2 col-sm-10 px-0 justify-content-between">
        <h2 className="p-0 m-0">
          <small>{MESSAGES.PLAN}</small>
        </h2>
       {/* <button type="button" className="btn" onClick={handleNewOrder} style={{ backgroundColor: '#3e92cc', color: 'white' }}>{MESSAGES.ADD_PLAN}</button> */}
      </div>
      <div className="table-responsive  mb-4">
        <Table columns={SUBSCRIPTION_TABLE_COLS} rows={getSubscriptionRows()} />
        <div className="w-100 form-row">
          <p className="col-7" />
          <p className="float-right col ml-n4">{MESSAGES.CONTACT_FOR_QUESTION}</p>
        </div>
      </div>
    </div>
  );
}
