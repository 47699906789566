import React from 'react';
import Error from './components/error';
import Loader from './components/loader';

import PageContent from './components/Route';
import AppContextProvider from './context/app';
import './style.scss';

// eslint-disable-next-line react/function-component-definition
export default function App() {
  return (
    <AppContextProvider>
      <Loader>
        <Error />
        <div className="App">
          <PageContent />
        </div>
      </Loader>
    </AppContextProvider>
  );
}
