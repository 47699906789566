import React, { Component } from "react";
import $ from "jquery";
class OrderError extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    $("#myModal").modal("show");
  }
  render() {
    console.log("prosp from error page", this.props);
    $("#myModal").modal("show");
    return (
      <div id="myModal" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div>
              <button type="button" className="close mr-2" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              
                <p> {this.props.reasonOfFailure}</p>
             
            </div>
           
          </div>    
        </div>
      </div>
    );
  }
}
export default OrderError;