import React, { useContext } from 'react';
import LoadingOverlay from 'react-loading-overlay';

import { AppContext } from '../../context/app';

// eslint-disable-next-line react/function-component-definition
export default function Loader({ children }) {
  const { loading } = useContext(AppContext);
  const [isLoading] = loading;

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      fadeSpeed={0}
    >
      {children}
    </LoadingOverlay>
  );
}
