import React, { Component } from "react";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const email=localStorage.getItem('email');
    const {handleLogout}=this.props;
    return (
      <div>
      <nav  className="navbar navbar-light bg-white">
       <p className="navbar-brand"></p>
      <ul className="nav nav-pills">
       
        <li className="nav-item dropdown">
        <a className=" dropdown-toggle text-dark" data-toggle="dropdown" role="button" aria-expanded="false">{email}</a>
          <div className="dropdown-menu ml-0 col-12 mr-1">
            <a className="dropdown-item" role="button" onClick={handleLogout}>Logout</a>
            
          </div>
        </li>
      </ul>
    </nav>
    </div>
    );
  }
}

export default Navbar;
