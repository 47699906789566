import { httpClient } from './index';
import * as constant from '../constant';
import { API_ROUTES } from '../constants/routes';

export function createOrder(payload) {
  return new Promise((resolve, reject) => {
    httpClient.post(`${constant.BASE_URL}${API_ROUTES.CREATE_ORDER}`, payload)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function previewOrdersTax(params) {
  return new Promise((resolve, reject) => {
    httpClient.get(`${constant.BASE_URL}${API_ROUTES.PREVIEW_ORDER_TAX}`, params)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}


export function previewOrdersTaxAsync(params) {
  return new Promise((resolve, reject) => {
    httpClient.get(`${constant.BASE_URL}${API_ROUTES.PREVIEW_ORDER_TAX_ASYNC}`, params)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function jobTaxStatus(params,jobId) {
  return new Promise((resolve, reject) => {
    httpClient.get(`${constant.BASE_URL}${API_ROUTES.ASYNC_TAX_STATUS}/${jobId}`, { params })
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function createExistingOrder(payload) {
  return new Promise((resolve, reject) => {
    httpClient.post(`${constant.BASE_URL}${API_ROUTES.CREATE_EXISTING_ORDER}`, payload)
      .then(async (response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
