import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';

import MESSAGES from '../../constants/messages';
import TopLogo from '../../assets/images/logo@1xWhite.png';
import resetPasswordSchema from '../../validations/resetPassword';
import FormError from '../../components/formError';
import { resetPassword } from '../../services/auth';
import { AppContext } from '../../context/app';

// eslint-disable-next-line react/function-component-definition
export default function ResetPassword() {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });
  const history = useHistory();
  const {
    loading, showMessage, messageText,
  } = useContext(AppContext);
  const [, setIsLoading] = loading;
  const [, setShowMsg] = showMessage;
  const [, setMessage] = messageText;

  const navigateTo = () => {
    history.push({
      pathname: '/',
    });
  };

  const onSubmit = async (formData) => {
    const { email } = formData;
    setIsLoading(true);

    const userDto = {
      email,
    };

    const response = await resetPassword(userDto);
    setIsLoading(false);
    setShowMsg(true);
    if (response.data.success) {
      setMessage(MESSAGES.CHECK_EMAIL_FOR_PASSWORD_RESET_LINK);
      navigateTo();
    } else {
      setMessage(MESSAGES.INVALID_EMAIL_2);
    }
  };

  return (
    <div className="full-screen-container">
      <div className="reset-card">
        <div className="logo-header">
          <img className="m-2" alt="" src={TopLogo} />
        </div>
        <div className="reset-pass-content">
          <h5>{MESSAGES.RESET_PASSWORD}</h5>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group pl-4 pr-4">
              <label htmlFor="email">{MESSAGES.EMAIL}</label>
              <input
                type="email"
                className="form-control rounded-0"
                placeholder={errors.email?.message}
                id="email"
                name="email"
                {...register('email')}
              />
              {errors.email?.message && <FormError error={errors.email?.message} />}
            </div>
            <div className="form-group mx-4">
              <button type="submit" className="btn btn-primary w-100" style={{ backgroundColor: '#3e92cc', borderColor: '#3e92cc' }}>
                {MESSAGES.RESET_PASSWORD}
              </button>
              <div className="px-4 text-center pt-3 pb-2">
                <a className="" onClick={navigateTo} style={{ color: '#3e92cc', cursor: 'pointer' }}>{MESSAGES.BACK_TO_LOGIN}</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
