import React, { Component } from "react";
import Header from "../header/Header";
import myClass from "./Billing.module.css";
import master from "../../assets/images/card_master.png";
import visa from "../../assets/images/card_visa.png";
import discover from "../../assets/images/card_discover.png";
import amex from "../../assets/images/card_amex.png";
import iconSecurePayment from "../../assets/images/icon_securepayment.png";
import { connect } from 'react-redux';
import { client } from "../../actions/index";
import BillingError from "./BillingError";
import CardDetails from "../paymentDetails/CardDetails";
import Loader from '../model/Loader';
class ExistingCustomerBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCardNumber: "",
      creditCardType: "",
      expiration: "",
      cvv: "",
      fname: "",
      lname: "",
      address: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
     
      errors: "",
      cardNumbererrors: "",
      visaopacity: "30%",
      amexopacity: "30%",
      discoveropacity: "30%",
      amexopacity:"30%",

      errorInExpiration: false,
  
      errorInCvv: false,
      paymentMethodId: '',
      status: false,
     
      shiffingFee: '',
      subTotal: '',
      total: '',
      isSoldTo: true,
      soldtoFname: '',
      soldtoLname: '',
      soldtoAddress: '',
      soldtoCity: '',
      soldtoState: '',
      soldtoCountry: '',
      soldtoPhone:'',
      soldtoPostalCode: '',
      errorInEmail: false,
      email: '',
      errorInEmail: false,
      hasError: false,
      isChecked: true,
      myCradInfo: [],
      creditcard:[],
      isLoading:true,
      opacity: "100%",
      phone:'',
      errorInPhone:false
     };
    this.onChange = this.onChange.bind(this);
    this.ValidateCreditCardNumber = this.ValidateCreditCardNumber.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
   
  }
  handleClikPayment() {
    this.props.history.push({
      pathname: `/nec/confirmation`,
    });
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  ValidateCreditCardNumber() {
    var ccNum = document.getElementById("creditCardNumber").value;
    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    var isValid = false;
    var cardType = null;

    if (visaRegEx.test(ccNum)) {
      isValid = true;
      cardType = "Visa";
    }
    else if (mastercardRegEx.test(ccNum)) {
      isValid = true;
      cardType = "MasterCard";
    }
    else if (amexpRegEx.test(ccNum)) {
      isValid = true;
      cardType = "AmericanExpress";
    } else if (discovRegEx.test(ccNum)) {
      isValid = true;
      cardType = "Discover";
    }

    if (isValid) {
     
      if (cardType === "Visa") {
        this.setState({
          visaopacity: "100%",
          amexopacity: "30%",
          discoveropacity: "30%",
          masteropacity:"30%"
        });
      } else if (cardType === "AmericanExpress") {
        this.setState({
          amexopacity: "100%",
          visaopacity: "30%",
          discoveropacity: "30%",
          masteropacity:"30%"
        });
      } else if (cardType === "Discover") {
        this.setState({
          discoveropacity: "100%",
          amexopacity: "30%",
          visaopacity: "30%",
          masteropacity:"30%"
        });
      }
      else if (cardType === 'MasterCard') {
        this.setState({ masteropacity: "100%", amexopacity: "30%", visaopacity: "30%", discoveropacity: "30%" })
      }
      this.setState({
        creditCardType: cardType,
      });
    } else {
      this.setState({
        cardNumbererrors: "please enter card number",
      });
    }
  }
  handleContinue() {
   
    let paymentDto;
    if (this.state.isChecked) {
      
      paymentDto = {
        creditCardNumber: this.state.creditcard.cardNumber,
        creditCardType: this.state.creditcard.cardType,
        expirationMonth: this.state.creditcard.expirationMonth,
        expirationYear: this.state.creditcard.expirationYear,
        productRateplanId:localStorage.getItem('productRateplanId'),
        productRatePlanChargeId:localStorage.getItem('productRatePlanChargeId'),
        accountId:localStorage.getItem('accountId'),
        cardHolderName:this.state.myCradInfo.cardHolderName,
        address: this.state.myCradInfo.addressLine1,
        city: this.state.myCradInfo.city,
        state: this.state.myCradInfo.state,
        phone: this.state.myCradInfo.phone,
        country: this.state.myCradInfo.country,
        postalCode: this.state.myCradInfo.zipCode,
        handyman:this.state.myCradInfo.cardHolderName,
        shiffingAddress: this.state.myCradInfo.addressLine1,
        shiffingCity: this.state.myCradInfo.city,
        shiffingState: this.state.myCradInfo.state,
        shiffingPhone: this.state.myCradInfo.phone,
        shiffingCountry: this.state.myCradInfo.country,
        shiffingPostalCode: this.state.myCradInfo.zipCode,
        quantity: localStorage.getItem('quantity'),
        
      }
      this.props.history.push({
      pathname: `/nec/existingConfiramtion`,
      state: { paymentData: paymentDto}
    });
    } else {
      if(this.checkData()){

     
      paymentDto = {
        creditCardNumber: this.state.creditcard.cardNumber,
        creditCardType: this.state.creditcard.cardType,
        expirationMonth: this.state.creditcard.expirationMonth,
        expirationYear: this.state.creditcard.expirationYear,
        cardHolderName:this.state.myCradInfo.cardHolderName,
        address: this.state.myCradInfo.addressLine1,
        city: this.state.myCradInfo.city,
        state: this.state.myCradInfo.state,
        phone: this.state.myCradInfo.phone,
        accountId:localStorage.getItem('accountId'),
        country: this.state.myCradInfo.country,
        postalCode: this.state.myCradInfo.zipCode,
        handyman: this.state.fname + ' ' + this.state.lname,
        shiffingAddress: this.state.address,
        shiffingCity: this.state.city,
        shiffingState: this.state.state,
        shiffingPhone: this.state.phone,
        shiffingCountry: this.state.country,
        shiffingPostalCode: this.state.postalCode,
        quantity: localStorage.getItem('quantity'),
        productRateplanId:localStorage.getItem('productRateplanId'),
        productRatePlanChargeId:localStorage.getItem('productRatePlanChargeId'),
      }
      this.props.history.push({
        pathname: `/nec/existingConfiramtion`,
        state: { paymentData: paymentDto}
      });
     }
   
    }
    
  }

  isValidPhone=()=>{
    if(this.state.phone.length<10){
      this.setState({errorInPhone:true})
    }
    else{
      this.setState({errorInPhone:false})
    }
  }

  checkData = () => {
    if (this.state.city === "") {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;
    }

    if (this.state.address === "") {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;
    }
    if (this.state.fname === "") {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;
    }
    if (this.state.lname === "") {
      this.setState({
        errors: "Cannot be empty",
      });
    }
    if (this.state.postalCode === "") {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;
    }
    if (this.state.state === "") {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;
    }
    if (this.state.phone === "") {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;
    }
    if (this.state.errorInPhone) {
      return false;
    }
    if (this.state.country === "") {
      this.setState({
        errors: "Cannot be empty",
      });
      return false;

     }
   
      else {
       return true;
     }
  };

  handleCheck = () => {
    this.setState((prevState) => {
      return {
        isChecked: !prevState.isChecked,
        soldtoFname: "",
        soldtoLname: "",
        soldtoAddress: "",
        soldtoCity: "",
        soldtoState: "",
        soldtoCountry: "",
        soldtoPostalCode: "",
        soldtoPhone: "",
      };
    });
  };
  isValidEmailAddress = () => {
    var userEmail = document.getElementById("email").value;
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (pattern.test(userEmail)) {
      this.setState({
        errorInEmail: false,
      });
    } else {
      this.setState({
        errorInEmail: true,
      });
    }
    return pattern.test(userEmail);
  };

  componentDidMount() {
    this.setState({
      isLoading:true,
      opacity: "40%",
      isNotChecked:true,
    })

    const accountId = localStorage.getItem('accountId');
    client.get('users/getPaymentMethod', {
      params: {
        accountId: accountId,
      }
    })
      .then((response) => {
        // console.log("printing resopnse from Existing biiling", response);
        this.setState({
          isLoading:false,
                  opacity: "100%",
          myCradInfo: response.data.creditCards.find(
            (i) =>
              i.defaultPaymentMethod === true).cardHolderInfo,
              creditcard: response.data.creditCards.find(
                (i) =>
                  i.defaultPaymentMethod === true),
                  
             
        });

      
      });

  }
  render() {


let Spinner = null;
    if (this.state.isLoading === true) {
      Spinner = <Loader />;
    }

    return (

      <div className={myClass.Rectangle}>
        {Spinner}
        <div className="fill-white">
        <Header image={iconSecurePayment} text="secure payment" />
      

        {/* <div className="d-flex mt-3 w-100 ">
          <div className="col-3"></div>
          <div className=" w-100">
            <div className={myClass.dot}>
              <span ></span>
            </div>

            <div className={myClass.line1}

            ></div>

            <div className={myClass.dot}>
              <span ></span>
            </div>

            <div className={myClass.line2}

            ></div>

            <div className={myClass.dot1}>
              <span></span>
            </div>
          </div>
        </div> */}
        {/* test */}
        

        {/* <div className="d-flex  mt-n2 w-100 ">
          <div className=" w-100">
            <div className={myClass.yourorder}>
              <p className="float-right">
                {" "}
                Your Order
              </p>
            </div>

            <div className={myClass.billing} >
              <p className="float-right" >
                Billing
              </p>
            </div>

            <div className="ml-3" className={myClass.confirmation}>
              <p className="float-right">
                Confirmation
              </p>
            </div>
          </div>
        </div> */}
        {/* test Over*/}
       
        <div className="d-flex mt-3 w-100">
            <div className="order-steps">
              <span className="selected">
                <em>1</em>
                <strong>Your Order</strong>
              </span>
              <span className="selected">
                <em>2</em>
                <strong>Billing</strong>
              </span>
              <span>
                <em>3</em>
                <strong>Confirmation</strong>
              </span>
            </div>
          </div>

          <div className="w-100 has-top-border">
            <div className="container-fluid w-1120">
        <div className="row w-100" style={{ opacity: this.state.opacity }}>
          

          <div className="col-md-8 mt-4">
            <div className="card ml-3 order-left-col mb-4">
              <div className="card-body billing-form">
                <CardDetails />
                 <h5 className="card-title mt-n1">Shipping Information</h5>
                <div className="custom-control custom-checkbox">
                  {this.state.isChecked ? <input
                    type="checkbox"
                    className="custom-control-input"
                    id="defaultChecked"
                    onClick={this.handleCheck}
                    checked
                  ></input> : <input
                    type="checkbox"
                    className="custom-control-input"
                    id="defaultChecked"
                    onClick={this.handleCheck}

                  ></input>}
                  <label className="custom-control-label" htmlFor="defaultChecked" >
                    Same as Billing Information
                  </label>
                </div>
                {this.state.isChecked ? '' :
                  <div>
                    <div className="form-row">
                      <div className="col">
                        <label htmlFor="firstName" className={myClass.label}>
                          First Name
                    </label>
                        <input
                          type="text"
                          className="form-control"
                          name="fname"
                          placeholder={this.state.errors}
                          onChange={this.onChange}
                        ></input>
                      </div>
                      <div className="col">
                        <label htmlFor="lastName" className={myClass.label}>
                          Last Name
                    </label>
                        <input
                          type="text"
                          name="lname"
                          className="form-control"
                          onChange={this.onChange}
                          placeholder={this.state.errors}
                        ></input>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-6">
                    <div className="form-group pt-2">
                      <label htmlFor="address" className={myClass.label}>
                        Phone Number
                  </label>
                      <input
                        type="number"
                        name="phone"
                        maxLength="15"
                        className="form-control"
                        onChange={this.onChange}
                        onBlur={this.isValidPhone}
                        placeholder={this.state.errors}
                      ></input>
                    </div>

                    <div className="col mb-n2 mt-n3 ml-n2">
                    {this.state.errorInPhone && this.state.phone.length>1 ? (
                      <p style={{ color: "red", fontSize: "small" }}>
                        Invalid Phone Number
                      </p>
                    ) : null}
                  </div>
                  </div>
                  </div>

                    <div className="form-group pt-2">
                      <label htmlFor="address" className={myClass.label}>
                        Address
                  </label>
                      <input
                        type="text"
                        name="address"
                        className="form-control"
                        onChange={this.onChange}
                        placeholder={this.state.errors}
                      ></input>
                    </div>

                    <div className="form-row">
                      <div className="col-6">
                    <div className="form-group ">
                      <label htmlFor="city" className={myClass.label}>
                        City
                  </label>
                      <input
                        type="text"
                        name="city"
                        className="form-control"
                        onChange={this.onChange}
                        placeholder={this.state.errors}
                      ></input>
                    </div>
                      </div>

                      <div className="col-6">
                    <div className="form-group ">
                      <label htmlFor="state" className={myClass.label}>
                        State
                  </label>
                      <input
                        type="text"
                        className="form-control"
                        name="state"
                        onChange={this.onChange}
                        placeholder={this.state.errors}
                      ></input>
                    </div>
                      </div>
                      </div>

                    <div className="form-row mb-3">
                      <div className="col">
                        <label htmlFor="country" className={myClass.label}>
                          Country
                    </label>
                        <select
                          id="country"
                          name="country"
                          onChange={this.onChange}
                          className={myClass.select}
                        >
                          <option></option>
                          <option>Afghanistan</option>
                          <option>Albania</option>
                          <option>American Samoa</option>
                          <option>Andorra</option>
                          <option>Anguilla</option>
                          <option>Antarctica</option>
                          <option>Antiqua and Barbuda</option>
                          <option>Argentina</option>
                          <option>Armenia</option>
                          <option>Aruba</option>
                          <option>Australia</option>
                          <option>Austria</option>
                          <option>Azerbaijan</option>
                          <option>Bahamas</option>
                          <option>Bahrain</option>
                          <option>Bangladesh</option>
                          <option>Barbados</option>
                          <option>Benin</option>
                          <option>Belarus</option>
                          <option>Belize</option>
                          <option>Belgium</option>
                          <option>Bermuda</option>
                          <option>Bhutan</option>
                          <option>Bolivia</option>
                          <option>Bouvet Islands</option>
                          <option>Brazil</option>
                          <option>British Indian Ocean Territory</option>
                          <option>British Virgin Islands</option>
                          <option>Brunei</option>
                          <option>Bulgaria</option>
                          <option>Burkina Faso</option>
                          <option>Burundi</option>
                          <option>Canada</option>
                          <option>Cambodia</option>
                          <option>Cameroon</option>
                          <option>Cape Verde</option>
                          <option>Cayman Islands</option>
                          <option>Central African Republic</option>
                          <option>Chad</option>
                          <option>Chile</option>
                          <option>China</option>
                          <option>Colombia</option>
                          <option>Comoros</option>
                          <option>Congo</option>
                          <option>Costa Rica</option>
                          <option>Cote D'Ivoire</option>
                          <option>Croatia</option>
                          <option>Cyprus</option>
                          <option>Czech Republic</option>
                          <option>Denmark</option>
                          <option>Djibouti</option>
                          <option>Dominica</option>
                          <option>Dominican Republic</option>
                          <option>Ecuador</option>
                          <option>Egypt</option>
                          <option>El Salvador</option>
                          <option>Equatorial Guinea</option>
                          <option>Eritrea</option>
                          <option>Estonia Faroe Islands</option>
                          <option>Ethiopia</option>
                          <option>Falkland Islands</option>
                          <option>Faroe Islands</option>
                          <option>Federated States of Micronesia</option>
                          <option>Fiji</option>
                          <option>Finland</option>
                          <option>France</option>
                          <option>French Guiana</option>
                          <option>French Polynesia</option>
                          <option>Gabon</option>
                          <option>Gambia</option>
                          <option>Georgia</option>
                          <option>Germany</option>
                          <option>Ghana</option>
                          <option>Gibraltar</option>
                          <option>Greece</option>
                          <option>Greenland</option>
                          <option>Grenada</option>
                          <option>Guadeloupe</option>
                          <option>Guam</option>
                          <option>Guatemala</option>
                          <option>Guinea- Bissau</option>
                          <option>Guyana</option>
                          <option>Haiti</option>
                          <option>Honduras</option>
                          <option>Hong Kong</option>
                          <option>Hungary</option>
                          <option>Iceland</option>
                          <option>India</option>
                          <option>Indonesia</option>
                          <option>Ireland</option>
                          <option>Israel</option>
                          <option>Italy</option>
                          <option>Jamaica</option>
                          <option>Japan</option>
                          <option>Jordan</option>
                          <option>Kazakhstan</option>
                          <option>Kenya</option>
                          <option>Kiribati</option>
                          <option>Kuwait</option>
                          <option>Kyrgyzstan</option>
                          <option>Laos</option>
                          <option>Latvia</option>
                          <option>Lebanon</option>
                          <option>Lesotho</option>
                          <option>Liechtenstein</option>
                          <option>Lithuania</option>
                          <option>Luxembourg</option>
                          <option>Macau</option>
                          <option>Madagascar</option>
                          <option>Malawi</option>
                          <option>Malaysia</option>
                          <option>Maldives</option>
                          <option>Mali</option>
                          <option>Malta</option>
                          <option>Marshall Islands</option>
                          <option>Martinique</option>
                          <option>Mauritania</option>
                          <option>Mayotte</option>
                          <option>Metropolitan France</option>
                          <option>Mexico</option>
                          <option>Moldova</option>
                          <option>Mongolia</option>
                          <option>Morocco</option>
                          <option>Mozambique</option>
                          <option>Namibia</option>
                          <option>Nauru</option>
                          <option>Nepal</option>
                          <option>Netherlands</option>
                          <option>Netherlands Antilles</option>
                          <option>New Caledonia</option>
                          <option>New Zealand</option>
                          <option>Nicaragua</option>
                          <option>Niger</option>
                          <option>Nigeria</option>
                          <option>Northern Mariana Islands</option>
                          <option>Norway</option>
                          <option>Oman</option>
                          <option>Pakistan</option>
                          <option>Palau</option>
                          <option>Panama</option>
                          <option>Papua New Guinea</option>
                          <option>Paraguay</option>
                          <option>Peru</option>
                          <option>Philippines</option>
                          <option>Pitcairn</option>
                          <option>Poland</option>
                          <option>Portugal</option>
                          <option>Puerto Rico</option>
                          <option>Qatar</option>
                          <option>Reunion</option>
                          <option>Republic of Korea</option>
                          <option>Romania</option>
                          <option>Russia</option>
                          <option>Sao Tome and Principe</option>
                          <option>Saudi Arabia</option>
                          <option>Senegal</option>
                          <option>Seychelles</option>
                          <option>Sierra Leone</option>
                          <option>Singapore</option>
                          <option>Slovakia</option>
                          <option>Slovenia</option>
                          <option>Solomon Islands</option>
                          <option>Somalia</option>
                          <option>South Africa</option>
                          <option>Spain</option>
                          <option>Sri Lanka</option>
                          <option>St. Helena</option>
                          <option>St. Kitts and Nevis</option>
                          <option>St. Lucia</option>
                          <option>St. Vincent and the Grenadines</option>
                          <option>Sudan</option>
                          <option>Suriname</option>
                          <option>Svalbard and Jan Mayen Islands</option>
                          <option>Swaziland</option>
                          <option>Sweden</option>
                          <option>Switzerland</option>
                          <option>Syria</option>
                          <option>Taiwan</option>
                          <option>Tajikistan</option>
                          <option>Tanzania</option>
                          <option>Thailand</option>
                          <option>Togo</option>
                          <option>Tonga</option>
                          <option>Trinidad and Tobago</option>
                          <option>Turkey</option>
                          <option>Turkmenistan</option>
                          <option>Turks and Caicos Islands</option>
                          <option>Tuvalu</option>
                          <option>Uganda</option>
                          <option>Ukraine</option>
                          <option>United Arab Emirates</option>
                          <option>United Kingdom</option>
                          <option>United States</option>
                          <option>Uruguay</option>
                          <option>Uzbekistan</option>
                          <option>Vanuatu</option>
                          <option>Vatican City</option>
                          <option>Vietnam</option>
                          <option>Venezuela</option>
                          <option>Western Sahara</option>
                          <option>Yemen</option>
                          <option>The former Yugoslav Republic of Macedonia</option>
                          <option>Yugoslavia</option>
                          <option>Zaire</option>
                          <option>Zambia</option>
                          <option>Zimbabwe</option>
                        </select>
                      </div>
                      <div className="col">
                        <label htmlFor="postalCode" className={myClass.label}>
                          Zipcode
                    </label>
                        <input
                          type="text"
                          className="form-control"
                          name="postalCode"
                          onChange={this.onChange}
                          placeholder={this.state.errors}
                        ></input>
                      </div>
                    </div>

                  </div>}

              </div>

            </div>
          </div>


          {/* {Order Summary box} */}
          <div className="col-md-4">
            <div className="card rounded-0 order-col pt-4">
              <div className="card-body">
                <h5 className="card-title">Order Summary</h5>
                <div className="order-total-calculation">

                <p>
                          {" "}
                          {/*
                        this.props.location.state.preview                          <label>Subtotal ({this.props.location.state.preview.quantity}{" "}Qty)</label>
                          <span>${this.props.location.state.preview.subTotal}</span> */}
                        </p>

                        <p>
                          <label>Discount</label>
                          <span>-$17.49</span>
                        </p>
                        <p>
                          <label>Discount</label>
                          <span>-$17.49</span>
                        </p>
                        <p>
                          <label>Tax</label>
                          <span>$14.20</span>
                        </p>

                        <p>
                          <label>
                            {" "}
                            Shipping (
                            {
                              //this.props.location.state.preview.quantity
                              3
                            }{" "}
                            Qty)
                          </label>
                          <span>123</span>
                        </p>

                        <p className="order-total">
                          <label>Total</label>
                          <span>
                            {" "}
                            4000
                            {/* ${this.props.location.state.preview.total}  */}
                          </span>
                        </p>

                  {/* <div className="col">
                    <p>Subtotal({localStorage.getItem('quantity')} Qty)</p>

                  </div>
                  <div className="col ">

                    <p className="float-right">${localStorage.getItem('subTotal')}</p>

                  </div>
                </div>
                <div className="form-row mt-n3">
                  <div className="col">
                    <p>Shipping ({localStorage.getItem('quantity')} Qty)</p>
                  </div>
                  <div className="col ml-n5">

                    <p className="float-right">${localStorage.getItem('shiffingFee')}</p>
                    

                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col">
                    <p>Total</p>
                  </div>
                  <div className="col ">

                    <p className="float-right">${localStorage.getItem('total')}</p>

                  </div>
                </div>

                <p className="mt-n3">Tax calculated in Checkout</p>
                <p className={myClass.p}>
                 
                </p> */}
              </div>
              <div className="btn-continue mt-3 w-100 d-flex">
              <button
                className={myClass.continueButton}
                disabled
              >
                Continue <i className="fal fa-long-arrow-right"></i>
              </button>


            </div>
            </div>
            
          </div>
          </div>
        </div>
        </div>
        </div>
        </div>
      </div>
    );
  }
}

export default ExistingCustomerBilling;