import React, { Component } from "react";
import $ from "jquery";
class BillingError extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    $("#myModal1").modal("show");
  
  }
 
  render() {
   
    let message= null;
    this.props.reasonOfFailure.map((i, index) => (
     message=( <p key={i.code}> {i.message.replace(/[<]br[^>]*[>]/gi," ")}</p>)
    ))
    
    return (
      <div id="myModal1" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div>
              <button type="button" className="close mr-2" data-dismiss="modal" >
                &times;
              </button>
            </div>
            <div class="modal-body">
               {message}
            </div>
           
          </div>
        </div>
      </div>
    );
  }
}
export default BillingError;