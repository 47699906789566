import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';

import { CountryDropdown } from 'react-country-region-selector';
import myClass from './editAccount.module.css';
import MESSAGES from '../../constants/messages';
import accountSchema from '../../validations/account';
import { updateAccount } from '../../services/account';

// eslint-disable-next-line react/function-component-definition
export default function EditAccount({
  defaultValues, setIsLoading,
}) {
  const {
    register, setValue, handleSubmit, control, formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(accountSchema),
  });

  useEffect(() => {
    const setFormValues = (values) => {
      Object.keys(defaultValues).forEach((key) => setValue(key, values[key]));
    };

    setFormValues(defaultValues);
  }, [defaultValues, setValue]);

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const payload = { ...formData, zipcCode: formData.zipCode, accountId: localStorage.getItem('accountId') };
    const response = await updateAccount(payload);
    if (response.data.success) {
      window.location.reload();
    }
  };

  return (
    <div>
      <div className="modal show active" id="myModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div>
              <button
                type="button"
                className="close mr-3 mt-1"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <div className="col">
                    <label htmlFor="firstName" className={myClass.label}>
                      {MESSAGES.FIRST_NAME}
                    </label>
                    <input
                      type="textArea"
                      className="form-control"
                      name="firstName"
                      {...register('firstName')}
                      placeholder={errors.firstName?.message}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="lastName" className={myClass.label}>
                      {MESSAGES.LAST_NAME}
                    </label>
                    <input
                      type="textArea"
                      name="lastName"
                      className="form-control"
                      {...register('lastName')}
                      placeholder={errors.lastName?.message}
                    />
                  </div>
                </div>
                <div className="form-group pt-3 ">
                  <label htmlFor="userName" className={myClass.label}>
                    {MESSAGES.USERNAME}
                  </label>
                  <input
                    type="textArea"
                    name="workEmail"
                    className="form-control"
                    {...register('workEmail')}
                    placeholder={errors.workEmail?.message}
                  />
                </div>

                <div className="form-group  ">
                  <label htmlFor="address" className={myClass.label}>
                    {MESSAGES.ADDRESS}
                  </label>
                  <input
                    type="textArea"
                    name="address1"
                    className="form-control"
                    {...register('address1')}
                    placeholder={errors.address1?.message}
                  />
                </div>
                <div className="form-group  ">
                  <label htmlFor="city" className={myClass.label}>
                    {MESSAGES.CITY}
                  </label>
                  <input
                    type="textArea"
                    name="city"
                    className="form-control"
                    {...register('city')}
                    placeholder={errors.city?.message}
                  />
                </div>
                <div className="form-row  ">
                  <div className="col">
                    <label htmlFor="state" className={myClass.label}>
                      {MESSAGES.STATE}
                    </label>
                    <input
                      type="textArea"
                      name="state"
                      className="form-control"
                      {...register('state')}
                      placeholder={errors.state?.message}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="country" className={myClass.label}>
                      {MESSAGES.COUNTRY}
                    </label>
                    <Controller
                      name="country"
                      control={control}
                      render={({
                        field: {
                          onChange, onBlur, value,
                        },
                      }) => (
                        <CountryDropdown
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          className={myClass.select}
                        />
                      )}
                    />
                    {errors?.country && (
                    <p style={{ color: 'red', fontSize: 'small' }}>
                      {errors?.country?.message}
                    </p>
                    )}
                  </div>
                </div>
                <div className="form-group pt-3 ">
                  <label htmlFor="zipCode" className={myClass.label}>
                    {MESSAGES.ZIPCODE}
                  </label>
                  <input
                    type="textArea"
                    name="zipCode"
                    className="form-control"
                    {...register('zipCode')}
                    placeholder={errors.zipCode?.message}
                  />
                </div>
                <div className="form-group  ">
                  <label htmlFor="workPhone" className={myClass.label}>
                    {MESSAGES.PHONE}
                  </label>
                  <input
                    type="textArea"
                    name="workPhone"
                    className="form-control"
                    {...register('workPhone')}
                    placeholder={errors.workPhone?.message}
                  />
                </div>
                <button
                  type="submit"
                  className={myClass.button}
                >
                  {MESSAGES.SAVE_CHANGES}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

EditAccount.propTypes = {
  defaultValues: PropTypes.arrayOf(PropTypes.object),
  setIsLoading: PropTypes.func,
};

EditAccount.defaultProps = {
  defaultValues: {},
  setIsLoading: () => {},
};
