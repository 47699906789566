import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import TopLogo from '../../assets/images/logo@2xWhite.png';
import chaos from '../../assets/images/chaos-device.png';
import myClass from './login.module.css';
import MESSAGES from '../../constants/messages';
import { APP_ROUTES } from '../../constants/routes';
import { signin } from '../../services/auth';
import loginSchema from '../../validations/login';
import FormError from '../../components/formError';
import { ORDER_STEP } from '../../constants/app';
import { AppContext } from '../../context/app';

// eslint-disable-next-line react/function-component-definition
export default function Login({ fromAdmin }) {
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(loginSchema),
  });
  const { loading, showMessage, messageText } = useContext(AppContext);
  const [, setIsLoading] = loading;
  const [, setShowMsg] = showMessage;
  const [, setMessage] = messageText;

  useEffect(() => {
    try {
    if(PostAffTracker && process.env.REACT_APP_AFFILIATION_PRO ===  'enable'){
    
      PostAffTracker.setAccountId('default1');
        PostAffTracker.track();
      }
  } catch (err) {
    console.log('err:', err)
  }
}, [])
  

  const handleForgot = (e) => {
    e.preventDefault();
    history.push({
      pathname: APP_ROUTES.RESET_PASSWORD,
    });
  };

  const handleSignup = (e) => {
    e.preventDefault();
    history.push({
      pathname: APP_ROUTES.SIGNUP,
    });
  };

  const onSubmit = async (formData) => {
    const { email, password } = formData;
    setIsLoading(true);

    const userDto = {
      email,
      password,
    };

    const response = await signin(userDto);
    setIsLoading(false);
    if (response.data.success) {
      if (
        response.data.permissions
        && response.data.role === 'ADMIN'
        && response.data.permissions
        && response.data.permissions.bulkOrder === true
      ) {
        localStorage.setItem('existingCustomer', true);
        history.push({
          pathname: APP_ROUTES.SWITCH_ORGANIZATION,
        });
      } else if (!response.data.accountId) {
        setShowMsg(true);
        setMessage(MESSAGES.NOT_AVAILABLE);
        // if (fromAdmin) {
        //   setMessage(MESSAGES.ACCOUNT_NOT_EXIST_ON_ZUORA);
        //   setShowMsg(true);
        // } else {
        // localStorage.removeItem('existingCustomer');
        // history.push({
        //   pathname: APP_ROUTES.ORDER,
        //   state: { step: ORDER_STEP.YOUR_ORDER },
        // });
        // }
      } else if (response.data.accountId) {
        localStorage.setItem('existingCustomer', true);
        // if (fromAdmin) {
          history.push({
            pathname: APP_ROUTES.ACCOUNT_AND_PLANS,
          });
        // } else {
        // if (!response.data?.permissions?.billing) {
        //   setShowMsg(true);
        //   setMessage(MESSAGES.ACCOUNT_NOT_BILLING_OWNER);
        // } else {
          // history.push({
          //   pathname: APP_ROUTES.ORDER,
          //   state: { step: ORDER_STEP.YOUR_ORDER },
          // });
        // }
      }
    } else {
      setShowMsg(true);
      setMessage(MESSAGES.INVALID_CREDENTIALS);
    }
  };

  return (
    <div className="login-screen fill-window">
      <div className="container">
        <div className="card card-login">
          <div className="row">
            <div className="col-md-5">
              <nav className={myClass.navbar}>
                <ul className="navbar-nav pt-2 pb-1">
                  <li className="nav-item">
                    <div className="form-group">
                      <img className="login-logo form-group container col-12 my-4 py-2 d-flex" src={TopLogo} alt="" />
                      <img className="chaos-device form-group container col-12 d-flex" src={chaos} alt="" />
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-md-7 pb-3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <h5 className="pt-4 mt-1 mb-1">{MESSAGES.YOUR_GVG_ACCOUNT}</h5>
                  <p>{MESSAGES.PLEASE_SIGN_IN}</p>
                </div>

                <div className="form-group pt-2">
                  <label htmlFor="email">
                    {MESSAGES.EMAIL}
                  </label>
                  <input type="text" className="form-control rounded-0" placeholder={errors.email?.message} {...register('email')} id="email" />
                  {errors.email?.message && <FormError error={errors.email?.message} />}
                </div>

                <div className="form-group">
                  <label htmlFor="password">
                    {MESSAGES.PASSWORD}
                  </label>
                  <input type="password" className="form-control rounded-0" placeholder={errors.password?.message} {...register('password')} id="password" />
                </div>

                <div className="form-group forgot-pass">
                  <a
                    onClick={handleForgot}
                    style={{ color: '#3e92cc', cursor: 'pointer' }}
                    className={myClass.link}
                  >
                    {MESSAGES.FORGOT_PASSWORD}
                  </a>
                </div>

                <div className="form-group">
                  <button type="submit" className="btn w-100">{MESSAGES.SIGN_IN}</button>
                </div>

                { /*     <div className="form-group">
                  <h5 className="line-through">
                    <span>{MESSAGES.CREATE_NEW_ACCOUNT}</span>
                  </h5>
                </div>

                <div className="form-group">
                  <p>{MESSAGES.LOGIN_TEXT}</p>
                </div>

             <div className="form-group">
                  <button type="submit" className="btn btn-outline w-100" onClick={handleSignup}>{MESSAGES.SIGN_UP}</button>
                </div> 
  */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  fromAdmin: PropTypes.bool,
};

Login.defaultProps = {
  fromAdmin: false,
};
