import React, { useState, useEffect, useContext } from 'react';

import MESSAGES from '../../../constants/messages';
import Table from '../../../components/table';
import { PAYMENT_TABLE_COLS } from '../../../constants/app';
import getUserPayments from '../../../services/paymentDetail';
import CardDetail from '../../../components/cardDetail';
import Loader from '../../../components/model/Loader';
import { AppContext } from '../../../context/app';

// eslint-disable-next-line react/function-component-definition
export default function PaymentDetail() {
  const [payments, setPayments] = useState([]);
  const [, setIsLoading] = useContext(AppContext).loading;

  useEffect(() => {
    async function getPageData() {
      setIsLoading(true);
      const accountId = localStorage.getItem('accountId');
      const userPayments = await getUserPayments({ params: { accountId } });
      setPayments(userPayments.data);
      setIsLoading(false);
    }
    getPageData();
  }, []);

  const getPaymentRows = () => payments.map((payment) => (
    {
      paymentNo: payment.paymentNumber,
      accountName: payment.accountName,
      paymentDate: payment.paymentDate,
      type: payment.type,
      amount: `$${payment.amount}`,
      invoiceNo: payment.invoiceNumber,
      status: payment.status,
    }));

  return (
    <div>
      <CardDetail
        setIsLoading={setIsLoading}
      />
      <h2 className="pt-4 pl-4">
        <small>{MESSAGES.PAYMENTS}</small>
      </h2>

      <div className="table-responsive w-auto bg-white mt-4 mb-4">
        <Table columns={PAYMENT_TABLE_COLS} rows={getPaymentRows()} />
      </div>
    </div>
  );
}
