import React, { Component } from "react";
import myClass from "./Model.module.css";
import { client } from "../../actions/index";
import UserContext from "../userContext/UserContext";
import master from "../../assets/images/card_master.png";
import visa from "../../assets/images/card_visa.png";
import discover from "../../assets/images/card_discover.png";
import amex from "../../assets/images/card_amex.png";
import BillingError from "../billing/BillingError";
import Loader from './Loader';
class PaymentModelPopup extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      creditCardNumber: "",
      creditCardType: "",
      expiration: "",
      cvv: "",
      cardHolderName: "",
      address: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      errors: "",
      cardNumbererrors: "",
      visaopacity: "30%",
      amexopacity: "30%",
      discoveropacity: "30%",
      masteropacity: "30%",

      errorInExpiration: false,
      hasError:false,
      maxLength:"3",
      phone:'',
      isLoading:false,
      opacity: "100%",
      errorInPhone:false
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentWillReceiveProps(props) {
    this.componentDidMount();
  }
  submitHandler = () => {
    this.setState({hasError:false})
    if (this.checkData()) {
      this.setState({  isLoading:true,opacity: "40%"})
        
    var expiration = this.state.expiration;
    const splits = expiration.split("/", 2);
    let paymentDto = {
      paymentMethodId: this.state.paymentMethodId,
      accId: localStorage.getItem('accountId'),
      creditCardNumber: this.state.creditCardNumber,
      creditCardType: this.state.creditCardType,
      cardHolderName: this.state.cardHolderName,
      expirationMonth: splits[0],
      expirationYear: splits[1],
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      postalCode: this.state.postalCode,
      cvv: this.state.cvv,
      phone:this.state.phone,
      newUser: false,
    };
    client.post("users/updatePaymentMethod", paymentDto).then((response) => {
      // console.log("from new card response in else part", response);
      if (response.data.success) {
        alert("Card Edited Successfully");
        window.location.reload();
      }
      else{
       
      
        this.setState({reasonOfFailure:response.data.reasons,hasError:true,isLoading:false,
          opacity:'100%'});
      }
    });
  }
  };

checkData=()=>{
  if (this.state.expiration === "") {
    this.setState({
      errors: "Cannot be empty",
    });
    return false;
  }
  if (this.state.expiration.length <= 6 && this.state.expiration.length > 0) {
    this.setState({
      errorInExpiration: true,
    });
    return false;
  }
  if (this.state.cvv === "") {
    this.setState({
      errors: "Cannot be empty",
    });
    return false;
  }
  if (this.state.cvv.length <= 2) {
    this.setState({
      errorInCvv: true,
    });
    return false;
  }
  if (this.state.city === "") {
    this.setState({
      errors: "Cannot be empty",
    });
    return false;
  }

  if (this.state.address === "") {
    this.setState({
      errors: "Cannot be empty",
    });
    return false;
  }
  if (this.state.cardHolderName === "") {
    this.setState({
      errors: "Cannot be empty",
    });
    return false;
  }
 
  if (this.state.postalCode === "") {
    this.setState({
      errors: "Cannot be empty",
    });
    return false;
  }
  if (this.state.state === "") {
    this.setState({
      errors: "Cannot be empty",
    });
    return false;
  }
  if (this.state.phone === ""|this.state.phone===null) {
    this.setState({
      errors: "Cannot be empty",
    });
    return false;
  }
  if (this.state.errorInPhone) {
    return false;
  }
  if (this.state.country === "") {
    this.setState({
      errors: "Cannot be empty",
    });
    return false;
  } else {
    return true;
  }
}
isValidPhone=()=>{
  if(this.state.phone.length<10){
    this.setState({errorInPhone:true})
  }
  else{
    this.setState({errorInPhone:false})
  }
}
  componentDidMount() {
    
    const user = this.context;
    

    if (Object.keys(user).length !== 0) {
      
      if (user.expirationMonth < 10) {
        this.setState({
          expiration: "0" + user.expirationMonth + "/" + user.expirationYear,
        });
      } else {
        this.setState({
          expiration: user.expirationMonth + "/" + user.expirationYear,
        });
      }
      this.setState({
        paymentMethodId: user.id,
        creditCardNumber: user.cardNumber,
        cardHolderName: user.cardHolderInfo.cardHolderName,
        cardType: user.cardType,
        address: user.cardHolderInfo.addressLine1,
        city: user.cardHolderInfo.city,
        state: user.cardHolderInfo.state,
        postalCode: user.cardHolderInfo.zipCode,
        country: user.cardHolderInfo.country,
        phone: user.cardHolderInfo.phone,
        isDisable: true,
      });
      if (user.cardType === "Visa") {
        this.setState({
          visaopacity: "100%",
          amexopacity: "30%",
          discoveropacity: "30%",
          masteropacity: "30%",
          maxLength:"3"
        });
      } else if (user.cardType === "AmericanExpress") {
        this.setState({
          amexopacity: "100%",
          visaopacity: "30%",
          discoveropacity: "30%",
          masteropacity: "30%",
          maxLength:"4"
        });
      } else if (user.cardType === "Discover") {
        this.setState({
          discoveropacity: "100%",
          amexopacity: "30%",
          visaopacity: "30%",
          masteropacity: "30%",
          maxLength:"3"
        });
      } else if (user.cardType === "MasterCard") {
        this.setState({
          masteropacity: "100%",
          amexopacity: "30%",
          visaopacity: "30%",
          discoveropacity: "30%",
          maxLength:"3"
        });
      }
    }
  }

  render() {
    let Spinner = null;
    if (this.state.isLoading === true) {
      Spinner = <Loader />;
    }
   
    return (
      <div>
      
        <div className="modal show active"  id="myModal">
         
        {this.state.hasError? <BillingError  reasonOfFailure={this.state.reasonOfFailure}/>:''}
          <div className="modal-dialog" opacity={this.state.opacity}>
          {Spinner}
            <div className="modal-content">
              <div>
                <button
                  type="button"
                  className="close mr-3 mt-1"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>
             
              <div className="modal-body">
                <div className="col-8 pl-0 pr-0" style={{ display: "flex" }}>
                  <div>
                    {" "}
                    <img
                      className="col-2 pl-0"
                      src={visa}
                      alt="Visa logo"
                      style={{ opacity: this.state.visaopacity }}
                    />{" "}
                    <img
                      className="col-2 pl-0"
                      src={master}
                      alt="master logo"
                      style={{ opacity: this.state.masteropacity }}
                    />{" "}
                    <img
                      className="col-2 pl-0"
                      src={discover}
                      alt="Discover logo"
                      style={{ opacity: this.state.discoveropacity }}
                    />
                     <img
                      className="col-2 pl-0"
                      src={amex}
                      alt="amex logo"
                      style={{ opacity: this.state.amexopacity }}
                    />
                  </div>
                </div>
                {/* <p className="card-text"> */}
                <div className="form-group ">
                  <label htmlFor="cardNumber" className={myClass.label}>
                    Card Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="creditCardNumber"
                    maxLength="16"
                    name="creditCardNumber"
                    // onBlur={this.ValidateCreditCardNumber}
                    value={this.state.creditCardNumber}
                    // onChange={this.onChange}
                    placeholder={this.state.errors}
                  ></input>
                </div>
                <div className="form-row">
                  <div className="col">
                    <label htmlFor="expiration" className={myClass.label}>
                      Expiration
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="expiration"
                      maxLength="7"
                      value={this.state.expiration}
                      placeholder={this.state.errors}
                      onChange={this.onChange}
                    ></input>
                  </div>

                  <div className="col">
                    <label htmlFor="cvv" className={myClass.label}>
                      CVV
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="cvv"
                      maxLength={this.state.maxLength}
                      value={this.state.cvv}
                      placeholder={this.state.errors}
                      onChange={this.onChange}
                    ></input>
                  </div>
                </div>
                <div className="form-row ">
                  <div className="col mb-n4">
                    {this.state.expiration.length < 7 &&
                    this.state.errorInExpiration ? (
                      <p style={{ color: "red", fontSize: "small" }}>
                        Expected MM/YYYY
                      </p>
                    ) : null}
                  </div>
                  <div className="col mb-n4">
                    {this.state.cvv.length < 3 && this.state.errorInCvv ? (
                      <p style={{ color: "red", fontSize: "small" }}>
                        Invalid CVV
                      </p>
                    ) : null}
                  </div>
                </div>
                <div
                  className="form-group pt-3"
                  style={{ borderBottom: "1px solid #ced4da" }}
                ></div>
                {/* add break line  */}
                <h5 className="card-title">Billing Information</h5>
                <div className="form-group">
                  <label htmlFor="cardHolderName" className={myClass.label}>
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="cardHolderName"
                    value={this.state.cardHolderName}
                    placeholder={this.state.errors}
                    onChange={this.onChange}
                  ></input>
                 
                </div>
                <div className="form-group pt-2 ">
                  <label htmlFor="address" className={myClass.label}>
                    Phone Number
                  </label>
                <input
                    type="text"
                    name="phone"
                    id="phone"
                    maxLength="15"
                    className="form-control"
                    value={this.state.phone}
                    onBlur={this.isValidPhone}
                    onChange={this.onChange}
                    placeholder={this.state.errors}
                  ></input>
                  </div>
                  <div className="col mb-n2 ml-n2">
                    {this.state.phone!=null &&this.state.phone.length > 1 && this.state.errorInPhone ? (
                      <p style={{ color: "red", fontSize: "small" }}>
                        Invalid Phone Number
                      </p>
                    ) : null}
                  </div>
                <div className="form-group pt-2 ">
                  <label htmlFor="address" className={myClass.label}>
                    Address
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={this.state.address}
                    className="form-control"
                    onChange={this.onChange}
                    placeholder={this.state.errors}
                  ></input>
                </div>
                <div className="form-group ">
                  <label htmlFor="city" className={myClass.label}>
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    value={this.state.city}
                    onChange={this.onChange}
                    placeholder={this.state.errors}
                  ></input>
                </div>
                <div className="form-group ">
                  <label htmlFor="state" className={myClass.label}>
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    value={this.state.state}
                    onChange={this.onChange}
                    placeholder={this.state.errors}
                  ></input>
                </div>
                <div className="form-row">
                  <div className="col">
                    <label htmlFor="country" className={myClass.label}>
                      Country
                    </label>
                    <select
                      id="country"
                      name="country"
                      value={this.state.country}
                      onChange={this.onChange}
                      className={myClass.select}
                    >
                      <option></option>
                      <option>Afghanistan</option>
                      <option>Albania</option>
                      <option>American Samoa</option>
                      <option>Andorra</option>
                      <option>Anguilla</option>
                      <option>Antarctica</option>
                      <option>Antiqua and Barbuda</option>
                      <option>Argentina</option>
                      <option>Armenia</option>
                      <option>Aruba</option>
                      <option>Australia</option>
                      <option>Austria</option>
                      <option>Azerbaijan</option>
                      <option>Bahamas</option>
                      <option>Bahrain</option>
                      <option>Bangladesh</option>
                      <option>Barbados</option>
                      <option>Benin</option>
                      <option>Belarus</option>
                      <option>Belize</option>
                      <option>Belgium</option>
                      <option>Bermuda</option>
                      <option>Bhutan</option>
                      <option>Bolivia</option>
                      <option>Bouvet Islands</option>
                      <option>Brazil</option>
                      <option>British Indian Ocean Territory</option>
                      <option>British Virgin Islands</option>
                      <option>Brunei</option>
                      <option>Bulgaria</option>
                      <option>Burkina Faso</option>
                      <option>Burundi</option>
                      <option>Canada</option>
                      <option>Cambodia</option>
                      <option>Cameroon</option>
                      <option>Cape Verde</option>
                      <option>Cayman Islands</option>
                      <option>Central African Republic</option>
                      <option>Chad</option>
                      <option>Chile</option>
                      <option>China</option>
                      <option>Colombia</option>
                      <option>Comoros</option>
                      <option>Congo</option>
                      <option>Costa Rica</option>
                      <option>Cote D'Ivoire</option>
                      <option>Croatia</option>
                      <option>Cyprus</option>
                      <option>Czech Republic</option>
                      <option>Denmark</option>
                      <option>Djibouti</option>
                      <option>Dominica</option>
                      <option>Dominican Republic</option>
                      <option>Ecuador</option>
                      <option>Egypt</option>
                      <option>El Salvador</option>
                      <option>Equatorial Guinea</option>
                      <option>Eritrea</option>
                      <option>Estonia Faroe Islands</option>
                      <option>Ethiopia</option>
                      <option>Falkland Islands</option>
                      <option>Faroe Islands</option>
                      <option>Federated States of Micronesia</option>
                      <option>Fiji</option>
                      <option>Finland</option>
                      <option>France</option>
                      <option>French Guiana</option>
                      <option>French Polynesia</option>
                      <option>Gabon</option>
                      <option>Gambia</option>
                      <option>Georgia</option>
                      <option>Germany</option>
                      <option>Ghana</option>
                      <option>Gibraltar</option>
                      <option>Greece</option>
                      <option>Greenland</option>
                      <option>Grenada</option>
                      <option>Guadeloupe</option>
                      <option>Guam</option>
                      <option>Guatemala</option>
                      <option>Guinea- Bissau</option>
                      <option>Guyana</option>
                      <option>Haiti</option>
                      <option>Honduras</option>
                      <option>Hong Kong</option>
                      <option>Hungary</option>
                      <option>Iceland</option>
                      <option>India</option>
                      <option>Indonesia</option>
                      <option>Ireland</option>
                      <option>Israel</option>
                      <option>Italy</option>
                      <option>Jamaica</option>
                      <option>Japan</option>
                      <option>Jordan</option>
                      <option>Kazakhstan</option>
                      <option>Kenya</option>
                      <option>Kiribati</option>
                      <option>Kuwait</option>
                      <option>Kyrgyzstan</option>
                      <option>Laos</option>
                      <option>Latvia</option>
                      <option>Lebanon</option>
                      <option>Lesotho</option>
                      <option>Liechtenstein</option>
                      <option>Lithuania</option>
                      <option>Luxembourg</option>
                      <option>Macau</option>
                      <option>Madagascar</option>
                      <option>Malawi</option>
                      <option>Malaysia</option>
                      <option>Maldives</option>
                      <option>Mali</option>
                      <option>Malta</option>
                      <option>Marshall Islands</option>
                      <option>Martinique</option>
                      <option>Mauritania</option>
                      <option>Mayotte</option>
                      <option>Metropolitan France</option>
                      <option>Mexico</option>
                      <option>Moldova</option>
                      <option>Mongolia</option>
                      <option>Morocco</option>
                      <option>Mozambique</option>
                      <option>Namibia</option>
                      <option>Nauru</option>
                      <option>Nepal</option>
                      <option>Netherlands</option>
                      <option>Netherlands Antilles</option>
                      <option>New Caledonia</option>
                      <option>New Zealand</option>
                      <option>Nicaragua</option>
                      <option>Niger</option>
                      <option>Nigeria</option>
                      <option>Northern Mariana Islands</option>
                      <option>Norway</option>
                      <option>Oman</option>
                      <option>Pakistan</option>
                      <option>Palau</option>
                      <option>Panama</option>
                      <option>Papua New Guinea</option>
                      <option>Paraguay</option>
                      <option>Peru</option>
                      <option>Philippines</option>
                      <option>Pitcairn</option>
                      <option>Poland</option>
                      <option>Portugal</option>
                      <option>Puerto Rico</option>
                      <option>Qatar</option>
                      <option>Reunion</option>
                      <option>Republic of Korea</option>
                      <option>Romania</option>
                      <option>Russia</option>
                      <option>Sao Tome and Principe</option>
                      <option>Saudi Arabia</option>
                      <option>Senegal</option>
                      <option>Seychelles</option>
                      <option>Sierra Leone</option>
                      <option>Singapore</option>
                      <option>Slovakia</option>
                      <option>Slovenia</option>
                      <option>Solomon Islands</option>
                      <option>Somalia</option>
                      <option>South Africa</option>
                      <option>Spain</option>
                      <option>Sri Lanka</option>
                      <option>St. Helena</option>
                      <option>St. Kitts and Nevis</option>
                      <option>St. Lucia</option>
                      <option>St. Vincent and the Grenadines</option>
                      <option>Sudan</option>
                      <option>Suriname</option>
                      <option>Svalbard and Jan Mayen Islands</option>
                      <option>Swaziland</option>
                      <option>Sweden</option>
                      <option>Switzerland</option>
                      <option>Syria</option>
                      <option>Taiwan</option>
                      <option>Tajikistan</option>
                      <option>Tanzania</option>
                      <option>Thailand</option>
                      <option>Togo</option>
                      <option>Tonga</option>
                      <option>Trinidad and Tobago</option>
                      <option>Turkey</option>
                      <option>Turkmenistan</option>
                      <option>Turks and Caicos Islands</option>
                      <option>Tuvalu</option>
                      <option>Uganda</option>
                      <option>Ukraine</option>
                      <option>United Arab Emirates</option>
                      <option>United Kingdom</option>
                      <option>United States</option>
                      <option>Uruguay</option>
                      <option>Uzbekistan</option>
                      <option>Vanuatu</option>
                      <option>Vatican City</option>
                      <option>Vietnam</option>
                      <option>Venezuela</option>
                      <option>Western Sahara</option>
                      <option>Yemen</option>
                      <option>The former Yugoslav Republic of Macedonia</option>
                      <option>Yugoslavia</option>
                      <option>Zaire</option>
                      <option>Zambia</option>
                      <option>Zimbabwe</option>
                    </select>
                  </div>
                  <div className="col">
                    <label htmlFor="postalCode" className={myClass.label}>
                      Zipcode
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="postalCode"
                      value={this.state.postalCode}
                      onChange={this.onChange}
                      placeholder={this.state.errors}
                    ></input>
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  className={myClass.submitButton}
                  onClick={this.submitHandler}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PaymentModelPopup;
