import React, { Component } from 'react';
class TermsAndConition extends Component {
  constructor(props) {
    super(props);
    this.state = {
        text:"shyam Soni"
    };
  }
  componentDidMount(){
      console.log('i ma  ksjdjkhdohs67676769');
  }
  render() {
      console.log('dude i m ');
    return (
    <div >
        <p>Terms and Condition</p>
        
        
        </div>
        )
  }
}
export default TermsAndConition;