import React from 'react';
import {
  useHistory,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import SettingsTabs from '../../components/settingsTabs';
import { APP_ROUTES } from '../../constants/routes';
import AccountAndPlan from './accountAndPlan';
import Receipt from './receipts';
import PaymentDetail from './paymentDetail';

// eslint-disable-next-line react/function-component-definition
export default function Settings() {
  const history = useHistory();
  const handleLogout = () => {
    localStorage.clear();
    history.push({
      pathname: '/',
    });
  };

  return (
    <div className="fill-window w-100">
      <Navbar handleLogout={handleLogout} />

      <div
        className="form-group"
        style={{ borderBottom: '1px solid #ced4da' }}
      />
      <div className="col">
        <div className="pt-4 row ">
          <SettingsTabs />
        </div>
      </div>
      <div
        className="form-group  ml-3"
        style={{ borderBottom: '1px solid #ced4da' }}
      />
      <Switch>
        <Route
          exact
          path={APP_ROUTES.ACCOUNT_AND_PLANS}
          name="accountplan"
          render={() => <AccountAndPlan />}
        />
        <Route
          path={APP_ROUTES.RECEIPTS}
          name="receipts"
          render={() => <Receipt />}
        />
        <Route
          path={APP_ROUTES.PAYMENT_DETAILS}
          name="paymentdetails"
          render={() => <PaymentDetail />}
        />
        <Route path="/" render={() => <Redirect to={APP_ROUTES.ACCOUNT_AND_PLANS} />} />
      </Switch>
    </div>
  );
}
