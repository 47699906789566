const defaultState = {
  paymentMethodId: '',
  status: '',
  isUserLoading: false,
};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  switch (action.type) {
    case 'CREATE_PAYMENT_METHOD': {
      return {
        ...state,
        paymentMethodId: action.payload.paymentMethodId,
        status: action.payload.success,

      };
    }
    default:
      return state;
  }
};
