import React, { Component } from "react";
import AccountPlanButtons from "../accountplanbutton/AccountPlanButtons";
//import myClass from "./PaymentDetails.module.css";
import Visa from "../../assets/images/card_visa.png";
import Discover from "../../assets/images/card_discover.png";
import masterCard from "../../assets/images/card_master.png";
import amex from "../../assets/images/card_amex.png";
import Edit from "../../assets/images/edit.png";
import Delete from "../../assets/images/delete-button.png";
import Plus from "../../assets/images/plus-button.png";
import { client } from "../../actions/index";
import PaymentModelPopup from "../model/PaymentModelPopup";
import { UserProvider } from "../userContext/UserContext";
import Loader from "../model/Loader";
import AddNewPayment from "../model/AddNewPayment";

class CardDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: "",
      defaultPaymentMethodCardId: "",
      cardInfo: [],
      indvCardInfo: {},
      togleState: false,
      payments: [],
      isLoading: false,
      opacity: "100%",
      selectedCardId: "",
    };
  }

  renderImage = (type) => {
    switch (type) {
      case "Visa":
        return (
          <img
            src={Visa}
            alt="Visa logo"
            width="40px"
            height="30px"
            color="white"
            style={{ position: "absolute" }}
          />
        );
      case "Discover":
        return (
          <img
            src={Discover}
            alt="Visa logo"
            width="40px"
            height="30px"
            color="white"
            style={{ position: "absolute" }}
          />
        );
      case "MasterCard":
        return (
          <img
            src={masterCard}
            alt="Master logo"
            width="40px"
            height="30px"
            color="white"
            style={{ position: "absolute" }}
          />
        );
      case "AmericanExpress":
        return (
          <img
            src={amex}
            alt="amex logo"
            width="40px"
            height="30px"
            color="white"
            style={{ position: "absolute" }}
          />
        );
    }
  };

  handleChange = (event) => {
    this.setState({
      defaultPaymentMethodCardId: event.target.value,
      isLoading: true,
      opacity: "30%",
      selectedCardId: event.target.value,
    });
    const selectedCard = this.state.cardInfo.find(
      (card) => card.id == event.target.value
    );
    this.props.getSelectedCard(selectedCard);
  };
  getItemId = (indvData) => {
    // <AccountModelPopup/>
    this.setState(
      {
        indvCardInfo: indvData,
      },
      () => {
        this.setState({ togleState: true });
      }
    );
  };

  componentDidMount() {
    const accountId = localStorage.getItem("accountId");
    client
      .get("users/getPaymentMethod", {
        params: {
          accountId: accountId,
        },
      })
      .then((response) => {
        const defaultCard = response.data.creditCards.find(
          (i) => i.defaultPaymentMethod === true
        );
        this.setState({
          defaultPaymentMethodCardId: defaultCard.id,
          selectedCardId: defaultCard.id,
        });

        this.setState({ cardInfo: response.data.creditCards });
        this.props.getSelectedCard(defaultCard);
      });
  }
  render() {
    let Spinner = null;
    if (this.state.isLoading === true) {
      Spinner = <Loader />;
    }
    const elements = this.state.cardInfo;

    const items = this.state.cardInfo.map((i, index) => (
      <li key={index}>{i.id}</li>
    ));

    return (
      <div opacity={this.state.opacity}>
        <h4 className="ml-3 mt-5">Payment Methods</h4>

        <div className="row w-100">
          <div className="ml-3 mt-4 ">
            {this.state.cardInfo.length > 0 &&
              this.state.cardInfo.map((i, index) => (
                <div
                  style={{ minWidth: "300px" }}
                  className="d-flex align-items-center ml-3 mt-2"
                >
                  <div
                    // className="col"
                    style={{
                      borderStyle: "solid",
                      borderColor: "#2483f4",
                      borderWidth: "1px",
                      borderRadius: "6px",
                      padding: "10px",
                      marginLeft: "1.5%",
                      marginBottom: "3%",
                      minWidth: "250px",
                    }}
                  >
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radio"
                        id="inlineRadio1"
                        checked={this.state.selectedCardId == i.id}
                        value={i.id}
                        onClick={this.handleChange}
                      />
                    </div>

                    {this.renderImage(this.state.cardInfo[index].cardType)}
                    <div className="d-flex ml-5 ">
                      <p className="float-right mr-3 ml-5 mt-n4">
                        {" "}
                        {this.state.cardInfo[index].cardNumber}
                      </p>
                    </div>
                  </div>
                  <div className="ml-2">
                    {" "}
                    <p>
                      {" "}
                      {this.state.cardInfo[index].defaultPaymentMethod &&
                        "Default"}{" "}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}
export default CardDetails;
